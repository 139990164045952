import { Typography } from "@mui/material";
import Layout from "./Layout";

const ClassicFormLayout = ({label, children}) => {
    return (
        <Layout alignItems="center">
            <Layout.Item oneThird>
                <Typography
                    color={"text.secondary"}
                >
                    {label}
                </Typography>
            </Layout.Item>
            <Layout.Item twoThird>{children}</Layout.Item>
        </Layout>
    )
}

export default ClassicFormLayout;