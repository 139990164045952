import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useFetch, } from "../providers/AppProvider";
import server_route_names from "../routes/server_route_names";
import LegacyPage from "../subcomponents/LegacyPage";
import ActivityTable from "../pages/users/ActivityTable";
import { Paper, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { convertCamelCaseToWords } from "../functions/common";
import moment from "moment";

const LoadProductHistory = ({
    productId = null
}) => {

    const [paginationModel, paginationModelChange] = useState({
        page: 0,
        pageSize: 25
    });

    let _q = `${server_route_names["products.history"]}?limit=${paginationModel.pageSize}&page=${paginationModel.page+1}`;

    if(productId){
        _q += `&productId=${productId}`;
    }

    const { data, fetching: loading } = useFetch(_q);

    return (
        <TableContainer component={Paper}>
            <DataGrid
                rows={data?.items?.map((x,i) => {
                    x.id = i+1;
                    return x;
                })??[]}
                columns={columns}
                rowCount={data?.total??0}
                paginationModel={paginationModel}
                onPaginationModelChange={paginationModelChange}
                paginationMode="server"
                loading={loading}
                sx={{ height: data?.total > 0?"auto":400 }}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            ></DataGrid>
        </TableContainer>
    )
}

export default LoadProductHistory;

const columns = [
    {
        field: "createdAt",
        headerName: "Date & Time",
        width: 180,
        sortable: false,
        renderCell: params => {
            return moment(params.value).format("DD/MM/YYYY hh:mm a");
        }
    },
    {
        field: "userName",
        headerName: "User Name",
        width: 160,
        sortable: false,
    },
    {
        field: "fieldName",
        headerName: "Column",
        width: 180,
        sortable: false,
        renderCell: params => {
            return convertCamelCaseToWords(params.value)
        }
    },
    {
        field: "oldValue",
        headerName: "Old Value",
        width: 200,
        sortable: false,
    },
    {
        field: "newValue",
        headerName: "New Value",
        width: 200,
        sortable: false,
    },
    {
        field: "comment",
        headerName: "Comment",
        width: 600,
        sortable: false,
    }
];