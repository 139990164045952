import { Autocomplete, Box, Button, Grid, ListItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Layout from "./Layout";
import ClassicFormLayout from "./ClassicFormLayout";

export default function RenderProductAspects({aspects, onChange, product}){
    const [showCount, setShowCount] = useState(10);


    const renderField = (aspect, index) => {
        let aspectDataType = aspect.aspectConstraint.aspectDataType??"STRING";
        let aspectMode = aspect.aspectConstraint.aspectMode??"FREE_TEXT"; // "SELECTION_ONLY,FREE_TEXT";
        let itemToAspectCardinality = aspect.aspectConstraint.itemToAspectCardinality??"SINGLE"; // "SINGLE,MULTI";
        
        let value = "";
        if (product && product.aspects && product.aspects.hasOwnProperty(aspect.localizedAspectName)) {
            try {
                value = product.aspects[aspect.localizedAspectName][0];
            } catch (error) {
                
            }
        }

        if (aspectMode === "SELECTION_ONLY") {
            return (
                <ClassicFormLayout
                    label={`${ aspect.localizedAspectName } ${ aspect.aspectConstraint.aspectRequired?" *":"" }`}
                >
                    <Autocomplete
                        onChange={(event, value, reason, details) => {
                            onChange(aspect.localizedAspectName, {
                                event, value, reason, details
                            });
                        }}
                        disableClearable
                        multiple={itemToAspectCardinality==="MULTI"}
                        id={`autocomplete-id--${index}`}
                        key={`asp-card-${index}`}
                        getOptionLabel={(option) => option.label}
                        options={aspect?.aspectValues?.map(r => { r.label = r.localizedValue; return r;})??[]}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                margin="normal"
                                placeholder={aspect.localizedAspectName}
                            />
                        }
                        renderOption={(props, option) => <ListItem {...props}>{option.label}</ListItem>}
                        // disabled
                    />
                </ClassicFormLayout>
            )
        }
        else if (aspectMode === "FREE_TEXT") {
            return (
                <ClassicFormLayout
                    label={`${ aspect.localizedAspectName } ${  aspect.aspectConstraint.aspectRequired?" *":"" }`}
                >
                    <TextField
                        fullWidth
                        size="small"
                        margin="normal"
                        placeholder={aspect.localizedAspectName}
                        required={aspect.aspectConstraint.aspectRequired}
                        defaultValue={value}
                        defaultChecked={value?true:false}
                        // disabled
                    />
                </ClassicFormLayout>
            )
        }
    }
    const render_aspect = () => {
        var _html = [];
        for (let index = 0; index < aspects.length; index++) {
            const aspect = aspects[index];
            if (index+1 > showCount) continue;
            _html.push(
                <div key={`aspect-item-${index}`}>
                    {renderField(aspect, index)}
                </div>
            )
        }
        return _html;
    }
    if (aspects && aspects?.length > 0) {
        return (
            <Box>
                {render_aspect()}
                <Box sx={{mt: 4}}>
                    <Button
                        onClick={() => {
                            if(aspects.length > showCount){
                                setShowCount(showCount + 25);
                            }
                            else{
                                setShowCount(10);
                            }
                        }}
                    >
                        {
                            aspects.length > showCount ? "Show more": "Show less"
                        }
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            No specifics found
        </Box>
    )
}