import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import route_names from "../../routes/route_names";

export default function Logo ({direction = "row", children}) {
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const navigate = useNavigate();
    return (
        <Stack direction={direction} flexWrap="nowrap" alignItems="center" height={(theme) => `${theme.mixins.toolbar.height}`}>
            {children}
            <Button
                variant="text"
                color="inherit"
                onClick={() => {
                    navigate(route_names.home);
                }}
            >
                <img src="/main-logo.png" width="auto" height="56px" alt="AES"></img>
            </Button>
        </Stack>
    )
}