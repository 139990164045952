import React, { useCallback, useState } from "react";
import server_route_names from "../routes/server_route_names";
import { useUpdateRecord } from "../providers/AppProvider";
import { Paper, Stack, TableContainer, TextField, Typography, Avatar, Button, ButtonGroup, Chip, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import EmptyPage from "./EmptyPage";
import { findChangedFields } from "../functions/objects";
import LegacyDialog from "../subcomponents/LegacyDialog";
import { convertCamelCaseToWords } from "../functions/common";
import { visible_columns_initial } from "../functions/filters";
import { StringToNumber } from "../functions/common";
import route_names from "../routes/route_names";
import moment from "moment";


const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .even`]: {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.background.default
	},
	'& .MuiDataGrid-cell': {
		borderRight: "1px solid",
		borderColor: theme.palette.divider,
	},
	'& .MuiDataGrid': {
		borderColor: theme.palette.divider,
	},
	'& .MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey.A200 : null
	},
	'& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey.A200 : null
	},
	'& .error': {
		backgroundColor: theme.palette.error[theme.palette.mode],
	},
	'& .success': {
		backgroundColor: theme.palette.success[theme.palette.mode],
	},
	'& .low-inventory': {
		backgroundColor: theme.palette.warning[theme.palette.mode],
	}
}));

const useDataMutation = () => {
	return useCallback((newRow, oldRow, updateRecord, comment) =>
		new Promise((resolve, reject) => {
			let changed = findChangedFields(newRow, oldRow);
			// if row was updated
			if (changed?.length > 0) {
				let data = {};
				for (let index = 0; index < changed.length; index++) {
					const changedData = changed[index];
					data[changedData.name] = changedData.newValue
				}
				updateRecord(newRow._id, {
					product: data,
					chx: changed,
					comment: comment
				})
					.then(response => {
						resolve(response);
					}).catch(error => {
						reject(error);
					})
			}
			else {
				resolve(null);
			}

		})
		, []);
}

function computeMutation(newRow, oldRow) {
	let changed = findChangedFields(newRow, oldRow);
	if (changed && changed.length > 0) {
		return `Edit product's ${changed.length} fields`;
	}
	return null;
}

export default function RepricerToolProductTable({
	pageSizeOptions = [10, 25, 50, 100],
	initialProducts = {
		items: [],
		total: 0,
		count: 0,
		next: null,
		limit: 25
	},
	paginationModel = {
		page: 0,
		pageSize: 10,
	},
	setPaginationModel = () => { },
	loading = true,
	onRowDoubleClick = () => { },
	onRowSelectionModelChange = () => { },
	rowSelectionModel = [],
	checkboxSelection = false,
	columns = null,
	hideFooterPagination = false,
	hideFooterSelectedRowCount = false,
	hideFooter = false,
	showQuickFilter = false,
	paginationMode = "client", // client, server
	onSetVisibleColumns = () => { },
	isEditAllowed = false,
	emptyPageMessage = null
}) {
	const { data: updatedRecord, loading: loadingRecord, error: errorUpdateRecord, updateRecord } = useUpdateRecord(server_route_names.products);
	const [snackbar, setSnackbar] = useState(null);
	const [visibleColumns, setVisibleColumns] = useState(visible_columns_initial());
	const [promiseArguments, setPromiseArguments] = useState(null);
	const [comment, setComment] = useState(null);
	const mutateRow = useDataMutation();

	if (!columns) {
		columns = columns_json()
	}

	const processRowUpdate = useCallback(
		(newRow, oldRow) => {
			return new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					// Save the arguments to resolve or reject the promise later
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				}
				else {
					resolve(oldRow); // Nothing was changed
				}
			})
		},
		[],
	);

	const handleProcessRowUpdateError = useCallback((error) => {
		setSnackbar({ children: error.message, severity: 'error' });
	}, []);

	const handleCloseSnackbar = () => setSnackbar(null);

	const handleNo = () => {
		const { oldRow, resolve, reject, newRow } = promiseArguments;
		setPromiseArguments(null);
		resolve(oldRow); // Resolve with the old row to not update the internal state
	};

	const handleYes = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;
		try {
			// Make the HTTP request to save in the backend
			const response = await mutateRow(newRow, oldRow, updateRecord, comment);
			setSnackbar({ children: response?.message, severity: response?.status === 'success' ? 'success' : 'error' });
			if (response?.status === "success") {
				resolve(newRow);
				setComment(null);
			}
			else {
				resolve(oldRow);
			}
			setPromiseArguments(null);
		} catch (error) {
			reject(oldRow);
			setPromiseArguments(null);
		}
	};

	const handleEntered = () => {
		// The `autoFocus` is not used because, if used, the same Enter that saves
		// the cell triggers "No". Instead, we manually focus the "No" button once
		// the dialog is fully open.
		// noButtonRef.current?.focus();
	};

	const renderConfirmDialog = () => {
		if (!promiseArguments) {
			return null;
		}

		const { newRow, oldRow } = promiseArguments;
		const mutation = computeMutation(newRow, oldRow);
		const editedFields = findChangedFields(newRow, oldRow);

		return (
			<LegacyDialog
				size="md"
				open={!!promiseArguments}
				TransitionProps={{ onEntered: handleEntered }}
				title={mutation}
				primaryAction={{
					title: "Update",
					onClick: handleYes,
					disabled: (!comment || String(comment).trim() === "") || loadingRecord
				}}
				secondaryAction={{
					title: "Cancel",
					onClick: handleNo,
					disabled: loadingRecord
				}}
				hideCloseIcon
			>
				<Stack spacing={3}>
					<Typography mb={2} variant="body2">Review your edits before proceeding with the update.</Typography>
					<StripedDataGrid
						density="compact"
						columns={[
							{
								field: "name",
								headerName: "Field",
								sortable: false,
								editable: false,
								width: 240,
								renderCell: params => <Typography variant="button">{convertCamelCaseToWords(params.value)}</Typography>
							},
							{
								field: "oldValue",
								headerName: "Old value",
								sortable: false,
								editable: false,
								width: 180
							},
							{
								field: "newValue",
								headerName: "New Value",
								sortable: false,
								editable: false,
								width: 180
							}
						]}
						rows={editedFields}
						disableColumnFilter
						disableColumnMenu
						disableColumnSelector
						disableDensitySelector
						disableEval
						disableRowSelectionOnClick
						disableVirtualization
						hideFooter
						hideFooterPagination
						hideFooterSelectedRowCount
					/>
					<TextField
						required
						label="Comment (Required)"
						helperText
						multiline
						rows={2}
						value={comment}
						onChange={(e) => {
							setComment(e.target.value);
						}}
					/>
					<Typography variant="caption" color="text.secondary">Note: The admin will be notified about this change.</Typography>
				</Stack>
			</LegacyDialog>
		);
	};

	return (
		<>
			{renderConfirmDialog()}
			{
				!!snackbar && (
					<Snackbar
						open
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						onClose={handleCloseSnackbar}
						autoHideDuration={6000}
					>
						<Alert {...snackbar} onClose={handleCloseSnackbar} />
					</Snackbar>
				)
			}
			<TableContainer component={Paper}>
				<StripedDataGrid
					density="comfortable"
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <EmptyPage title={emptyPageMessage} />,
						toolbar: GridToolbar
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: false,
							printOptions: {
								disableToolbarButton: true
							}
						}
					}}
					sx={{
						p: 0, pt: 0, height: initialProducts?.total > 0 ? "auto" : 400,
						'& .MuiDataGrid-cell:hover': {
							color: 'primary.main',
						},
						maxHeight: 700,
						overflow: "hidden"
						// maxHeight: 'calc(100% - 400px)'
					}}
					columns={columns}
					rows={DataGridRow(initialProducts)}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pagination
					initialState={{
						pagination: {
							paginationModel: paginationModel
						}
					}}
					pageSizeOptions={pageSizeOptions}
					rowCount={initialProducts?.total ?? 0}
					paginationMode={paginationMode}
					editMode="row"
					isCellEditable={(params) => isEditAllowed === true}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					loading={loading}
					onColumnVisibilityModelChange={(columns, details) => {
						setVisibleColumns(columns);
						onSetVisibleColumns(columns, details);
					}}
					columnVisibilityModel={visibleColumns}
					disableRowSelectionOnClick
					onRowDoubleClick={onRowDoubleClick}
					checkboxSelection={false}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					hideFooterPagination={hideFooterPagination}
					hideFooter={hideFooter}
					hideFooterSelectedRowCount={hideFooterSelectedRowCount}
					disableColumnMenu
					showQuickFilter={showQuickFilter}
					getCellClassName={(params) => {
						if (params?.field === "image") {
							return 'bg-white';
						}
						return ``;
					}}
					disableColumnFilter
					disableDensitySelector
				>
				</StripedDataGrid>
			</TableContainer>
		</>
	)
}

const DataGridRow = (data) => {
	if (data?.items?.length > 0) {
		return data.items;
	}
	else {
		return [];
	}
}

function columns_json() {
	let _columns = [
		{
			"field": "title",
			"headerName": "Title",
			"description": "Item title",
			"width": 400,
			"sortable": false,
			"editable": false,
			"valueGetter": params => {
				if (params?.row?.sku) {
					return params.row?.sku ?? 0
				}
				return "";
			},
			"renderCell": (params) => {
				let img = params?.row?.image ?? params?.row?.images?.[0] ?? null;
				return <Stack direction="row" spacing={1} alignItems="center">
					<Avatar
						alt={`Image-${params?.row?.sku}`}
						src={img}
						sx={{ width: 84, height: 84, borderRadius: 0 }}
					/>
					<Stack width="100%">
						<Typography
							flexWrap="wrap"
							variant="body2"
							
						>
							{params?.row?.title}
						</Typography>
					</Stack>
				</Stack>;
			},
		},
		{
			"field": "sku",
			"headerName": "Sku",
			"description": "Item SKU",
			"width": 160,
			"sortable": false,
			"editable": false,
			"valueGetter": params => {
				if (params?.row?.sku) {
					return params.row?.sku ?? 0
				}
				return "";
			},
		},
		{
			"field": "Suspects",
			"headerName": "Suspects",
			"description": "Suspects",
			"width": 120,
			"sortable": false,
			"editable": false,
			"valueGetter": params => {
				if (params?.row?.competitorKeywords) {
					return params.row?.suspectsCounts ?? 0
				}
				return "";
			},
			"renderCell": (params) => {
				const suspectsCounts = params.row?.suspectsCounts ?? 0;
				const competitorsCounts = params.row?.competitorsCounts ?? 0;
				const nonCompetitorsCounts = params.row?.nonCompetitorsCounts ?? 0;
				if (params?.row?.competitorKeywords) {
					return <Stack>
						<ButtonGroup size="small" variant="text">
							<Tooltip title={`${suspectsCounts} suspects waiting manual decision`}>
							<Button
								size="small"
								variant="text"
								disabled={!suspectsCounts || suspectsCounts === 0}
								onClick={() => {
									window.open(`${route_names["repricer.suspects"]}?productId=${params?.row?._id}`, "_blank")
								}}
							>
								{suspectsCounts}
							</Button>
							</Tooltip>
							<Tooltip title={`${nonCompetitorsCounts} items previously marked as "Don't compete"`}>
								<Button
									color="warning"
									size="small"
									variant="text"
									disabled={!nonCompetitorsCounts || nonCompetitorsCounts === 0}
									onClick={() => {
										window.open(`${route_names["repricer.suspects.dontcompete"]}?target=dont_compete&productId=${params?.row?._id}`, "_blank")
									}}
								>
									<span style={{padding: "2px", textDecoration:"line-through"}}>({nonCompetitorsCounts})</span>
								</Button>
							</Tooltip>
						</ButtonGroup>
						<Tooltip title={`Last updated`}>
							<Typography
								variant="caption"
								color="text.secondary"
								noWrap
							>{moment(params.row?.suspectUpdatedAt).fromNow()}
							</Typography>
						</Tooltip>
					</Stack>;
				}
				return null;
			},
		},
		{
			"field": "Competitors",
			"headerName": "Competitors",
			"description": "Competitors",
			"width": 100,
			"sortable": false,
			"editable": false,
			"valueGetter": params => {
				if (params?.row?.competitorKeywords) {
					return params.row?.competitorsCounts ?? 0;
				}
				return "";
			},
			"renderCell": (params) => {
				const competitorsCounts = params.row?.competitorsCounts?? 0;
				if (params?.row?.competitorKeywords) {
					return <Stack spacing={0}>
						<Tooltip title={`${competitorsCounts} suspects previously marked as "Compete"`}>
							<Button
								size="small"
								variant="text"
								disabled={!competitorsCounts || competitorsCounts === 0}
								onClick={() => {
									window.open(`${route_names["repricer.competitors"]}?productId=${params?.row?._id}`, "_blank")
								}}
							>
								{competitorsCounts}
							</Button>
						</Tooltip>
						<Tooltip title={`Last updated`}>
							<Typography
								variant="caption"
								color="text.secondary"
								noWrap
							>{moment(params.row?.competitorUpdatedAt).fromNow()}
							</Typography>
						</Tooltip>
					</Stack>
				}
				return null;
			},
		},
		{
			"field": "price",
			"headerName": "Our Price",
			"description": "Item price / Our price / Ebay price",
			"width": 120,
			"sortable": false,
			"editable": false,
			"type": "number",
			"valueFormatter": params => {
				if (!params?.value) {
					return `$ ${StringToNumber(params?.row?.marketplaceListings?.[0]?.price ?? 0)}`;
				}
				return `$ ${StringToNumber(params.value)}`;
			}
		},
		{
			"field": "competitorsLowestPrice",
			"headerName": "Competitors Lowest Price",
			"description": "Competitors Lowest Price",
			"width": 120,
			"sortable": false,
			"editable": false,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`;
			}
		},
		{
			"field": "floorPrice",
			"headerName": "Floor Price",
			"description": "Floor price",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "ceilingPrice",
			"headerName": "Ceiling Price",
			"description": "Ceiling Price",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "competitorKeywords",
			"headerName": "Competitor Keywords",
			"description": "Competitor Keywords",
			"width": 360,
			"sortable": true,
			"editable": true,
		}, {
			"field": "competitorIds",
			"headerName": "Competitor Ids",
			"description": "Competitor Ids",
			"width": 360,
			"sortable": true,
			"editable": true,
		},
	];
	return _columns
}