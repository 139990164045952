import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";

export default function CustomCard({children, title, subtitle, variant, elevation, size = "large", textTransform, sx, actions, borderRadius, borderLess }){
    
    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
    const openActions = Boolean(actionsAnchorEl);

    let sxObject = {};
    if(sx && typeof sx === "object"){
        sxObject = {...sxObject, ...sx}
    }
    let style = undefined;
    if (typeof borderRadius === "number") {
        style = {};
        style["borderRadius"] = borderRadius;
    }
    if (borderLess) {
        style = {};
        style["borderRadius"] = 0;
        variant = "elevation";
        elevation = 0;
    }
    return (
        <Card
            variant={variant??"outlined"}
            elevation={elevation??0}
            sx={sxObject}
            style={style}
        >
            {
                title && (
                    <CardHeader
                        subheader={subtitle}
                        sx={{
                            textTransform:textTransform?textTransform:size === "small"?"capitalize":"uppercase"
                        }}
                        title={title}
                        titleTypographyProps={{
                            variant: 'h6'
                        }}
                        action={
                            <>
                            {
                            actions && actions.length > 0 && 
                            (
                                <>
                                    <IconButton
                                        id="card-buttons"
                                        aria-controls={openActions ? 'card-menus' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openActions ? 'true' : undefined}
                                        onClick={(event) => {
                                            setActionsAnchorEl(event.currentTarget);
                                        }}
                                    >
                                        <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                        id="card-menus"
                                        anchorEl={actionsAnchorEl}
                                        open={openActions}
                                        onClose={() => {
                                            setActionsAnchorEl(null);
                                        }}
                                        MenuListProps={{
                                            'aria-labelledby': 'card-buttons',
                                        }}
                                    >
                                        {
                                            actions.map((r,i) => (
                                                <MenuItem
                                                    key={`m${r.label}-${i}`}
                                                    onClick={r.onClick}
                                                    disabled={r.disabled}
                                                >
                                                {r.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </>
                            )
                            }</>
                        }
                        
                    ></CardHeader>
                )
            }
            <CardContent sx={{pt: title?0:null}}> {children} </CardContent>
        </Card>
    )
}