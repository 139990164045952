import { useFetch, useRequest } from "../../providers/AppProvider";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useParams } from "react-router-dom";
import LegacyPage from "../../subcomponents/LegacyPage";
import FiltersJsonGetQuery from "../../components/FiltersJsonGetQuery";
import ColumnsJson from "../../components/ColumnsJson";
import ProductTable from "../../components/ProductTable";

export default function Reporting() {
    const { id } = useParams();
    const {data: reporting, fetching, error: reportingError } = useFetch(`${server_route_names["reporting"]}?id=${id}`);
    const {data: reports, loading, error, sendRequest: getReports } = useRequest();
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });
    useEffect(() => {
        document.title = reporting?.title??"Reporting";
    }, [reporting]);

    useEffect(() => {
        if (reporting) {
            let query = reporting.query || null;
            let fields = "imageUrls,id";
            if (reporting.type === "FILTERS") {
                query = FiltersJsonGetQuery(reporting.filters);
                if (reporting.fields) {
                    fields += `,${reporting.fields}`;
                }
            }
            let q = `${server_route_names["products.lookup"]}?page=${paginationModel.page+1}&limit=${paginationModel.pageSize}`;
            if (query) {
                q += "&"+query;
                if (fields) {
                    q += "&fields="+fields;
                }
            }
            else if (fields) {
                q += "&fields="+fields;
            }
            getReports(q);
        }
    }, [reporting, fetching, paginationModel, setPaginationModel]);

    useEffect(() => {
        if (reports) {
            if (reporting.fields) {
                let fields = {};
                let f = String(reporting.fields).split(",");
                f.forEach(x => {
                    fields[x] = true
                });
                setVisibleColumns(fields);
            }
        }
    }, [reports]);

    if (error) {
        return (
            <LegacyPage
                title="Reporting"
            >
                {error}
            </LegacyPage>
        )
    }

    if (reportingError) {
        return (
            <LegacyPage
                title="Reporting"
            >
                {reportingError}
            </LegacyPage>
        )
    }

    const DataGridColumns = () => {
        let newColumns = [];
        try {
            // image tab must be included
            let dddt = ColumnsJson();
            let II = dddt?.findIndex(x => x.field == "imageUrl");
            if (II > -1) {
                newColumns.push(dddt[II]);
            }
        } catch (error) {
            
        }
        try {
            for (const key in visibleColumns) {
                if (Object.hasOwnProperty.call(visibleColumns, key)) {
                    let dddt = ColumnsJson();
                    let fIndex = dddt?.findIndex(x => x.field == key);
                    if (fIndex > -1) {
                        newColumns.push(dddt[fIndex]);
                    }
                }
            }
        } catch (error) {
            
        }
        return newColumns.length > 0?newColumns:ColumnsJson();
    }

    return (
        <LegacyPage
            title={reporting?.title??"Reporting"}
        >
            <Layout>
                <Layout.Section>
                    <ProductTable
                        columns={DataGridColumns()}
                        loading={fetching || loading}
                        initialProducts={reports?.data}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        isEditAllowed
                        paginationMode="server"
                    />
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}