const string_condition = [
    {
        "label": "Equals",
        "value": "eq"
    },
    {
        "label": "Contains",
        "value": "contains"
    },
    {
        "label": "Does not contain",
        "value": "not_contain"
    }
];

const number_condition = [
    {
        "label": "Equals",
        "value": "eq"
    },
    {
        "label": "Greater than",
        "value": "gt"
    },
    {
        "label": "Less than",
        "value": "lt"
    },
    {
        "label": "Range",
        "value": "range"
    }
];

const FiltersJson = [
    {
        "label": "Ad Rate %",
        "value": "adRate",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Carrier %",
        "value": "carrierPercentage",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Carrier fee",
        "value": "carrierFee",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Cost/Item",
        "value": "cost",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Ebay lowest price",
        "value": "ebayLowestPrice",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Ebay profit %",
        "value": "profitPercentage",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Ebay rank",
        "value": "ebayRank",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "FBM cost",
        "value": "fbmCost",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Floor price",
        "value": "floorPrice",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Inventory quantity",
        "value": "inventoryQuantity",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Our price",
        "value": "price",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "Sold quantity",
        "value": "soldQuantity",
        "valueType": "number",
        "conditions": number_condition
    },
    {
        "label": "SKU",
        "value": "sku",
        "conditions": string_condition
    },
    {
        "label": "Title",
        "value": "title",
        "conditions": string_condition
    }
];

export default FiltersJson;