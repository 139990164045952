import {
    Typography,
    Stack,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    ListItemButton,
} from '@mui/material';
import { useFetch } from '../providers/AppProvider';
import server_route_names from '../routes/server_route_names';
import { abbreviateNumber } from '../functions/common';
import WidgetCard from './WidgetCard';
import LegacyCard from '../subcomponents/LegacyCard';
import { useNavigate } from 'react-router-dom';
import route_names from '../routes/route_names';

export default function InventoryAnalyticsWidget({
    startDate = null, endDate = null, title = null
}) {

    let query = `${server_route_names["dashboard.widget"]}?widget_type=inventory_analytics`;
    // if (startDate) {
    //     query += `&start_date=${startDate}`;
    // }
    // if (endDate) {
    //     query += `&end_date=${endDate}`;
    // }

    const { data: widget_data, error, fetching: widget_is_loading } = useFetch(query);

    const navigate = useNavigate();

    if (error) {
        return <div>{error}</div>;
    }

    if (widget_is_loading) {
        return (
            <LegacyCard title="Inventory by item price" sectioned>
                <List disablePadding dense>
                    <ListItemButton dense disableGutters>
                        <ListItemText>
                            <Skeleton />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton dense disableGutters>
                        <ListItemText>
                            <Skeleton />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton dense disableGutters>
                       <ListItemText>
                            <Skeleton />
                        </ListItemText>
                    </ListItemButton>
                </List>
            </LegacyCard>
        );
    }

    return (
        <LegacyCard title="Inventory by item price" sectioned>
            <List disablePadding dense>
                <CustomList title="$ 0 → 20" secondaryInfo={widget_data?.items_zero_twenty} />
                <CustomList title="$ 20 → 50" secondaryInfo={widget_data?.items_twenty_fifty} />
                <CustomList title="$ 50 +" secondaryInfo={widget_data?.items_fifty_plus} />
            </List>
        </LegacyCard>
    );
}

function CustomList({
    title = null,
    secondaryInfo = null,
    onClick = () => {}
}){
    return (
        <ListItemButton dense disableGutters
            onClick={onClick}
        >
            <ListItemText primary={title}/>
            {   secondaryInfo &&
                <Typography>{secondaryInfo}</Typography>
            }
        </ListItemButton>
    )
}