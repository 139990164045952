import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useFetch } from "../../providers/AppProvider";
import { Box, Button, Card, Chip, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Popover, Select, Skeleton, Stack, TableContainer, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { renderCondition, renderConditionView } from "../../functions/filters";
import { exportToCSV, exportToExcel } from "../../functions/files";
import LegacyPage from "../../subcomponents/LegacyPage";
import EmptyPage from "../../components/EmptyPage";
import LegacyCard from "../../subcomponents/LegacyCard";
import CsvUploader from "../../components/CsvUploader";
import LegacyDialog from "../../subcomponents/LegacyDialog";
import { usePrivileges } from "../../providers/AuthProvider";
import privileges from "../../privileges";

export default function Orders(){
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [filtersQuery, setFiltersQuery] = useState(new URLSearchParams());
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    })

    const [uploadCheckmate, setUploadCheckmate] = useState(false);
    const [q, setQ] = useState(params.get('q') || "");
    if (q && q.toString().trim() !== "") {
        params.set("q", q);
    }
    else {
        params.delete("q", q);
    }
    if (paginationModel?.pageSize) {
        params.set("limit", paginationModel?.pageSize);
    }
    else{
        params.set("limit", 10);  
    }
    if (paginationModel?.page) {
        params.set("page", paginationModel?.page);
    }
    else{
        params.set("page", 1);  
    }
    const combinedParams = new URLSearchParams(params.toString() + '&' + filtersQuery.toString());
    let url = `${server_route_names["orders.lookup"]}`;
    if (combinedParams.toString()) {
        url += `?${combinedParams.toString()}`;
    }
    else{
        url += `?q=`;
    }
    const { data: orders, error: orderError, fetching: orderLoading, reFetch: refreshData } = useFetch(url);

    const navigate = useNavigate();

    const { hasPermission } = usePrivileges();

    useEffect(() => {
        document.title = "Orders";
    }, []);

    if (orderError) {
        return (
            <LegacyPage
                title="Orders"
            >
                {orderError}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            title="Orders"
            // actions={[
            //     {
            //         title: "Export to CSV",
            //         onClick: () => {
            //             let compile = "Order Number,Buyer Username,Subtotal,Total,Fulfillment Status,Payment Status,Selling Marketplace,Order created,Last modified\n" + DataGridRow(orders).map(item => `${item.orderId},${item.customer},${item.subtotal},${item.total},${item.orderFulfillmentStatus},${item.orderPaymentStatus},${item.sellingMarketplace},${item.creationDate}%,${item.lastModifiedDate}`).join('\n');
            //             exportToCSV(compile, 'orders');
            //         }
            //     },
            //     {
            //         title: "Export to Excel",
            //         onClick: () => {
            //             let compiledData = DataGridRow(orders).map(r =>{
            //                 let d = {
            //                     "Order Number": r.orderId,
            //                     "Buyer Username": r.customer,
            //                     "Subtotal": r.subtotal,
            //                     "Total": r.total,
            //                     "Fulfillment status": r.orderFulfillmentStatus,
            //                     "Payment status": r.orderPaymentStatus,
            //                     "Selling Marketplace": r.sellingMarketplace,
            //                     "Order created": r.creationDate,
            //                     "Last modified": r.lastModifiedDate,
            //                     "Last synchronized": r.updatedAt
            //                 };
            //                 return d;
            //             })
            //             exportToExcel(compiledData, "orders");
            //         }
            //     }
            // ]}
            headerSlots={
                <>
                    {
                        hasPermission(privileges["checkmate.manage"]) &&
                        <Button
                            onClick={() => setUploadCheckmate(true) }
                        >Upload Checkmate Sheet</Button>
                    }
                </>
            }
        >
            <LegacyDialog
                title="Upload Checkmate Sheet"
                open={uploadCheckmate}
                onCancel={() => setUploadCheckmate(false)}
                onClose={() => setUploadCheckmate(false)}
            >
                <CsvUploader
                    fileType="checkmat.upload"
                    targetUrl={server_route_names["checkmate.bulk_update_by_csv"]}
                    showButton
                    onUploadDone={() => {
                        refreshData();
                        setTimeout(() => {
                            setUploadCheckmate(false);
                        }, 1000);
                    }}
                ></CsvUploader>
            </LegacyDialog>
            <TableContainer component={Paper}>
                <DataGrid
                    slots={{
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: EmptyPage,
                        toolbar: GridToolbar
                    }}
                    sx={{
                        p: 0, pt: 0, height: orders?.total > 0?null:400
                    }}
                    loading={orderLoading}
                    columns={columns}
                    rows={DataGridRow(orders)}
                    rowCount={orders?.total??0}
                    pageSizeOptions={[10, 25, 50, 100]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25
                            }
                        }
                    }}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                    // checkboxSelection
                    disableColumnMenu
                >
                </DataGrid>
            </TableContainer>
        </LegacyPage>
    )
}

const columns = [
    {   field: 'orderId', 
        headerName: 'Order Number',
        width: 140
    },
    {   field: 'subtotal', 
        headerName: 'Subtotal ($)',
        width: 100,
        valueFormatter: params => `$ ${params.value}`
    },
    {   field: 'total', 
        headerName: 'Total ($)',
        width: 100,
        valueFormatter: params => `$ ${params.value}`
    },
    {   field: 'customer', 
        headerName: 'Buyer Username',
        filterable: false,
        sortable: false,
        width: 160
    },
    {   field: 'storeName', 
        headerName: 'Selling Store Name',
        filterable: true,
        sortable: false,
        width: 240
    },
    {   field: 'sellingMarketplace', 
        headerName: 'Marketplace',
        filterable: true,
        sortable: false,
        width: 120
    },
    
    // {   field: 'pricingSummary.priceSubtotal', 
    //     headerName: 'Subtotal',
    //     filterable: false,
    //     sortable: false,
    //     width: 120,
    //     valueGetter: (params) =>  `${ params.row.order?.pricingSummary?.priceSubtotal?.value } ${ params.row.order?.pricingSummary?.priceSubtotal?.currency }`
    // },
    // {   field: 'pricingSummary.total', 
    //     headerName: 'Total',
    //     filterable: false,
    //     sortable: false,
    //     width: 120,
    //     valueGetter: (params) =>  `${ params.row.order?.pricingSummary?.total?.value } ${ params.row.order?.pricingSummary?.total?.currency }`
    // },
    {   field: 'orderFulfillmentStatus', 
        headerName: 'Fulfillment status',
        filterable: false,
        sortable: false,
        width: 160,
        valueFormatter: params => `${String(params.value).replace(/\_/g, ' ').toLowerCase()}`
    },
    {   field: 'orderPaymentStatus', 
        headerName: 'Payment status',
        filterable: false,
        sortable: false,
        width: 120,
        valueFormatter: params => `${String(params.value).replace(/\_/g, ' ')}`
    },
    {   field: 'creationDate', 
        headerName: 'Order created',
        filterable: false,
        sortable: false,
        width: 160,
        valueFormatter: params => `${moment(params.value).format("MM/DD/YYYY hh:mm")}`
    },
    {   field: 'lastModifiedDate', 
        headerName: 'Last modified',
        filterable: false,
        sortable: false,
        width: 160,
        valueGetter: (params) =>  `${ moment(params.row.lastModifiedDate).fromNow() }`
    },
    {   field: 'lastSyc', 
        headerName: 'Last synchronized',
        filterable: false,
        sortable: false,
        width: 160,
        valueGetter: (params) =>  `${ moment(params.row.updatedAt).fromNow() }`
    },
    {   field: 'firstSynchronized', 
        headerName: 'Originally synchronized',
        filterable: false,
        sortable: false,
        width: 180,
        valueGetter: (params) =>  `${ moment(params.row.createdAt).fromNow() }`
    },
];

const DataGridRow = (data) => {
    if (data?.items?.length > 0) {
        return data.items.map((r,i) => {
            return r;
        });
    }
    else{
        return [];
    }
}

function FilterOrders({ onFilter, onClearFilters }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterColumn, setFilterColumn] = useState(initialFilters[0].value);
    const [filterCondition, setFilterCondition] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState([]);

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleApplyFilter = () => {
        // Validate and apply the filter
        let filter = {
            column: filterColumn,
            condition: filterCondition,
            value: filterValue,
            filter: initialFilters[selectedFilter]
        };
        let newFilters = JSON.parse(JSON.stringify(appliedFilters));
        let indx = newFilters.findIndex(i => i.column === filter.column);
        if(indx > -1){
            newFilters[indx] = filter;
        }
        else{
            newFilters.push(filter);
        }
        setAppliedFilters(newFilters);
        let newQuery = new URLSearchParams();
        for (let index = 0; index < newFilters.length; index++) {
            const filter = newFilters[index];
            let value = renderCondition(filter.condition);
            value += filter.value;
            newQuery.set(filter.column, value);
        }
        if (typeof onFilter === "function") {
            onFilter(newFilters, newQuery);
        }
        handleClosePopover();
    };

    return (
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <div>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    {
                        appliedFilters.map(
                            (filter, index) => (
                                <Chip
                                    key={filter.column}
                                    label={`${filter.filter.label}: ${renderConditionView(filter.condition)} ${filter.value}`}
                                />
                            )
                        )
                    }
                </Stack>
            </div>
            <div>
                <Stack direction="row" spacing={2} alignItems="end" justifyItems="end" justifyContent="end" sx={{ width: "100%" }}>
                    <Button onClick={handleOpenPopover} variant="contained">
                        Filters
                    </Button>
                </Stack>
            </div>
            {
                appliedFilters && appliedFilters.length > 0 && 
                (
                    <div>
                        <Button
                            onClick={() => {
                                setAppliedFilters([]);
                                if (typeof onClearFilters === "function") {
                                    onClearFilters();
                                }
                            }}
                            variant="outlined"
                        >
                            Clear
                        </Button>
                    </div>
                )
            }
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: "left"
                }}
            >
                <Box sx={{ maxWidth: "360px", p: 2 }}>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Select</InputLabel>
                        <Select
                            label="Select"
                            value={initialFilters[selectedFilter].value}
                            onChange={(e) => {
                                let v = e.target.value;
                                let findIndex = initialFilters.findIndex(i => i.value === v);
                                if (findIndex > -1) {
                                    setSelectedFilter(findIndex);
                                    setFilterColumn(v);
                                    setFilterCondition("")
                                    setFilterValue("")
                                }
                            }}
                        >
                            {
                                initialFilters.map(
                                    (r, i) => (
                                        <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Condition</InputLabel>
                        <Select
                            label="Condition"
                            margin="normal"
                            value={filterCondition}
                            onChange={(e) => setFilterCondition(e.target.value)}
                        >
                            {
                                initialFilters[selectedFilter].conditions.map(
                                    (r, i) => (
                                        <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Filter Value"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApplyFilter}
                        disabled={
                            !filterColumn || !filterCondition || !filterValue?true:false
                        }
                    >
                        Apply Filter
                    </Button>
                </Box>
            </Popover>
        </Stack>
    );
}

const initialFilters = [
    {
        label: "Order Number",
        value: "orderId",
        conditions: [
            {
                label: "Equal to",
                value: "eq"
            }
        ]
    },
    {
        label: "Buyer Username",
        value: "customer",
        conditions: [
            {
                label: "Equal to",
                value: "eq"
            }
        ]
    }
]