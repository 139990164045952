import { useTheme } from '@mui/material';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import { useFetch } from '../providers/AppProvider';
import server_route_names from '../routes/server_route_names';
import LegacyCard from '../subcomponents/LegacyCard';

let chart_dummy_data = [
    {
        "Store name": "Automotive Collision Parts",
        "Orders value": 1,
        "Min orders value": 1,
        "Max orders value": 1,
        "Avg orders value": 1,
        "Total orders": 1
    },
    {
        "Store name": "Infiniti OEM Parts",
        "Orders value": 1,
        "Min orders value": 1,
        "Max orders value": 1,
        "Avg orders value": 1,
        "Total orders": 1
    },
    {
        "Store name": "INFINITI NISSAN COLLISION PARTS",
        "Orders value": 1,
        "Min orders value": 1,
        "Max orders value": 1,
        "Avg orders value": 1,
        "Total orders": 1
    }
];

export default function SalesByStoreWidget({
    startDate = null, endDate = null,
}) {

    let theme = useTheme();

    let query = `${server_route_names["sales.velocity.sales_by_store"]}?view=dashboard`;
    if (startDate) {
        query += `&start_date=${startDate}`;
    }
    if (endDate) {
        query += `&end_date=${endDate}`;
    }

    const { data: sales_metrics_data, fetching: sales_metrics_loading, error } = useFetch(query);

    if (error) {
        return <div>{error}</div>;
    }

    if (sales_metrics_data?.length === 0) {
        return (
            <></>
        )
    }

    if (sales_metrics_loading) {
        return (
            <LegacyCard title="Sales by stores">
                <LegacyCard.Section>
                    <BarChart
                        width={480}
                        height={360}
                        data={chart_dummy_data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid />
                        <XAxis dataKey="Store name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Orders value" fill={theme.palette.primary[theme.palette.mode]} activeBar={<Rectangle fill={theme.palette.secondary[theme.palette.mode]} stroke={theme.palette.secondary[theme.palette.mode]} />}/>
                    </BarChart>
                </LegacyCard.Section>
            </LegacyCard>
        )
    }

    chart_dummy_data = sales_metrics_data;

    return (
        <LegacyCard title="Sales by stores">
            <LegacyCard.Section>
                <BarChart
                    width={480}
                    height={360}
                    data={sales_metrics_data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid />
                    <XAxis dataKey="Store name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Orders value" fill={theme.palette.primary[theme.palette.mode]} activeBar={<Rectangle fill={theme.palette.secondary[theme.palette.mode]} stroke={theme.palette.secondary[theme.palette.mode]} />}/>
                </BarChart>
            </LegacyCard.Section>
        </LegacyCard>
    )
}

