import store from "store2";
import namespace from "../namespace";
import logger from "../logger";
import { host } from "../config";
import axios from "axios";

class CustomRequest {
    storeId = null; // store information
    accessToken = store(namespace.token); // user information
    host = host // server information
    headers = {};
    setAccessToken (accessToken){
        this.accessToken = accessToken;
    }

    setStoreId (id){
        this.storeId = id;
    }

    setHeaders (headers){
        this.headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+ this.accessToken
        };
        if (this.storeId) {
            this.headers = {...this.headers, ...{"X-Store-Id": this.storeId}};
        }
        if (headers) {
            this.headers = {...this.headers, ...headers};
        }
    }

    constructor(props){
        const { storeId, accessToken } = props??{};
        if (typeof accessToken === "string") {
            this.setAccessToken(accessToken);
        }
        if (typeof storeId === "string" || typeof storeId === "number") {
            this.setStoreId(storeId);
        }
    }

    #sendRequest = async (url, options) => {
        return new Promise((resolve, reject) => {
            if (!this.accessToken) {
                logger('accessToken is required');
                return {error: "accessToken is required"}
            }
            this.setHeaders(options?.headers);
            let config = {
                method: options?.type?.toLowerCase()??options?.method?.toLowerCase()??"GET"?.toLowerCase(),
                url: `${this.host}${url}`,
                headers: this.headers
            };
            if (options?.body) {
                config["data"] = JSON.stringify(options?.body)
            }
            axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                resolve({error});
            });
        });
    }

    send = async (url, options) => {
        var response = await this.#sendRequest(url, options);
        if(response?.code === "992"){
            console.log("Login expired");
            store.remove(namespace.token);
            window.location.href = '/';
        }
        if(response?.code === "1004"){
            store.remove(namespace.token);
            window.location.href = '/';
        }
        return response;
    }

    uploadFile = async (url, formData) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${this.host}${url}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer "+ this.accessToken
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                resolve({error});
            });
        });
    }

}

export default CustomRequest;