import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, TableContainer, TextField, Typography, styled } from "@mui/material";
import { useFetch } from "../../providers/AppProvider";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LegacyPage from "../../subcomponents/LegacyPage";
import EmptyPage from "../../components/EmptyPage";
import { useLocation } from "react-router-dom";


const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .even`]: {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey[100]: theme.palette.background.default
  },
  '& .MuiDataGrid-cell': {
    borderRight: "1px solid",
    borderColor: theme.palette.divider,
  },
  '& .MuiDataGrid': {
    borderColor: theme.palette.divider,
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === "light"? theme.palette.grey.A200: null
  },
  '& .error': {
    backgroundColor: theme.palette.error[theme.palette.mode],
  },
  '& .success': {
    backgroundColor: theme.palette.success[theme.palette.mode],
  },
  '& .low-inventory': {
    backgroundColor: theme.palette.warning[theme.palette.mode],
  }
}));

export default function Restock() {
    const [numberOfPastPeriods, SetNumberOfPastPeriods] = useState("90");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    if (paginationModel.pageSize) {
        params.set("limit", paginationModel.pageSize);
    }
    else {
        params.set("limit", 10);
    }
    if (paginationModel.page) {
        params.set("page", parseInt(paginationModel.page)+1);
    }
    else {
        params.set("page", 1);
    }
    const [filterColumn, setFilterColumn] = useState(null);
    const [filterCondition, setFilterCondition] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [filterQuery, setFilterQuery] = useState(null);
    if (filterQuery && filterQuery.key && filterQuery.value && String(filterQuery.key).trim() !== "") {
        params.set(filterQuery.key, filterQuery.value)
    }
    
    const { data, fetching: loading, error } = useFetch(`${server_route_names["ordering_sheet"]}?${params.toString()}`);
    useEffect(() => {
        document.title = "Ordering sheet";
    }, []);

    if (error) {
        return (
            <LegacyPage
                title="Ordering sheet"
            >
                {error}
            </LegacyPage>
        )
    }

    const getFilterConditions = () => {
        if (filterColumn) {
            let cindex = filters_options.findIndex( x => x.key === filterColumn);
            if (cindex > -1) {
                let filterOption = filters_options[cindex];
                if (filterOption.valueType) {
                    let conditions = filter_conditions[filterOption.valueType];
                    if (conditions && typeof conditions.map === "function") {
                        return conditions.map(x => (
                            <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem> 
                        ))
                    }
                }
            }
        }
        return null;
    }
    
    const doFilter = () => {
        if (filterColumn && filterCondition && filterValue && String(filterValue).trim() !== "") {
            let condition = filterCondition;
            let prepare_query = {
                key: `${filterColumn}.${condition}`,
                value: filterValue
            };
            setFilterQuery(prepare_query);
        }
        else{
            setFilterQuery(null);
        }
    }

    return (
        <LegacyPage
            title="Ordering sheet"
        >
            <Layout>
                <Layout.Section>
                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                        <Typography variant="subtitle1">Filters:</Typography>
                        <FormControl size="small" sx={{width: 220}}>
                            <InputLabel id="demo-simple-select-label">Column</InputLabel>
                            <Select
                                label="Column"
                                value={filterColumn}
                                onChange={(e) => {
                                    setFilterColumn(e.target.value);
                                    setFilterCondition(null);
                                }}
                            >
                                {
                                    filters_options.map(x => (
                                        <MenuItem key={x.key} value={x.key}>{x.column}</MenuItem> 
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl size="small" sx={{width: 160}}>
                            <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                            <Select
                                label="Condition"
                                value={filterCondition}
                                onChange={(e) => {
                                    setFilterCondition(e.target.value);
                                }}
                            >
                                {getFilterConditions()}
                            </Select>
                        </FormControl>
                        <TextField
                            size="small" sx={{width: 260}}
                            label="Value"
                            value={filterValue}
                            onChange={(e) => {
                                setFilterValue(e.target.value);
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={doFilter}
                        >Filter</Button>
                    </Stack>
                </Layout.Section>
                <Layout.Section>
                    <TableContainer component={Paper}>
                        <StripedDataGrid
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            slots={{
                                toolbar: GridToolbar,
                                loadingOverlay: LinearProgress,
                                noResultsOverlay: EmptyPage
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                        disableToolbarButton: true
                                    }
                                },
                            }}
                            sx={{
                                p: 0, pt: 0, height: data?.total>0?null:400
                            }}
                            columns={columns}
                            loading={loading}
                            rows={data?.items??[]}
                            rowCount={data?.total}
                            pageSizeOptions={[10, 25, 50, 100]}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            initialState={{
                                pagination: {
                                    paginationModel: paginationModel
                                }
                            }}
                            pagination
                            paginationMode="server"
                            // onRowDoubleClick={(selection) => {
                            //     console.log("selection", selection.row);
                            //     navigate(route_names["products.edit"].replace(":id", selection.row._id));
                            // }}
                            
                            disableRowSelectionOnClick
                            // checkboxSelection
                            disableColumnMenu
                            disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                        >
                        </StripedDataGrid>
                    </TableContainer>
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

const columns = [
    {   field: 'sku', 
        headerName: 'SKU',
        width: 200,
    },
    {   field: 'title', 
        headerName: 'Title',
        width: 260,
    },
    {   field: 'price', 
        headerName: 'Price',
        width: 120,
        valueFormatter: params => {
            return `$ ${params.value}`
        }
    },
    {   field: 'competitorsLowestPrice', 
        headerName: 'Competitors lowest price',
        width: 120,
        valueFormatter: params => {
            return `$ ${params.value}`
        }
    },
    {   field: 'inventoryQuantity', 
        headerName: 'Inventory',
        width: 120,
    },
    {   field: 'ordersQuantityCount', 
        headerName: 'Sold Quantity',
        width: 140
    },
    {   field: 'reorderQuantity', 
        headerName: 'Reorder Quantity',
        width: 140,
        valueGetter: params => {
            return (params?.row?.ordersQuantityCount*3)??0
        },
    },
    {   field: 'ebayProfitPercentage', 
        headerName: 'Ebay Profit',
        width: 120,
        valueFormatter: params => {
            return `${params.value}%`
        }
    },
    {   field: 'shopifyProfitPercentage', 
        headerName: 'Shopify Profit',
        width: 120,
        valueFormatter: params => {
            return `${params.value}%`
        }
    },
    {   field: 'localProfitPercentage', 
        headerName: 'Local Profit',
        width: 120,
        valueFormatter: params => {
            return `${params.value}%`
        }
    },
    {
        "field": "totalSales",
        "headerName": "Total Sales",
        "description": "Total Sales",
        "width": 130,
        "sortable": false,
        "filterable": false,
        "valueGetter": params => {
            return params?.row?.ordersQuantityCount??0
        },
    },
    {
        "field": "EbaySales",
        "headerName": "Ebay Sales",
        "description": "Ebay Sales",
        "width": 120,
        "sortable": false,
        "filterable": false,
        "valueGetter": params => {
            let marketplace = params?.row?.orders?.filter(x => x?.source === "EBAY")?.[0];
            return marketplace?.ordersCount??0
        },
    },
    {
        "field": "ShopifySales",
        "headerName": "Shopify Sales",
        "description": "Shopify Sales",
        "width": 120,
        "sortable": false,
        "filterable": false,
        "valueGetter": params => {
            let marketplace = params?.row?.orders?.filter(x => x?.source === "SHOPIFY")?.[0];
            return marketplace?.ordersCount??0
        },
    },
    {
        "field": "LocalSales",
        "headerName": "Local Sales",
        "description": "Local Sales",
        "width": 120,
        "sortable": false,
        "filterable": false,
        "valueGetter": params => {
            let marketplace = params?.row?.orders?.filter(x => x?.source === "LOCAL")?.[0];
            return marketplace?.ordersCount??0
        },
    },
];

const past_period_options = [
    {
        label: "7 days",
        value: "7",
    },
    {
        label: "15 days",
        value: "15",
    },
    {
        label: "30 days",
        value: "30",
    },
    {
        label: "45 days",
        value: "45",
    },
    {
        label: "60 days",
        value: "60",
    },
    {
        label: "75 days",
        value: "75",
    },
    {
        label: "90 days",
        value: "90",
    },
    {
        label: "6 months",
        value: "180",
    },
    {
        label: "1 year",
        value: "360",
    }
];

const filter_conditions = {
    "number": [
        {
            label: "is equal to",
            value: "$eq"
        },
        {
            label: "is less than",
            value: "$lt"
        },
        {
            label: "is greater than",
            value: "$gt"
        },
    ],
    "string": [
        {
            label: "is equal to",
            value: "$eq"
        }
    ],
}

const filters_options = [
    {
        column: "Product ID/SKU",
        key: "sku",
        valueType: "string"
    },
    {
        column: "Ebay profit %",
        key: "ebayProfitPercentage",
        valueType: "number"
    },
    {
        column: "Shopify profit %",
        key: "shopifyProfitPercentage",
        valueType: "number"
    },
    {
        column: "Local profit %",
        key: "localProfitPercentage",
        valueType: "number"
    },
    {
        key: "ordersCount",
        column: "Total Sales",
        valueType: "number"
    },
    {
        key: "ordersQuantityCount",
        column: "Sold quantity",
        valueType: "number"
    }
];

