import { Stack, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import SalesVelocityWidget from "../../widgets/SalesVelocityWidget";
import { useEffect, useState } from "react";
import { useAuth, usePrivileges } from "../../providers/AuthProvider";
import { useLocation } from "react-router-dom";
import DateRangeSelection from "../../components/DateRangeSelection";
import SalesByStoreWidget from "../../widgets/SalesByStoreWidget";
import { calculateDate } from "../../functions/dates";
import OutOfStockItemsWidget from "../../widgets/OutOfStockItemsWidget";
import OrderFulfillmentStatusWidget from "../../widgets/OrderFulfillmentStatusWidget";
import privileges from "../../privileges";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesAnalyticsWidget from "../../widgets/SalesAnalyticsWidget";
import InventoryAnalyticsWidget from "../../widgets/InventoryAnalyticsWidget";
import LegacyPage from "../../subcomponents/LegacyPage";

export default function Home(){
    const { user } = useAuth();

    const location = useLocation();
    const { hasPermission } = usePrivileges();
    const params = new URLSearchParams(location.search);
    let filtersQuery =  new URLSearchParams();
    const skuQuery = params.get("sku")??null;
    const startDateQuery = params.get("start_date")??null;
    const endDateQuery = params.get("end_date")??null;
    const [sku, setSku] = useState(skuQuery??"");
    const [start_date, setStartDate] = useState(startDateQuery??calculateDate(0));
    const [end_date, setEndDate] = useState(endDateQuery??calculateDate(0));

    if (sku) {
        filtersQuery.set("sku", String(sku).trim());
    }
    else{
        filtersQuery.delete("sku");
    }

    if(start_date) {
        filtersQuery.set("start_date", start_date);
    }
    else{
        filtersQuery.delete("start_date");
    }

    if(end_date) {
        filtersQuery.set("end_date", end_date);
    }
    else{
        filtersQuery.delete("end_date");
    }

    useEffect(() => {
        document.title = "Home";
    }, []);

    return(
        <LegacyPage>
            <Layout>
                <Layout.Section>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack direction="row" justifyContent="start" width="100%">
                            <Typography
                                component="h5"
                                variant="h5"
                            >
                                {`Welcome ${user.fullName}!`}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="end">
                            <DateRangeSelection
                                onSelection={(startDate, endDate) => {
                                    setStartDate(startDate??null);
                                    setEndDate(endDate??null);
                                }}
                                startDate={start_date}
                                endDate={end_date}
                            >
                            </DateRangeSelection>
                        </Stack>
                    </Stack>
                </Layout.Section>
                {
                    hasPermission(privileges["financial_reporting.view"]) &&
                    <Layout.Section oneThird>
                        <OrdersWidget
                            startDate={start_date}
                            endDate={end_date}
                        />
                    </Layout.Section>
                }
                {
                    hasPermission(privileges["financial_reporting.view"]) &&
                    <Layout.Section oneThird>
                        <SalesAnalyticsWidget
                            startDate={start_date}
                            endDate={end_date}
                        />
                    </Layout.Section>
                }
                {
                    hasPermission(privileges["financial_reporting.view"]) &&
                    <Layout.Section oneThird>
                        <InventoryAnalyticsWidget/>
                    </Layout.Section>
                }
                {
                    hasPermission(privileges["dashboard.widget.view"]) &&
                    <OutOfStockItemsWidget
                        startDate={start_date}
                        endDate={end_date}
                        // title="Inventory Statistics"
                    />
                }
                {/* {
                    hasPermission(privileges["financial_reporting.view"]) &&
                    <Layout.Section oneThird>
                        <SalesVelocityWidget
                            startDate={start_date}
                            endDate={end_date}
                        />
                    </Layout.Section>
                } */}
                {/* {
                    hasPermission(privileges["dashboard.widget.view"]) &&
                    <Layout.Section oneThird>
                        <OrderFulfillmentStatusWidget
                            startDate={start_date}
                            endDate={end_date}
                            // title="Order fullfilments"
                        />
                    </Layout.Section>
                } */}
                {
                    hasPermission(privileges["financial_reporting.view"]) &&
                    <Layout.Section twoThird>
                        <SalesByStoreWidget
                            startDate={start_date}
                            endDate={end_date}
                        />
                    </Layout.Section>
                }
            </Layout>
        </LegacyPage>
    )
}