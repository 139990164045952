import React, { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useDeleteRecord, useFetch, useUpdateRecord } from "../../providers/AppProvider";
import { Button, Stack, TextField, Autocomplete, Checkbox, Popper, IconButton, Grid, FormControlLabel, FormGroup, Link, Popover, Typography, Paper, Card, List, ListItem, ListItemText  } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CsvUploader from "../../components/CsvUploader";
import LegacyDialog from "../../subcomponents/LegacyDialog";
import ExportCSV from "../../subcomponents/ExportCSV";
import LegacyPage from "../../subcomponents/LegacyPage";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FiltersJsonGetQuery from "../../components/FiltersJsonGetQuery";
import FilterProducts from "../../components/FilterProducts";
import ProductTable from "../../components/ProductTable";
import TableLoadingData from "../../components/TableLoadingData";
import Layout from "../../components/Layout";
import { visible_columns_initial } from "../../functions/filters";
import { usePrivileges } from "../../providers/AuthProvider";
import privileges from "../../privileges";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import LegacyCard from "../../subcomponents/LegacyCard";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Research (){
    const { hasPermission } = usePrivileges();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pageSizeLimit = params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 10;
    const pageNumber = params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 0;
    const [paginationModel, setPaginationModel] = useState({
        page: pageNumber,
        pageSize: pageSizeLimit,
    });
    const [filtersQuery, setFiltersQuery] = useState(new URLSearchParams());
    const [pageLoading, setPageLoading] = useState(true);
    const [tabs, setTabs] = useState(initialTabs);
    const [selectedTab, setSelectedTab] = useState(0);
    const [importOpen, setImportOpen] = useState(false);
    const [exportCSVOpen, setExportCSVOpen] = useState(false);
    const [q, setQ] = useState(params.get('q') || "");
    const [showSelectionMenuFilters, setShowSelectionMenuFilters] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(visible_columns_initial());
    const [advancedFiltersData, setAdvancedFiltersData] = useState({});
    const [appliedAdvancedFilters, setAppliedAdvancedFilters] = useState({});

    if (String(q).trim() !== "") {
        params.set("q", String(q).trim());
        params.set("qmw", "sku,checkmateName,title");
    }
    else {
        params.delete("q", q);
    }
    if (paginationModel.pageSize) {
        params.set("limit", paginationModel.pageSize);
    }
    else {
        params.set("limit", 10);
    }
    if (paginationModel.page) {
        params.set("page", parseInt(paginationModel.page) + 1);
    }
    else {
        params.set("page", 1);
    }
    const keys = Object.keys(appliedAdvancedFilters);
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const values = appliedAdvancedFilters[key];
        if (values && values?.length > 0) {
            params.set(`${key}.$in`, values.toString());
        } else {
            params.delete(`${key}.$in`);
        }
    }
    const combinedParams = new URLSearchParams(params.toString() + '&' + filtersQuery.toString());
    let productsURL = `${server_route_names["products.lookup"]}`;
    if (combinedParams.toString()) {
        productsURL += `?${combinedParams.toString()}`;
    }
    else {
        productsURL += `?q=`;
    }
    const { data: updatedRecord, loading: loadingRecord, error: errorUpdateRecord, updateRecord } =  useUpdateRecord(server_route_names.products);
    const { data: initialProducts, error: initialProductsError, fetching: initialProductLoading, reFetch: refreshProducts } = useFetch(productsURL);
    const { data: recordDeleted, loading: recordDeleting, deleteRecord } = useDeleteRecord(server_route_names["products.save.search"]);
    const { data: saveSearches, reFetch: reFetchSaveSearches } = useFetch(`${server_route_names["products.save.search"]}?limit=1000`);
    const { data: filtersMetadata, reFetch: refreshFiltersMetadata } = useFetch(`${server_route_names["products.filters.metadata"]}`);
    const [snackbar, setSnackbar] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Research";
    }, []);

    useEffect(() => {
        let newFilters = JSON.parse(JSON.stringify(tabs[selectedTab].filters));
        let newQuery = FiltersJsonGetQuery(newFilters);
        setFiltersQuery(newQuery);
    }, [tabs,selectedTab]);

    useEffect(() => {
        if(saveSearches){
            let items = saveSearches.items.map(r =>{
                return {
                    label: r.name,
                    filters: r.filters || [],
                    custom: true,
                    _id: r._id,
                    id: r.id
                };
            });
            let tbs = JSON.parse(JSON.stringify(initialTabs));
            tbs = tbs.concat(items);
            setTabs(tbs);
        }
    }, [saveSearches])

    useEffect(() => {
        if (recordDeleted) {
            setSelectedTab(0);
            applyFiltersByTab(0);
            reFetchSaveSearches();
        }
    }, [recordDeleted]);

    useEffect(() => {
        if (initialProducts) {
            setPageLoading(false);
        }
        if (initialProductsError) {
            setPageLoading(false);
        }
    }, [initialProducts, initialProductsError]);

    const applyFiltersByTab = (value) => {
        // Validate and apply the filter
        let newFilters = JSON.parse(JSON.stringify(tabs[value].filters));
        let newQuery = FiltersJsonGetQuery(newFilters);
        setFiltersQuery(newQuery);
    };

    const ApplyFiltersButtonDisabled = () => {
        for (let key in advancedFiltersData) {
            console.log(key);
            if (advancedFiltersData.hasOwnProperty(key) && advancedFiltersData[key].length > 0) {
                return false; // If any value has length greater than 0, return false
            }
        }
        return true; // If all values are empty arrays, return true
    }

    const handleCloseSnackbar = () => setSnackbar(null);

    if (pageLoading) {
        return (
            <LegacyPage
                title="Research"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }

    if (initialProductsError || !initialProducts) {
        return (
            <LegacyPage
                title="Research"
            >
                {initialProductsError}
            </LegacyPage>
        )
    }

    const AutocompleteBox = ({
        title = null,
        type = null
    }) => {
        if (type) {
            return (
                <Autocomplete
                    multiple
                    limitTags={1}
                    fullWidth
                    id={`${type}-tg`}
                    size="small"
                    options={filtersMetadata?.[type]}
                    value={advancedFiltersData?.[type]}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                        let d = {...advancedFiltersData};
                        d[type] = newValue;
                        setAdvancedFiltersData(d);
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selected} />
                                    }
                                    label={option}
                                />
                            </FormGroup>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} size="small" variant="outlined" label={title} />
                    )}
                />
            )
        }
        return null;
    }

    // console.log("selectedOptions", selectedOptions);
    
    return (
        <LegacyPage
            title="Research"
            actions={hasPermission(privileges["products.manage"])?[
                {
                    title: "Import",
                    color: "primary",
                    onClick: () => {
                        setImportOpen(true);
                    }
                }
            ]:[]}
            // slots={}
        >
            {
                exportCSVOpen && (
                    <ExportCSV
                        data={initialProducts?.items}
                        onClose={() => {
                            setExportCSVOpen(false);
                        }}
                    />
                )
            }
            {
                !!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )
            }
            <LegacyDialog
                open={importOpen}
                title="Upload CSV File"
                onClose={() => {
                    setImportOpen(false);
                }}
                secondaryAction={{
                    title: "Close",
                    onClick: () => setImportOpen(false)
                }}
            >
                <CsvUploader
                    fileType="products.create"
                    targetUrl={server_route_names["products.bulk_update_by_csv"]}
                    showButton
                    onUploadDone={() => {
                        setImportOpen(false);
                        refreshProducts();
                        reFetchSaveSearches();
                    }}
                />
            </LegacyDialog>
            <Layout>
                <Layout.Section>
                    <FilterProducts
                        preAppliedFilters={tabs[selectedTab].filters}
                        onFilter={(filters, query) => {
                            setFiltersQuery(query);
                        }}
                        onClearFilters={() => {
                            setFiltersQuery(new URLSearchParams());
                            setAdvancedFiltersData({});
                            setAppliedAdvancedFilters([]);
                            setQ("");
                            navigate('');
                        }}
                        onFilterSave={() => {
                            setSelectedTab(0);
                            applyFiltersByTab(0);
                            reFetchSaveSearches();
                        }}
                        onToggleAdvancedFilters={(show) => {
                            setShowSelectionMenuFilters(show);
                        }}
                        visibleColumns={visibleColumns}
                        onSearchQueryChange={(value) => {
                            setQ(value);
                            navigate(`?q=${value}`);
                        }}
                    />
                </Layout.Section>
                {
                    showSelectionMenuFilters && <Layout.Section>
                        <LegacyCard sectioned title="Advanced filters">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={1}>
                                    {
                                        filtersMenuData.map((r,indx) => (
                                            filtersMetadata[r.type] && (
                                                <Grid item xs={6} md={4} lg={3} xl={3} className={`ADVANCED-FILTER-${indx+1}-${String(r.type).toUpperCase()}`}>
                                                    <AutocompleteBox {...r} />
                                                </Grid>
                                            )
                                        ))
                                    }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            // size="small"
                                            onClick={() => {
                                                if (ApplyFiltersButtonDisabled()) {
                                                    setAppliedAdvancedFilters([]);
                                                }
                                                else{
                                                    setAppliedAdvancedFilters(advancedFiltersData);
                                                }
                                            }}
                                            disabled={ApplyFiltersButtonDisabled()}
                                        >
                                            Apply filters
                                        </Button>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setAdvancedFiltersData({});
                                                setAppliedAdvancedFilters([]);
                                            }}
                                            color="inherit"
                                            size="small"
                                            sx={{ textDecoration: "underline" }}
                                        >
                                            Clear all filters
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </LegacyCard>
                    </Layout.Section>
                }
                <Layout.Section>
                    <ProductTable
                        initialProducts={initialProducts}
                        loading={initialProductLoading || recordDeleting}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        paginationMode="server"
                        onSetVisibleColumns={(columns, details) => {
                            setVisibleColumns(columns);
                        }}
                        isEditAllowed={hasPermission(privileges["products.manage"])}
                    />
                </Layout.Section>
                {
                    tabs[selectedTab]?.custom && (
                        <Layout.Section>
                            <Button color="error"
                                onClick={() => {
                                    deleteRecord(tabs[selectedTab]._id);
                                }}
                                size="small"
                            >Delete this tab</Button>
                        </Layout.Section>
                    )
                }
            </Layout>
        </LegacyPage>
    )
}

const initialTabs = [
    {
        label: "All",
        value: null,
        filters: [],
        custom: false
    }
];

const filtersMenuData = [
    { "title": "Carrier Name", "type": "carrierName" },
    { "title": "Comment", "type": "comment" },
    { "title": "Condition", "type": "condition" },
    { "title": "Custom Category", "type": "customCategory" },
    { "title": "Keyword1", "type": "keyword1" },
    { "title": "Profit Loss Category", "type": "profitLossCategory" },
    { "title": "Shipping Service", "type": "shippingService" },
    { "title": "Vendor", "type": "vendor" }
];