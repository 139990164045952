import { Table, TableHead, TableRow, TableCell, TableBody, Button, Stack, Card, Paper, TableContainer } from "@mui/material";
import { useDeleteRecord, useFetch } from "../../providers/AppProvider";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LegacyPage from "../../subcomponents/LegacyPage";
import route_names from "../../routes/route_names";
import LegacyDialog from "../../subcomponents/LegacyDialog";
import { usePrivileges } from "../../providers/AuthProvider";
import privileges from "../../privileges";
import { useAppData } from "../../providers/AppDataProvider";
import EmptyPage from "../../components/EmptyPage";
import LegacyCard from "../../subcomponents/LegacyCard";
import TableLoadingData from "../../components/TableLoadingData";
import { DataGrid } from "@mui/x-data-grid";

export default function UserReportings() {
    const [query, setQuery] = useState(null);
    let finalQuery = `${server_route_names["reporting"]}`;
    if (query) {
        finalQuery += '?'+query;
    }
    const {data: reportsInitialData, fetching, error, reFetch: refreshReport } = useFetch(finalQuery);
    const { data: recordDeleted, loading: deletingRecord, deleteRecord } = useDeleteRecord(`${server_route_names["reporting"]}`);
    const navigate = useNavigate();
    const [reports, setReports] = useState({});
    const [deletable, setDeletable] = useState({});
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [reportingSummary, setReportingSummary] = useState({});
    const { hasPermission } = usePrivileges();

    const {users} = useAppData();

    useEffect(() => {
        document.title = "Personalized reports";
    }, []);

    useEffect(() => {
        if (reportsInitialData) {
            setReports(reportsInitialData);
        }
    }, [reportsInitialData]);

    const deleteThisReport = async () => {
        const response = await deleteRecord(deletable._id);
        if (response?.status === "success") {
            setShowResponse(true);
        }
        setDeletable({});
        setDeleteAlert(false);
        refreshReport();
    }

    if (error) {
        return (
            <LegacyPage>
                {error}
            </LegacyPage>
        )
    }

    const tableRows = <>
        <TableCell>Name</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Products count</TableCell>
        <TableCell>Frequency</TableCell>
        <TableCell>Report Time</TableCell>
        <TableCell>Report</TableCell>
    </>

    return (
        <LegacyPage
            slots={
                <Stack spacing={1} direction="row" flexWrap="wrap">
                    <Button 
                        size="small"
                        variant={!query?"contained":"text"}
                        onClick={() => {
                            setQuery(null);
                            setSelectedUser(null);
                        }}
                    >
                        All users
                    </Button>
                    {
                        users?.map( user => (
                            <Button key={user.id}
                                size="small"
                                variant={selectedUser?.userId === user.userId?"contained":"text"}
                                onClick={() => {
                                    setQuery(`createdBy=${user.userId}`);
                                    setSelectedUser(user);
                                }}
                            >
                                {user.fullName}
                            </Button>
                        ))
                    }
                </Stack>
            }
        >
            <LegacyDialog
                open={deleteAlert}
                title={`Delete ${deletable.title}`}
                secondaryAction={
                    {
                        title: "Delete",
                        onClick: deleteThisReport,
                        color: "error"
                    }
                }
                primaryAction={
                    {
                        title: "Cancel",
                        onClick: () => setDeleteAlert(!deleteAlert)
                    }
                }
                onClose={() => setDeleteAlert(!deleteAlert)}
            >
                Deleting the report is irreversible and cannot be undone. Ensure you have reviewed all content thoroughly before proceeding.
            </LegacyDialog>
            <Layout>
                {
                    fetching && <Layout.Section><TableLoadingData columns={tableRows} /></Layout.Section>
                }
                {
                    !fetching && 
                    <Layout.Section>
                        {
                            reports?.total === 0 && <EmptyPage title="No report found"/>
                        }
                        {
                            reports?.total > 0 && 
                            <TableContainer component={Card}>
                                <Table sx={{ minWidth: 650 }} size="medium" aria-label="reporting table">
                                    <TableHead>
                                        <TableRow>
                                            {tableRows}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reports?.items?.map((report) => (
                                            <TableRow
                                                key={report.title}
                                            >
                                                <CustomTableCell content={report.title}></CustomTableCell>
                                                <CustomTableCell content={report.description}></CustomTableCell>
                                                <CustomTableCell>
                                                    {
                                                        (reports.summary && reports?.summary[report.id]) && <>
                                                        {
                                                            reports?.summary[report.id]?.count
                                                        }
                                                        </>
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell content={String(report.frequency).toLowerCase()}></CustomTableCell>
                                                <CustomTableCell content={
                                                    moment(report.createdAt).calendar()
                                                }></CustomTableCell>
                                                <CustomTableCell>
                                                    <Stack spacing={1} direction="row">
                                                        <Button size="small" type="button"
                                                            onClick={() => {
                                                                window.open(`${route_names["report.view"].replace(":id",report.id)}`, "_blank");
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            View
                                                        </Button>
                                                        {
                                                            hasPermission(privileges["reporting.manage"]) && 
                                                            <Button size="small" type="button" color="error"
                                                                onClick={() => {
                                                                    setDeletable(report);
                                                                    setDeleteAlert(true);
                                                                }}
                                                                variant="outlined"
                                                            >
                                                                Delete
                                                            </Button>
                                                        }
                                                    </Stack>
                                                </CustomTableCell>
                                                {/* <CustomTableCell>
                                                    <Button color="error" size="small">Delete</Button>
                                                </CustomTableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Layout.Section>
                }
            </Layout>
        </LegacyPage>
    )
}

function CustomTableCell({content, children}){
    return (
        <TableCell component="th" scope="row">
            {content??children}
        </TableCell>
    )
}