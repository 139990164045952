import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useFetch, } from "../../providers/AppProvider";
import server_route_names from "../../routes/server_route_names";
import LegacyCard from "../../subcomponents/LegacyCard";
import LegacyPage from "../../subcomponents/LegacyPage";
import ActivityTable from "./ActivityTable";

const StaffActivity = () => {
    const [paginationModel, paginationModelChange] = useState({
        page: 0,
        pageSize: 25
    });

    const { data: history, fetching: loading } = useFetch(`${server_route_names["history.users"]}?limit=${paginationModel.pageSize}&page=${paginationModel.page+1}`);

    useEffect(() => {
        document.title = "Staff activities";
    }, []);

    return (
        <LegacyPage
            title="Staff activities"
        >
            <Layout>
                <Layout.Section>
                    <ActivityTable
                        loading={loading}
                        data={history}
                        paginationModel={paginationModel}
                        paginationModelChange={paginationModelChange}
                    />
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

export default StaffActivity;