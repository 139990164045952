export default function FiltersJsonGetQuery(filters){
    let query = new URLSearchParams();
    for (let index = 0; index < filters.length; index++) {
        const filter = filters[index];
        console.log(filter);
        if(String(filter.condition).indexOf("$expr_") > -1){
            query.append(`${filter.column}.${filter.condition}`, `${filter.value}`);
        }
        else if (filter.condition === "range") {
            let minValue = filter.value?.min;
            let maxValue = filter.value?.max;
            query.append(`${filter.column}.$gte`, `${minValue}`);
            query.append(`${filter.column}.$lte`, `${maxValue}`);
        }
        else if (filter.condition === "gt"){
            query.append(`${filter.column}.$gt`, `${filter.value}`);
        }
        else if (filter.condition === "lt"){
            query.append(`${filter.column}.$lt`, `${filter.value}`);
        }
        else if (filter.condition === "eq"){
            query.append(`${filter.column}.$eq`, `${filter.value}`);
        }
        else if (filter.condition === "contains"){
            query.append(`${filter.column}.$regex`, `${filter.value}`);
        }
        else if (filter.condition === "not_contain"){
            query.append(`${filter.column}.$not`, `${filter.value}`);
        }
        else if (filter.condition === "in"){
            query.append(`${filter.column}.$in`, `${filter.value}`);
        }
        else{
            query.append(filter.column, filter.value);
        }
    }
    return query;
}