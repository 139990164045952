import { useEffect, useState } from "react";
import Page from "../components/Page";
import { Box, Breadcrumbs, Card, Grid, ImageList, ImageListItem, InputAdornment, Link, MenuItem, Select, Skeleton, Stack, Switch, TableContainer, TextField, Typography } from "@mui/material";
import { useFetch, useGetRecord, useRequest } from "../providers/AppProvider";
import { useNavigate, useParams } from "react-router-dom";
import route_names from "../routes/route_names";
import server_route_names from "../routes/server_route_names";
import { DataGrid } from "@mui/x-data-grid";
import CustomCard from "../components/CustomCard";
import { findObjectByCategoryId, generateRandom, reverseWords } from "../functions/common";
import RenderProductAspects from "../components/RenderProductAspects";
import _enum from "../namespace/enum";
import ClassicFormLayout from "../components/ClassicFormLayout";
import Layout from "../components/Layout";

export default function EditProduct ({isEdit}) {
    const { id } = useParams();
    const { data: categories, fetching: categoriesLoading } = useFetch(server_route_names["category.all"]);
    const { data: aspects, loading: aspectsLoading, sendRequest: getAspectsByCategory } = useRequest();
    const { data: getData, loading: getLoading, error: getError, getRecord } = useGetRecord(server_route_names.products);
    const [pageLoading, setPageLoading] = useState(isEdit?true:false);
    const [product, setProduct] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [productAspects, setProductAspects] = useState(null);

    const navigate = useNavigate();
    useEffect(() => {
        if(id){
            getRecord(id);
        }
    }, [id]);

    useEffect(() => {
        if(getData){
            setProduct(getData);
            setPageLoading(false);
            // getCompatibility(`${server_route_names["ebay.product?.compatibility"]}?id=${getData?.product?._id}`);
        }
    }, [getData]);

    useEffect(() => {
        if (categories?.length > 0 && product?.categoryId) {
            const categoryInfo = findObjectByCategoryId(categories, product?.categoryId, product?.marketplaceId);
            setSelectedCategory(categoryInfo);
            if(categoryInfo?.categoryTreeId){
                getAspectsByCategory(
                    `${server_route_names["ebay.category.aspects_by_category"]}?category_tree_id=${categoryInfo?.categoryTreeId}&category_id=${product?.categoryId}`
                )
            }
        }
    }, [categories, categoriesLoading, product?.categoryId, product?.marketplaceId]);

    useEffect(() => {
        if (aspects && aspects.status === "success" && aspects.data && aspects.data.aspects) {
            setProductAspects(aspects.data);
        }
    },  [aspects, aspectsLoading, categories, categoriesLoading]);

    if (pageLoading) {
        return (
            <Page
                title={<Skeleton variant="text" width={100}></Skeleton>}
                backAction={{
                    onClick: () => {
                        navigate(route_names.products);
                    }
                }}
            >
                <Grid container spacing={2} sx={{width: "100%", m: 0,}}>
                    <Grid item xs={7} lg={7} md={7} sm={7}>
                        <Card sx={{width: "100%", p: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                        
                        <Card sx={{width: "100%", p: 2, mt: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                    </Grid>
                    <Grid item xs={5} lg={5} md={5} sm={5}>
                        <Card sx={{width: "100%", p: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                    </Grid>
                </Grid>
            </Page>
        )
    }

    return (
        <Page
            title={product?.title??product?.sku}
            backAction={{
                onClick: () => {
                    navigate(route_names.products);
                }
            }}
        >
            <Grid container spacing={2} sx={{width: "100%", m: 0,}}>
                <Grid item lg={8} md={12} sm={12}>
                    <CustomCard title="Title">
                        <TextField
                            label="SKU"
                            fullWidth
                            type="text"
                            value={product?.sku}
                            // disabled
                        />
                        <TextField
                            label="Title"
                            fullWidth
                            type="text"
                            value={product?.title}
                            // disabled
                        />
                        <TextField
                            label="Subtitle"
                            fullWidth
                            type="text"
                            value={product?.subtitle}
                            // disabled
                        />
                    </CustomCard>
                    <CustomCard title="Item Category">
                        {
                            selectedCategory && selectedCategory?.categories?.length > 0 &&
                            (
                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {
                                    selectedCategory.categories.map((item, index) => (
                                        <Link underline="none" color="inherit">
                                            {item.categoryName}
                                        </Link>
                                    ))
                                }
                                </Breadcrumbs>
                            )
                        }
                    </CustomCard>
                    
                    <CustomCard title="Item Specifics">
                       {
                            aspectsLoading && (
                                <div>
                                    {
                                        [1,2,3,4,5].map(r => (
                                            <Grid key={`sdse-${r}`} container alignItems="center" spacing={4} mb={1}>
                                                <Grid item sm={12} md={5} lg={5} xl={5}>
                                                    <Skeleton variant="text"></Skeleton>
                                                </Grid>
                                                <Grid item sm={12} md={7} lg={7} xl={7}>
                                                    <Skeleton variant="text"></Skeleton>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            (!aspectsLoading && productAspects) && (
                                <RenderProductAspects
                                    product={product}
                                    aspects={productAspects?.aspects}
                                    onChange={(aspectName, options) => {
                                        const { event, value, reason, details } = options;
                                        let prod = JSON.parse(JSON.stringify(product));
                                        let dataVal = null;
                                        if (typeof value.map === "function") {
                                            dataVal = value.map(r => r.localizedValue);
                                        }
                                        else{
                                            dataVal = [
                                                value?.localizedValue
                                            ];
                                        }
                                        if(dataVal){
                                            prod.aspects[aspectName] = dataVal;
                                        }
                                        setProduct(prod);
                                    }}
                                ></RenderProductAspects>
                            )
                        }
                    </CustomCard>
                    <CustomCard title="Item Compatibility">
                        {
                            (product?.compatibility && product?.compatibility.compatibleProducts && product?.compatibility.compatibleProducts?.length > 0) &&
                            (
                                <TableContainer>
                                    <DataGrid
                                        rows={getCompatibilityRows(product?.compatibility.compatibleProducts)}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                }
                                            },
                                        }} 
                                        columns={compatibilityColumns}
                                        density="compact"
                                        pageSizeOptions={[10,25,50,100]}
                                        selectable={false}
                                        disableRowSelectionOnClick
                                        disableColumnFilter
                                        disableColumnSelector
                                    ></DataGrid>
                                </TableContainer>
                            )
                        }
                    </CustomCard>
                    <CustomCard title="Pricing & Inventory">
                        <CustomProductInfo
                            title="Format"
                            textTransform="capitalize"
                            value={String(product?.format).replace(/\_/g, ' ')}
                        />
                        {
                            (String(product?.format) === "AUCTION") && (
                                <>
                                    <CustomProductInfo
                                        title="Listing duration"
                                        textTransform="capitalize"
                                        value={ reverseWords(String(product?.listingDuration).replace(/\_/g, ' ')) }
                                    />
                                    <ClassicFormLayout label="Floor price">
                                        <TextField 
                                            fullWidth
                                            size="small"
                                            value={product?.floorPrice}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{product?.currency}</InputAdornment>,
                                            }}
                                        />
                                    </ClassicFormLayout>
                                    <ClassicFormLayout label="Buy it now">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={product?.auctionBuyItNowPrice}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{product?.currency}</InputAdornment>,
                                            }}
                                        />
                                    </ClassicFormLayout>
                                    <ClassicFormLayout label="Ceiling price">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={product?.ceilingPrice}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{product?.currency}</InputAdornment>,
                                            }}
                                        />
                                    </ClassicFormLayout>
                                </>
                            )
                        }
                        {
                            String(product?.format) === "FIXED_PRICE" && (
                                <>
                                    <CustomProductInfo
                                        title="Price"
                                        value={`$${product?.price} ${product?.currency}`}
                                        underline={false}
                                    />
                                    <CustomProductInfo
                                        title="Floor Price"
                                        value={`$${product?.floorPrice} ${product?.currency}`}
                                        underline={false}
                                    />
                                    <CustomProductInfo
                                        title="Ebay Lowest Price"
                                        value={`$${product?.ebayLowestPrice} ${product?.currency}`}
                                        underline={false}
                                    />
                                    <CustomProductInfo
                                        title="Ceiling Price"
                                        value={`$${product?.ceilingPrice} ${product?.currency}`}
                                        underline={false}
                                    />
                                </>
                            )
                        }
                        <CustomProductInfo title="In Stock Quantity" textTransform="capitalize" value={product?.inventoryQuantity}/>
                        <CustomProductInfo title="Sold Quantity" textTransform="capitalize" value={product?.soldQuantity}/>
                        <CustomProductInfo title="Upcoming Quantity" textTransform="capitalize" value={product?.upcomingQuantity}/>
                    </CustomCard>
                    <CustomCard title="Images">
                        <ImageList cols={2}>
                            {product?.imageUrls?.map((img, imgIndex) => (
                                <ImageListItem key={img}
                                    sx={{ m: 1 }}
                                >
                                    <img
                                        src={img}
                                        alt={product?.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                            </ImageList>
                    </CustomCard>
                </Grid>
                <Grid item lg={4} md={12} sm={12}>
                    <CustomCard title="Product info">
                        <CustomProductInfo title="Item Number" textTransform="capitalize" value={product?.listingId}/>
                        <CustomProductInfo title="Condition" textTransform="capitalize" value={String(product?.condition).replace(/\_/g, ' ')}/>
                        <CustomProductInfo title="Product Status" textTransform="capitalize" value={product?.status}/>
                        <CustomProductInfo title="MPN" value={product?.mpn}/>
                        <CustomProductInfo title="ePID" value={product?.epid}/>
                    </CustomCard>
                </Grid>
            </Grid>
        </Page>
    )
}

const CustomProductInfo = ({title, value, textTransform, underline }) => {
    if (textTransform === "capitalize") {
        try {
            value = value.toLowerCase();
        } catch (error) {
            
        }
    }

    return (
        <Layout alignItems="center" sx={{mb: 2}}>
            <Layout.Item oneHalf>
                <Typography
                    color={"text.secondary"}
                >
                    {title}
                </Typography>
            </Layout.Item>
            <Layout.Item oneHalf>
                <Link color="inherit" underline={underline===false?"none":"always"} textTransform={textTransform??"inherit"}>
                    {value}
                </Link>
            </Layout.Item>
        </Layout>
    )
}

function getCompatibilityRows(data){
    var rows = [];
    if (data && data.length > 0) {
        rows = data.map((r,i) => {
            r.id = generateRandom(5);
            return r;
        })
    }
    return rows;
}

let compatibilityColumns = [
    {
        field: 'Year',
        headerName: 'Year',
        width: 100,
        filterable: false,
        hideable: false,
        editable: false,
        valueGetter: (params) => {
            if (params?.row?.compatibilityProperties) {
                var value = "";
                var inx = params?.row?.compatibilityProperties.findIndex( x => x.name.toLowerCase() === "year")
                if (inx > -1) {
                    value = params?.row?.compatibilityProperties[inx].value;
                }
                return value;
            }
            return "";
        }
    },
    {
        field: 'Make',
        headerName: 'Make',
        width: 100,
        filterable: false,
        hideable: false,
        editable: false,
        valueGetter: (params) => {
            if (params?.row?.compatibilityProperties) {
                var value = "";
                var inx = params?.row?.compatibilityProperties.findIndex( x => x.name.toLowerCase() === "make")
                if (inx > -1) {
                    value = params?.row?.compatibilityProperties[inx].value;
                }
                return value;
            }
            return "";
        }, editable: false, filterable: false,
    },
    {
        field: 'Model',
        headerName: 'Model',
        width: 100,
        filterable: false,
        hideable: false,
        editable: false,
        valueGetter: (params) => {
            if (params?.row?.compatibilityProperties) {
                var value = "";
                var inx = params?.row?.compatibilityProperties.findIndex( x => x.name.toLowerCase() === "model")
                if (inx > -1) {
                    value = params?.row?.compatibilityProperties[inx].value;
                }
                return value;
            }
            return "";
        }
    },
    {
        field: 'trim',
        headerName: 'Trim',
        width: 180,
        filterable: false,
        editable: false,
        valueGetter: (params) => {
            if (params?.row?.compatibilityProperties) {
                var value = "";
                var inx = params?.row?.compatibilityProperties.findIndex( x => x.name.toLowerCase() === "trim")
                if (inx > -1) {
                    value = params?.row?.compatibilityProperties[inx].value;
                }
                return value;
            }
            return "";
        }
    },
    {
        field: 'engine',
        headerName: 'Engine',
        width: 340,
        filterable: false,
        editable: false,
        valueGetter: (params) => {
            if (params?.row?.compatibilityProperties) {
                var value = "";
                var inx = params?.row?.compatibilityProperties.findIndex( x => x.name.toLowerCase() === "engine")
                if (inx > -1) {
                    value = params?.row?.compatibilityProperties[inx].value;
                }
                return value;
            }
            return "";
        }
    },
    {
        field: 'notes',
        headerName: 'notes',
        width: 340,
        sortable: false,
        editable: false,
        filterable: false,
    }
];
