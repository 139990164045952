import { useEffect, useState } from "react";
import { useFetch, useRequest } from "../providers/AppProvider";
import { Box, Card, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../components/ActionButtons";
import route_names from "../routes/route_names";
import AlertDialog from "../components/AlertDialog";
import StoreDetails from "../components/StoreDetails";
import { FaEbay, FaShopify } from "react-icons/fa6";
import server_route_names from "../routes/server_route_names";
import SkeletonPage from "../components/SkeletonPage";
import { usePrivileges } from "../providers/AuthProvider";
import privileges from "../privileges";
import LegacyPage from "../subcomponents/LegacyPage";
import LegacyDialog from "../subcomponents/LegacyDialog";

const Stores = () => {
    const { data: shopify_stores, error, fetching, reFetch } = useFetch(server_route_names["shopify.stores"]);
    const { data: ebay_stores, fetching: ebay_fetching, reFetch: ReFetchEbay } = useFetch(server_route_names["ebay.stores"]);
    const { data: connectData, loading: connectingToEbay, error: connectError, sendRequest } = useRequest();

    const { hasPermission } = usePrivileges();
    
	const [connecting, setConnecting] = useState(false);
    const [wantConnectEbay, setWantConnectEbay] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);

    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = "Stores";
    }, []);

    const refreshData = async () => {
        await reFetch();
        await ReFetchEbay();
    }

    const connectEbayStore = async () => {
        if (hasPermission(privileges["store.ebay.manage"])) {
            setConnecting(true);
            var response = await sendRequest(server_route_names["ebay.account.connect"]);
            if (response?.authUrl) {
                window.location.href = response?.authUrl;
                // setConnecting(false);
            }
            else{
                setConnecting(false);
            }
        }
        else{
            setWantConnectEbay(false);
            setConnecting(false);
        }
    }

    if (fetching) {
        return (<SkeletonPage />)
    }

	if (ebay_fetching) {
        return (<SkeletonPage />)
    }

    let store_add_buttons = [];

    if(hasPermission(privileges["store.ebay.manage"])){
        store_add_buttons.push({
            name: "Connect eBay store",
            icon: <FaEbay size="32" />,
            onClick: () => {
                setWantConnectEbay(true);
            }
        })
    }

    if(hasPermission(privileges["store.shopify.manage"])){
        store_add_buttons.push({
            name: "Connect Shopify store",
            icon: <FaShopify size="26" />,
            onClick: () => {
                navigate(route_names["shopify.store.connect"])
            }
        })
    }

    return (
        <LegacyPage>
            <LegacyDialog
                open={wantConnectEbay}
                title="Link your eBay store"
                primaryAction={{
                    disabled: connecting,
                    title: connecting? "Connecting...": "Connect",
                    onClick: async () => {
                        connectEbayStore();
                    }
                }}
                secondaryAction={{
                    disabled: connecting,
                    title: "Close",
                    onClick: async () => {
                        setWantConnectEbay(false);
                    }
                }}
                onClose={() => setWantConnectEbay(false)}
            >
                <Stack spacing={2}>
                    <div>
                        <Typography variant="h6">Limitations warning:</Typography>
                        <Typography variant="body2" component="p" color="text.secondary">
                            Please be aware that certain listing features cannot be managed or modified using our software. These features will remain active on your eBay listings even after successfully connecting to this software. It's essential to note that once the connection is complete, you won't be able to make any changes to these features through this software or My eBay. Here are the specific limitations:
                        </Typography>
                    </div>
                    
                    <div>
                        <Typography variant="subtitle2" component="h4" >Listing-Level Buyer Requirements:</Typography>
                        <Typography variant="body2" component="p" color="text.secondary">
                            If you have set any buyer requirements at the individual listing level, these requirements will persist on your listings post-migration. However, you won't be able to adjust or remove them using the API or My eBay.
                        </Typography>
                    </div>
                    
                    <div>
                        <Typography variant="subtitle2" component="h4">Listing Enhancements:</Typography>
                        <Typography variant="body2" component="p" color="text.secondary">
                            Features such as a bold listing title or Gallery Plus, which enhance the visibility of your listings, will continue to be active on migrated listings. But please be aware that you cannot modify or remove these enhancements through the Inventory API or eBay's seller tools once the migration is finalized.
                        </Typography>
                    </div>

                    <div>
                        <Typography variant="body2" component="p" color="text.secondary">
                            By proceeding, you acknowledge and agree to these limitations regarding unsupported listing features. If you have any questions or need to manage these features in the future, you will need to do so directly on the eBay website or platform that supports these specific features.
                        </Typography>
                    </div>
                </Stack>
            </LegacyDialog>
            {
                (ebay_stores && ebay_stores?.length === 0 && shopify_stores && shopify_stores.length === 0) && (
                    <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden', p:4 }}>
                        <Typography>
                            No store found
                        </Typography>
                    </Paper>
                )
            }
			{
				((ebay_stores && ebay_stores.length > 0) || (shopify_stores && shopify_stores.length > 0)) && (
                    <Box sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{ mb: 2 }}>
                            <Tabs
                                // component={Card}
                                value={selectedTab}
                                onChange={(handle, newValue) => {
                                    setSelectedTab(newValue);
                                }}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                                // sx={{px:1}}
                            >
                                {
                                    ebay_stores?.map((s,i) => (
                                        <Tab key={`ix${i}`} label={s.name??s.ebayUserId} {...a11yProps(s._id)} />
                                    ))
                                }
                                {
                                    shopify_stores?.map((s,i) => (
                                        <Tab key={`six${i}`} label={s.name} {...a11yProps(s._id)} />
                                    ))
                                }
                            </Tabs>
                        </Box>
                        <StoreDetails
                            selectedTab={selectedTab}
                            shopify_stores={shopify_stores}
                            ebay_stores={ebay_stores}
                            onDisconnect={(store) => {
                                refreshData();
                            }}
                            onRefresh={() => {
                                refreshData();
                            }}
                        />
                    </Box>
				)
			}
            {
                store_add_buttons.length > 0 && 
                <ActionButtons
                    actions={store_add_buttons}
                />
            }
            {/* <FooterActions /> */}
        </LegacyPage>
    )
}

export default Stores;

function a11yProps(index) {
	return {
	  id: `${index}`,
	  'aria-controls': `tabpanel-${index}`,
	};
}
