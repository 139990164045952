import { createContext, useContext, useEffect, useState } from "react";
import { locals as english } from "../locals/en";
import { fetchApi } from "../functions/fetchApi";
import CustomRequest from "../functions/CustomRequest";

const AppContext = createContext();

export const useTranslate = () => useContext(AppContext);

const AppProvider = ({ children }) => {

    const t = (label) => {
        var text = label;
        if (label) {
            var labels = label.split(".");
            switch (labels.length) {
                case 1:
                    try {
                        text = english[labels[0]];
                    } catch (error) { }
                    break;
                case 2:
                    try {
                        text = english[labels[0]][labels[1]];
                    } catch (error) { }
                    break;
                case 3:
                    try {
                        text = english[labels[0]][labels[1]][labels[2]];
                    } catch (error) { }
                    break;
                case 4:
                    try {
                        text = english[labels[0]][labels[1]][labels[2]][labels[3]];
                    } catch (error) { }
                    break;
                case 5:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]];
                    } catch (error) { }
                    break;
                case 6:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]][
                            labels[5]
                            ];
                    } catch (error) { }
                    break;
                case 7:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]][
                            labels[5]
                            ][labels[6]];
                    } catch (error) { }
                    break;
                case 8:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]][
                            labels[5]
                            ][labels[6]][labels[7]];
                    } catch (error) { }
                    break;
                case 9:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]][
                            labels[5]
                            ][labels[6]][labels[7]][labels[8]];
                    } catch (error) { }
                    break;
                case 10:
                    try {
                        text =
                            english[labels[0]][labels[1]][labels[2]][labels[3]][labels[4]][
                            labels[5]
                            ][labels[6]][labels[7]][labels[8]][labels[9]];
                    } catch (error) { }
                    break;

                default:
                    break;
            }
        }
        if (typeof text !== "string") {
            text = label;
        }
        return text;
    };

    return <AppContext.Provider value={{ t }}>{children}</AppContext.Provider>;
};

export default AppProvider;

export const useFetch = (url, options) => {
    const [fetching, setFetching] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        async function fetchData() {
            try {
                setFetching(true);
                setError(null)
                let fetch_options = {...options||{}, ...{
                    signal: signal 
                }}
                var response = await fetchApi(url, fetch_options);
                if (response?.name === "AbortError") {
                    // console.log("request aborted");
                }
                else{
                    if (response?.status === "success") {
                        setData(response?.data);
                    }
                    else if(response?.message){
                        setError(response?.message);
                    }
                    setFetching(false);
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    // console.log('Fetch aborted',error);
                } else {
                    setError(error);
                }
            }
            finally{
                setFetching(false);
            }
            
        }

        fetchData();

        return () => {
            // Cleanup function to abort the fetch when the component unmounts or when the URL changes
            abortController.abort();
        };
    }, [url]);

    const reFetch = async () => {
        setFetching(true);
        setData(null);
        setError(null);
        var response = await fetchApi(url, options);
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
        return response;
    };

    const fetchMore = async (url, options) => {
        setFetching(true);
        setData(null);
        setError(null);
        var response = await fetchApi(url, options);
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
    };

    const createRecord = async (payload) => {
        setFetching(true);
        setError(null);
        setData(null);
        var response = await fetchApi(url, {
            body: payload,
            type: "POST",
        });
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
    };

    const updateRecord = async (id, payload) => {
        setFetching(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`, {
            body: payload,
            type: "PUT",
        });
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
    };

    const deleteRecord = async (id) => {
        setFetching(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`, {
            type: "DELETE",
        });
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
    };

    const getRecord = async (id) => {
        setFetching(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`);
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setFetching(false);
    };

    return {
        data,
        error,
        fetching,
        fetchMore,
        reFetch,
        getRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};

export const useCreateRecord = (url, storeId) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const createRecord = async (payload) => {
        setLoading(true);
        setError(null);
        setData(null);
        var response = await fetchApi(url, {
            body: payload,
            type: "POST"
        }, storeId);
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setLoading(false);
        return response;
    };

    return { data, error, loading, createRecord };
};

export const useUpdateRecord = (url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const updateRecord = async (id, payload) => {
        setLoading(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`, {
            body: payload,
            type: "PUT",
        });
        if (response?.status === "success") {
            setData(response);
        } else {
            setError(response?.message);
        }
        setLoading(false);
        return response;
    };
    return { data, error, loading, updateRecord };
};

export const useDeleteRecord = (url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const deleteRecord = async (id) => {
        setLoading(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`, {
            type: "DELETE",
        });
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setLoading(false);
        return response;
    };

    return { data, error, loading, deleteRecord };
};

export const useGetRecord = (url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const getRecord = async (id) => {
        setLoading(true);
        setError(null);
        setData(null);
        var response = await fetchApi(`${url}?id=${id}`);
        if (response?.status === "success") {
            setData(response?.data);
        } else {
            setError(response?.message);
        }
        setLoading(false);
        return response;
    };

    return { data, error, loading, getRecord };
};

export const useRequest = () => {
    const [loading, setLoading] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const sendRequest = async (url, options) => {
        setLoading(true);
        setError(null);
        setData(null);
        var response = await fetchApi(url, options);
        if (response?.status === "success") {
            setData(response);
        } else {
            setError(response?.message);
        }
        setLoading(false);
        return response;
    };

    return { data, error, loading, sendRequest };
};

export const useCustomRequest = () => {
    const [sending, setSending] = useState(false);
    const [response, setResponse] = useState(null);

    const send = async (url, options, storeId) => {
        setSending(true);
        setResponse(null);
        const instance = new CustomRequest({ storeId: storeId });
        var responseData = await instance.send(url, options);
        setResponse(responseData);
        setSending(false);
        return responseData;
    }

    return {
        response,
        sending,
        send
    };
};

export const useUploadFile = () => {
    const [uploading, setSending] = useState(false);
    const [data, setResponse] = useState(null);

    const upload = async (url, formData) => {
        setSending(true);
        setResponse(null);
        const instance = new CustomRequest(null, true);
        var responseData = await instance.uploadFile(url, formData);
        setResponse(responseData);
        setSending(false);
        return responseData;
    }

    return {
        data,
        uploading,
        upload
    };
};