
const server_route_names = {
    "shopify.stores":                                   "/api/v1/shopify_stores",
    "ebay.stores":                                      "/api/v1/ebay_stores",
    "category.all":                                     "/api/v1/category_trees",
    "ebay.category.subtree":                            "/ebay/category/subtree",
    "ebay.category.suggestions":                        "/ebay/category/suggestions",
    "ebay.category.tree":                               "/ebay/category/tree",
    "ebay.category.compatibility_properties":           "/ebay/category/compatibility_properties",
    "ebay.category.compatibility_property_values":      "/ebay/category/compatibility_property_values",
    "ebay.category.default_tree":                       "/ebay/category/default_tree",
    "ebay.category.aspects_by_category":                "/ebay/category/aspects_by_category",
    "ebay.category.create_or_replace_compatibility":    "/ebay/category/create_or_replace_compatibility",
    "ebay.offers":                                      "/ebay/offers",
    "ebay.offers.get":                                  "/ebay/offers/get",
    "ebay.product.compatibility":                       "/ebay/product/compatibility",
    "ebay.account.connect":                             "/ebay/account/connect",
    "ebay.store.resync":                                "/ebay/store/resync",
    "ebay.store.bulk_migrate_listing":                  "/ebay/store/bulk_migrate_listing",
    "shopify.store.resync":                             "/shopify/store/resync",
    "users":                                            "/api/v1/users",
    "users.update":                                     "/api/v1/users/update",
    "password.forgot":                                  "/auth/account/password/forgot",
    "otp.resend":                                       "/auth/account/otp/resend",
    "account.activate":                                 "/auth/account/activate",
    "account.password.update":                          "/auth/account/password/update",
    "account.verify":                                   "/auth/account/verify_user",
    "account.login":                                    "/auth/login",
    "account.verify.token":                             "/auth/account/verify_token",
    "account.invitation.accept":                        "/auth/account/accept_invitation",
    "account.password.create":                          "/auth/account/password/create",
    "checkmate.bulk_update_by_csv":                     "/api/v1/checkmate/bulk_update_by_csv",
    "vendor_sheet.bulk_update_by_csv":                  "/api/v1/vendor_sheet/bulk_update_by_csv",
    "products.bulk_update_by_csv":                      "/api/v1/products/bulk_update_by_csv",
    "orders":                                           "/api/v1/orders",
    "orders.lookup":                                    "/api/v1/orders/lookup",
    "products":                                         "/api/v1/products",
    "products.lookup":                                  "/api/v1/products/lookup",
    "products.repricer":                                "/api/v1/products/repricer",
    "products.save.search":                             "/api/v1/products/save_search",
    "products.history":                                 "/api/v1/products/history",
    "products.filters.metadata":                        "/api/v1/products/filters/metadata",
    "user.invite":                                      "/api/v1/staff/add",
    "customers":                                        "/api/v1/customers",
    "myaccount":                                        "/api/v1/myaccount",
    "inventory.reports":                                "/api/v1/inventory/reports",
    "sales.velocity":                                   "/api/v1/sales_velocity",
    "sales_velocity.sku":                               "/api/v1/sales_velocity/sku",
    "sales.velocity.reports":                           "/api/v1/sales_velocity/reports",
    "sales.velocity.sales_metrics":                     "/api/v1/sales_velocity/sales_metrics",
    "sales.velocity.sales_by_store":                    "/api/v1/sales_velocity/sales_by_store",
    "inventory.reports.profit_metrics":                 "/api/v1/inventory/reports/profit_metrics",
    "shopify.products":                                 "/shopify/products",
    "shopify.products.bulk_create":                     "/shopify/products/bulk_create",
    "shopify.products.all_import":                     "/shopify/products/all_import",
    "reporting":                                        "/api/v1/reporting",
    "ordering_sheet":                                   "/api/v1/ordering_sheet",
    "vendor_sheet":                                     "/api/v1/vendor_sheet",
    "vendor_sheet.image_uplaod":                        "/api/v1/vendor_sheet/image_upload",
    "unauthorized_access.users":                        "/unauthorized_access/users",
    "dashboard.widget":                                 "/api/v1/dashboard/widgets",
    "pos.ymm":                                          "/api/v1/products/ymm/pos",
    "history.users":                                    "/api/v1/history/users",
    "history":                                          "/api/v1/history",
    "repricer.suspects":                                "/api/v1/suspects",
    "repricer.suspects.dont_compete":                   "/api/v1/suspects/dont_compete",
    "repricer.suspects.bulk_update":                    "/api/v1/suspects/bulk_update",
    "repricer.competitors":                             "/api/v1/competitors",
    "repricer.competitors.rescan":                      "/api/v1/competitors/rescan",
};

export default server_route_names;