import React, { useCallback, useEffect, useState } from 'react';
import { useUploadFile } from '../providers/AppProvider';
import { Typography, Alert, Avatar } from '@mui/material';
import { generateRandom } from '../functions/common';

function ImageUploader({
    onUploadDone = (uploaded_file) => {},
    fileType = "",
    targetUrl = "",
    imageId = "",
    title = null,
    disable = false,
    imageUrl = null
}) {
    const { upload, data: fileUploaded, uploading } = useUploadFile();
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const onFileChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setErrorMessage(null);
        }
    }, []);

    const handleFileUpload = async (file) => {
        let fileName = selectedFile.name.replace(/\s+/gi,'').toLowerCase();
        const formData = new FormData();
        formData.append('image', selectedFile, fileName);
        var uploaded_file = await upload(`${targetUrl}?type=${fileType??'image.upload'}&id=${imageId}`, formData);
        onUploadDone(uploaded_file);
    };

    useEffect(() => {
        if (fileUploaded) {
            setSelectedFile(null);
        }
    }, [fileUploaded, uploading]);

    useEffect(() => {
        if (selectedFile) {
            handleFileUpload();
        }
    }, [selectedFile]);

    if (fileUploaded) {
        return (
            <div>
                {
                    (fileUploaded && fileUploaded.status === "success") && (
                        <Alert severity="success">{fileUploaded.message}</Alert>
                    )
                }
                {
                    (fileUploaded && fileUploaded.status !== "success") && (
                        <Alert severity="error">{fileUploaded.message}</Alert>
                    )
                }
            </div>
        )
    }

    let _rID = `image-upload-input${generateRandom(10)}`;

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                id={_rID}
                style={{ display: 'none' }}
                onChange={onFileChange}
                disabled={disable}
            />
            <label htmlFor={_rID}>
                {
                    imageUrl &&
                    <Avatar
                        alt={`Image-${_rID}`}
                        src={imageUrl}
                        sx={{ width: 84, height: 84, borderRadius: 0 }}
                    />
                }
                {
                    !imageUrl &&
                    <Typography sx={{border: "1px dashed", p:1, textAlign: "center", cursor: "pointer" }} variant="caption">
                        {selectedFile ? selectedFile.name : title??'Upload image'}
                    </Typography>
                }
            </label>
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </div>
    );
}

export default ImageUploader;
