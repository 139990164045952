import { Paper, Stack, TableContainer, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

const ActivityTable = ({
    items = [],
    data = {
        "limit": 0,
        "page": 1,
        "total": 0,
        "pages": 1,
        "items": [],
    },
    paginationModel = {
        page: 0,
        pageSize: 25
    },
    paginationModelChange = () => {},
    loading = true
}) => {

    return (
        <TableContainer component={Paper}>
            <DataGrid
                rows={data?.items??[]}
                columns={columns}
                rowCount={data?.total??0}
                paginationModel={paginationModel}
                onPaginationModelChange={paginationModelChange}
                paginationMode="server"
                loading={loading}
                sx={{ height: data?.total > 0?"auto":400 }}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            ></DataGrid>
        </TableContainer>
    )
}

const columns = [
    {
        field: "createdAt",
        headerName: "Date Time",
        width: 130,
        sortable: false,
        valueFormatter: params => {
            return moment(params.value).format("DD/MM hh:mma")
        }
    },
    {
        field: "userName",
        headerName: "User Name",
        width: 180,
        sortable: false,
    },
    {
        field: "activityType",
        headerName: "Activity Type",
        width: 160,
        sortable: false,
    },
    {
        field: "action",
        headerName: "Action",
        width: 180,
        sortable: false,
    },
    {
        field: "comment",
        headerName: "Comment",
        width: 400,
        sortable: false,
    },
    {
        field: "History Track",
        headerName: "History",
        width: 600,
        sortable: false,
        renderCell: params => {
            return <Stack direction="row" key={params.row.id}>
                <Stack direction="column">
                    {
                        params?.row?.history?.length > 0 &&
                        params?.row?.history?.map(h => (
                            h?.name?<Stack key={h.name}>
                                <Typography>{h?.name} was changed from {h?.newValue} to {h?.oldValue}</Typography>
                            </Stack>:null
                        ))
                    }
                </Stack>
            </Stack>
        }

    }
];

export default ActivityTable;
