import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useFetch, useUpdateRecord } from "../../providers/AppProvider";
import { Avatar, Button, ButtonGroup, Chip, FormControl, IconButton, InputLabel, Link, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import _enum from "../../namespace/enum";
import LegacyPage from "../../subcomponents/LegacyPage";
import TableLoadingData from "../../components/TableLoadingData";
import Layout from "../../components/Layout";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import LegacyModal from "../../subcomponents/LegacyModal";
import { EbayLink } from "../../functions/common";
import moment from "moment";


export default function Competitors() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [pageNumber, setPageNumber] = useState(params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 1);
    const [pageSizeLimit, setPageSizeLimit] = useState(params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 200);
    const [pageLoading, setPageLoading] = useState(true);
    const [floorPrice, setFloorPrice] = useState(0);
    const [ceilingPrice, setCeilingPrice] = useState(0);
    const [comment, setComment] = useState("");
    const [wantUpdate, setWantUpdate] = useState(false);
    
    if (pageSizeLimit) {
        params.set("limit", pageSizeLimit);
    }
    else {
        params.set("limit", 250);
    }
    if (!isNaN(pageNumber) && Number(pageNumber) > 0) {
        params.set("page", parseInt(pageNumber));
    }
    else {
        params.set("page", 1);
    }
    const combinedParams = new URLSearchParams(params.toString());
    let productsURL = `${server_route_names["repricer.competitors"]}`;
    if (combinedParams.toString()) {
        productsURL += `?${combinedParams.toString()}`;
    }
    const { data, error, fetching, reFetch } = useFetch(productsURL);
    const { data: recordUpdated, updateRecord, loading: recordUpdating } = useUpdateRecord(`${server_route_names["repricer.suspects"]}`);
    const { data: rescanData, updateRecord: RescanData, loading: rescanning } = useUpdateRecord(`${server_route_names["repricer.competitors.rescan"]}`);
    const { data: productUpdated, updateRecord: updateProduct, loading: updatingProduct } = useUpdateRecord(`${server_route_names["products"]}`);
    const [ suspectData, setSuspectData ] = useState({});
    
    useEffect(() => {
        document.title = "Competitors";
    }, []);

    useEffect(() => {
        console.log("Page refreshed");
        if (data) {
            setSuspectData(data);
            if (data?.product?.floorPrice) {
                setFloorPrice(data?.product?.floorPrice);
            }
            if (data?.product?.ceilingPrice) {
                setCeilingPrice(data?.product?.ceilingPrice);
            }
        }
        setPageLoading(false);
    }, [data, data?.product, data?.suspects]);

    if (pageLoading) {
        return (
            <LegacyPage
                title="Competitors"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }

    if (error) {
        return (
            <LegacyPage
                title="Competitors"
            >
                {error}
            </LegacyPage>
        )
    }
    const product = suspectData?.product??{};
    const pagination = {
        count: suspectData?.suspects?.count ?? 0,
        limit: suspectData?.suspects?.limit ?? 0,
        next: suspectData?.suspects?.next ?? null,
        prev: suspectData?.suspects?.prev ?? null,
        page: suspectData?.suspects?.page ?? 0,
        pages: suspectData?.suspects?.pages ?? 0,
        total: suspectData?.suspects?.total ?? 0
    };
    const competitors_html = <Layout.Section twoThird>
        <TableContainer sx={{ minWidth: 650, height: 650, overflow: "hidden", overflowY: "auto" }}>
            <Table aria-label={`Competitors table`} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Item ID</TableCell>
                        <TableCell>Avil Qty</TableCell>
                        <TableCell>Current Price + Ship</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    suspectData?.suspects?.items?.map((suspect, index) => (
                        <TableRow key={`tki${index}`}>
                            <TableCell>
                                <Avatar sx={{ width: 64, height: 64, borderRadius: 0 }} src={suspect?.image} alt={`Image of suspect ${suspect.title}`}></Avatar>
                            </TableCell>
                            <TableCell>
                                <Stack direction="column" spacing={1}>
                                    <Link
                                        sx={{cursor:"pointer"}}
                                        color="text.primary"
                                        underline="hover"
                                        onClick={() => {
                                            window.open(EbayLink(suspect?.legacyItemId),"_blank");
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {suspect.title}
                                        </Typography>
                                    </Link>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction="column" spacing={0}>
                                    <div>{suspect?.seller?.username}</div>
                                    <div>
                                        <Link
                                            sx={{cursor:"pointer"}}
                                            underline="hover"
                                            onClick={() => {
                                                window.open(EbayLink(suspect?.legacyItemId),"_blank");
                                            }}
                                        >
                                            {suspect?.legacyItemId}
                                        </Link>
                                    </div>
                                    <div>
                                        <Stack direction="row" spacing={1}>
                                            <Chip color="default" size="small" label={suspect.condition}></Chip>
                                        </Stack>
                                    </div>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction="column" spacing={0}>
                                    <div>
                                        {suspect?.quantity}
                                    </div>
                                    {
                                        Number(suspect?.soldQuantity) > 0 && 
                                        <Typography color="text.secondary" variant="caption" noWrap>
                                            {suspect?.soldQuantity} sold
                                        </Typography>
                                    }
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction="column" spacing={0}>
                                    <div
                                        className={`${Number(suspect?.totalPrice) < Number(product?.floorPrice)?"red-circle":""}`}
                                    >
                                        <Typography color="text.primary" variant="subtitle1" noWrap>
                                            ${Number(suspect?.totalPrice).toFixed(2)}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="caption"
                                            color="text.primary"
                                            noWrap
                                        >Last updated</Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            noWrap
                                        >{moment(suspect?.updatedAt).format('MMMM DD, YYYY hh:mm A')}</Typography>
                                    </div>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction={"column"} spacing={0}>
                                    <Tooltip title="Update competitor data now">
                                        <IconButton
                                            color="success"
                                            onClick={async () => {
                                                await RescanData(suspect?._id, {
                                                    productId: suspect?.productId,
                                                    competitorId: suspect?.legacyItemId
                                                });
                                                reFetch();
                                            }}
                                        >
                                            <UpdateIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete competitor">
                                    <IconButton
                                        color="warning"
                                        onClick={async () => {
                                            if(window.confirm(`Want to remove ${suspect.legacyItemId} competitor?`)){
                                                await updateRecord(suspect?._id, {
                                                    data: {
                                                        isCompetitor: false
                                                    }
                                                });
                                                reFetch();
                                            }
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
    </Layout.Section>;
    const our_product_html = <Layout.Section oneThird>
        <TableContainer component={Table}>
            <Stack direction="column" spacing={2} padding={2} sx={{ height: 650, overflow: "hidden", overflowY: "auto"}}>
                <div>
                    <img width="100%" src={product.images?.[0]} alt={`Image for ${product.title}`}></img>
                </div>
                <Typography>
                    <Link
                        underline="hover"
                        sx={{cursor:"pointer"}}
                        onClick={() => {
                            // window.open(EbayLink(suspect?.legacyItemId),"_blank");
                        }}
                    >
                        {product.sku}
                    </Link>
                </Typography>
                <Typography>{product.title}</Typography>
                <Stack direction="column" spacing={2}>
                    <div>
                        <TextField
                            fullWidth
                            label="Price"
                            type="number"
                            value={product.price}
                            defaultValue={0}
                            onChange={() => {}}
                        ></TextField>
                    </div>
                    <div>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Floor Price"
                                value={floorPrice}
                                defaultValue={0}
                                type="number"
                                onChange={(e) => {
                                    setFloorPrice(e.target.value)
                                }}
                            ></TextField>
                            <TextField
                                label="Ceiling Price"
                                value={ceilingPrice}
                                defaultValue={0}
                                type="number"
                                onChange={(e) => {
                                    setCeilingPrice(e.target.value)
                                }}
                            ></TextField>
                        </Stack>
                    </div>
                    <div>
                        <Button
                            fullWidth
                            onClick={() => {
                                setWantUpdate(true);
                            }}
                            variant="contained"
                        >Update</Button>
                    </div>
                </Stack>
            </Stack>
        </TableContainer>
    </Layout.Section>
    const headerSlots = pagination?.total?<Stack direction="row" spacing={1} alignItems="center">
        <Typography
            noWrap
            color="text.secondary"
        >
           {(pagination.page - 1)*(pagination.limit)} - {((pagination.page - 1)*(pagination.limit))+(pagination.count)} of {pagination.total}
        </Typography>
        <ButtonGroup>
            <IconButton
                disabled={!pagination.prev || pageLoading}
                onClick={() => {
                    if (Number(pageNumber-1) > 0) {
                        setPageNumber(pageNumber-1);
                    }
                }}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                disabled={!pagination.next || pageLoading}
                onClick={() => {
                    if (Number(pageNumber-1) <= Number(pagination.pages)) {
                        setPageNumber(pageNumber+1);
                    }
                }}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
        </ButtonGroup>
    </Stack>:null;
    return (
        <LegacyPage
        fullWidth
            title="Competitors"
            headerSlots={headerSlots}
        >
            {
                wantUpdate &&
                <LegacyModal
                    title="Want to update Floor/Ceiling Price?"
                    onCancel={() => {
                        setWantUpdate(false);
                    }}
                    onClose={() => {
                        setWantUpdate(false);
                    }}
                    primaryAction={{
                        title: "Update",
                        disabled: updatingProduct || !comment || String(comment).length < 3,
                        onClick: async() => {
                            let d = await updateProduct(product?._id, {
                                product: {
                                    floorPrice: floorPrice,
                                    ceilingPrice: ceilingPrice
                                },
                                chx: [
                                    {
                                        id: product?._id,
                                        name: "floorPrice",
                                        oldValue: product?.floorPrice,
                                        newValue: floorPrice
                                    },
                                    {
                                        id: product?._id,
                                        name: "ceilingPrice",
                                        oldValue: product?.ceilingPrice,
                                        newValue: ceilingPrice
                                    },
                                ],
                                comment: comment
                            });
                            if (d?.status === "success") {
                                setWantUpdate(false);
                                setComment("");
                                reFetch();
                            }
                            else{
                                alert("Error");
                            }
                        }
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        label="Comment"
                        multiline
                        rows={4}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        helperText="Please mention reason why are you chaning this price."
                    />
                </LegacyModal>
            }
            <Layout alignItems="initial">
                {our_product_html}
                {competitors_html}
            </Layout>
        </LegacyPage>
    )
}

function TableSeparator({}){
    return <TableCell sx={{
        // width: 1,
        // minWidth: 1,
        // maxWidth: 1,
        // border: "1px solid",
        borderColor: theme => theme.palette.divider,
        borderTopColor: theme => theme.palette.background.default,
        borderBottomColor: theme => theme.palette.background.default,
        backgroundColor: theme => theme.palette.background.default,
    }}></TableCell>
}