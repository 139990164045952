import { useEffect, useState } from "react";
import Page from "../components/Page";
import { Box, Card, Grid, InputAdornment, Paper, Radio, RadioGroup, Skeleton, TextField, Typography } from "@mui/material";
import { useCreateRecord, useDeleteRecord, useFetch, useGetRecord, useUpdateRecord } from "../providers/AppProvider";
import Toast from "../components/Toast";
import { useNavigate, useParams } from "react-router-dom";
import route_names from "../routes/route_names";
import DeleteIcon from '@mui/icons-material/Delete';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AlertDialog from "../components/AlertDialog";
import server_route_names from "../routes/server_route_names";
import Layout from "../components/Layout";
import CustomCard from "../components/CustomCard";
import FormLayout from "../components/FormLayout";

const ConnectShopifyStore = ({isEdit}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: createData, loading: creatingRecord, error: createError, createRecord } = useCreateRecord(server_route_names["shopify.stores"]);
    const { data: getData, loading: getLoading, error: getError, getRecord } = useGetRecord(server_route_names["shopify.stores"]);
    const { data: deleted, loading: deleting, error: delError, deleteRecord } = useDeleteRecord(server_route_names["shopify.stores"]);
    const { data: updated, loading: updating, error: updateError, updateRecord } = useUpdateRecord(server_route_names["shopify.stores"]);
    
    const [name, setName] = useState("");
    const [domain, setDomain] = useState("");
    const [note, setNote] = useState("");
    const [api_key, setApiKey] = useState("");
    const [secret_key, setSecretKey] = useState("");
    const [access_token, setAccessToken] = useState("");
    const [pageLoading, setPageLoading] = useState(isEdit?true:false);

    const [updatedSuccess, setUpdatedSuccess] = useState(false);
    const [updatedError, setUpdatedError] = useState(false);

    const [deletedSuccess, setDeletedSuccess] = useState(false);
    const [deletedError, setDeletedError] = useState(false);

    const [createdSuccess, setCreatedSuccess] = useState(false);
    const [createdError, setCreatedError] = useState(false);

    const [wantDelete, setWantDelete] = useState(false);
    
    useEffect(() => {
        if (updated) {
            setUpdatedSuccess(true);
        }
    }, [updated]);

    useEffect(() => {
        if (updateError) {
            setUpdatedError(updateError?.message);
        }
    }, [updateError]);

    useEffect(() => {
        if(id){
            getRecord(id);
        }
    }, [id]);

    useEffect(() => {
        if(delError){
            setDeletedError(delError);
        }
    }, [delError]);

    useEffect(() => {
        if (deleted) {
            navigate(route_names.stores);
        }
    }, [deleted]);

    useEffect(() => {
        if (getData) {
            setName(getData.name);
            setDomain(getData.domain);
            setNote(getData.note);
            setPageLoading(false);
        }
    }, [getData, getLoading]);

    useEffect(() => {
        if (getError) {
            setPageLoading(false);
        }
    }, [getError]);

    useEffect(() => {
        if (createData) {
            setCreatedSuccess(true);
            setName("");
            setDomain("");
            setNote("");
            setApiKey("");
            setAccessToken("");
            setSecretKey("");
            navigate(route_names.stores);
        }
    }, [createData]);

    useEffect(() => {
        if (createError) {
            setCreatedError(createError);
        }
    }, [createError]);

    useEffect(() => {
        document.title = "Connect Shopify store";
    }, []);

    const validated = () => {
        if(!name) return false;
        if(!domain) return false;
        if (!isEdit) {
            if(!api_key) return false;
            if(!secret_key) return false;
            if(!access_token) return false;
        }
        return true;
    };

    if (pageLoading) {
        return (
            <Page
                title={isEdit?"Edit store":"Connect Shopify store"}
                backAction={{
                    onClick: () => {
                        navigate(route_names.stores);
                    }
                }}
            >
                <Grid container spacing={2} sx={{width: "100%", m: 0,}}>
                    <Grid item xs={7} lg={7} md={7} sm={7}>
                        <Card sx={{width: "100%", p: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                        
                        <Card sx={{width: "100%", p: 2, mt: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                    </Grid>
                    <Grid item xs={5} lg={5} md={5} sm={5}>
                        <Card sx={{width: "100%", p: 2}}>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                            <Skeleton variant="text"></Skeleton>
                        </Card>
                    </Grid>
                </Grid>
            </Page>
        )
    }

    if(!getData && isEdit){
        return (
            <Page
                title="404"
                backAction={{
                    onClick: () => {
                        navigate(route_names.stores);
                    }
                }}
            >
                <Typography>
                    Store not found
                </Typography>
            </Page>
        )
    }

    const primaryAction = {
        title: isEdit?"Update":"Connect",
        icon: <AddBusinessIcon />,
        onClick: () => {
            if(id){
                // update record
                updateRecord(id, {
                    name,
                    domain
                });
            }
            else{
                createRecord({
                    name,
                    domain,
                    apiKey: api_key,
                    apiSecretKey: secret_key,
                    accessToken: access_token
                })
            }
        },
        disabled: updating?true:creatingRecord?true:!validated()
    }

    return (
        <Page
            title={isEdit?"Edit store":"Connect Shopify store"}
            primaryAction={primaryAction}
            backAction={{
                onClick: () => {
                    navigate(route_names.stores);
                }
            }}
            actions={isEdit?[
                {
                    title: "Delete",
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setWantDelete(true);
                    }
                }
            ]:[]}
        >
            {
                createdSuccess && <Toast open={true} onClose={() => { setCreatedSuccess(false) }} message="Created successfuly"></Toast>
            }
            {
                createdError && <Toast open={true} onClose={() => { setCreatedError(false) }} type="error" message={createdError}></Toast>
            }

            {
                deletedError && <Toast open={true} onClose={() => { setDeletedError(false) }} type="error" message={deletedError}></Toast>
            }

            {
                updatedError && <Toast open={true} onClose={() => { setUpdatedError(false) }} type="error" message={updatedError}></Toast>
            }
            {
                updatedSuccess && <Toast open={true} onClose={() => { setUpdatedSuccess(false) }} message="Updated successfuly"></Toast>
            }
            {
                wantDelete && (
                    <AlertDialog
                        title={`Delete "${name}"?`}
                        content="Deleting this means no turning back. All ties to this store, including products, orders, and customers, vanish forever—irretrievable."
                        primaryAction={{
                            title: "Delete",
                            color: "error",
                            disabled: deleting,
                            onClick: async () => {
                                var del = await deleteRecord(id);
                                setWantDelete(false);
                            }
                        }}
                        secondaryAction={{
                            title: "Close",
                            disabled: deleting,
                            onClick: async () => {
                                setWantDelete(false);
                            }
                        }}
                        onClose={() => {
                            setWantDelete(false);
                        }}
                    ></AlertDialog>
                )
            }
            <Layout alignItems="center">
                <Layout.Annotated
                    title="Basic information"
                    content="You can write unique shop name to identify store when pushing products, managing inventory and track orders."
                >
                    <CustomCard title="Name & domain">
                            <TextField
                                required
                                margin="normal"
                                label="Store name"
                                fullWidth
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                            <TextField
                                required
                                margin="normal"
                                label="Store domain"
                                fullWidth
                                type="text"
                                value={domain}
                                onChange={(e) => {
                                    setDomain(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
                                }}
                                disabled={isEdit}
                            />
                    </CustomCard>
                </Layout.Annotated>
                <Layout.Annotated
                    title="API Credentials"
                    content="Please create a custom app in your Shopify admin and copy API Credentials"
                >
                    <CustomCard>
                        <TextField
                            required
                            margin="normal"
                            label="API KEY"
                            fullWidth
                            type={isEdit?"password":"text"}
                            disabled={isEdit}
                            value={isEdit?"***************":api_key}
                            onChange={(e) => {
                                setApiKey(e.target.value);
                            }}
                        />
                        <TextField
                            required
                            margin="normal"
                            label="API SECRET KEY"
                            fullWidth
                            type={isEdit?"password":"text"}
                            disabled={isEdit}
                            value={isEdit?"***************":secret_key}
                            onChange={(e) => {
                                setSecretKey(e.target.value);
                            }}
                        />
                        <TextField
                            required
                            margin="normal"
                            label="ACCESS TOKEN"
                            fullWidth
                            type={isEdit?"password":"text"}
                            disabled={isEdit}
                            value={isEdit?"***************":access_token}
                            onChange={(e) => {
                                setAccessToken(e.target.value);
                            }}
                        />
                    </CustomCard>
                </Layout.Annotated>
            </Layout>
        </Page>
    )
}

export default ConnectShopifyStore;