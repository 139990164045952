import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Container, Divider, ListItemIcon, ListItemText, Stack, useMediaQuery, useTheme } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PasswordIcon from '@mui/icons-material/Password';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useNavigate } from 'react-router-dom';
import route_names from '../../routes/route_names';
import { useAuth } from '../../providers/AuthProvider';
import Logo from './Logo';
import { NameAvatar } from '../../components/CustomAvatar';
import { useThemeMode } from '..';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const AppTopBar = (props) => {
  const { onNavOpen, drawerWidth } = props;
  
  const theme = useTheme();
  const colorMode = useThemeMode();

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Container sx={{pb: 2}}>
        <Stack direction="row" spacing={2} alignItems="center">
          <NameAvatar name={`${user?.firstName} ${user?.lastName}`} />
          <Stack direction="column" spacing={0}>
            <Typography color="inherit" variant="h6">
                {user?.fullName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
                {user?.email}
            </Typography>
          </Stack>
        </Stack>
      </Container>
      <Divider></Divider>
      <MenuItem onClick={() => {
        handleMenuClose();
        navigate(route_names.myaccount);
      }}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>My account</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose();
        navigate(`${route_names.myaccount}?view=password`);
      }}>
        <ListItemIcon>
          <PasswordIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change password</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={colorMode.toggleColorMode}
      >
        <ListItemIcon>
          {theme.palette.mode === 'dark' ? <DarkModeIcon fontSize="small"/> : <LightModeIcon fontSize="small"/>}
        </ListItemIcon>
        <ListItemText>{theme.palette.mode === 'dark' ? "Light theme" : "Dark theme"}</ListItemText>
      </MenuItem>
      <Divider></Divider>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          handleMobileMenuClose();
          logout();
        }}
      >
        <ListItemIcon>
        <LogoutIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
       <MenuItem onClick={() => {
          handleMenuClose();
          navigate(route_names.myaccount);
        }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <NameAvatar name={`${user?.firstName} ${user?.lastName}`} />
            <Typography>
              {user?.firstName} {user?.lastName}
            </Typography>
          </Stack>
        </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backdropFilter: 'blur(6px)',
          boxShadow: "none",
          // backgroundColor: blueGrey[900],
          // color: common.white,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        color='primary'
        // enableColorOnDark
      >
          <Toolbar>
            <Logo>
            {
              !lgUp && (<IconButton
                onClick={onNavOpen}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>)
            }
            </Logo>
            <Box sx={{ flexGrow: 1 }} />
            {/* {
              mdUp && (
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
              )
            } */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <NameAvatar name={user?.fullName}></NameAvatar>
                  {/* <Typography color="inherit">
                    {user?.fullName}
                  </Typography> */}
                </Stack>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </>
  );
}

export default AppTopBar;