import { createContext, useContext, useEffect, useState } from "react";
import store from "store2";
import namespace from "../namespace";
import { fetchApi } from "../functions/fetchApi";
import { useMatch, useNavigate } from "react-router-dom";
import route_names from "../routes/route_names";
import AuthRoutes from "../auth/AuthRoutes";
import AppDataProvider from "./AppDataProvider";
import AppLayout from "../layouts/dashboard/AppLayout";
import AppRouter from "../routes/AppRouter";
import { Backdrop, CircularProgress } from "@mui/material";
import server_route_names from "../routes/server_route_names";
import ActivateAccount from "../auth/ActivateAccount";

const AuthContext = createContext();
const AuthorizedContext = createContext();

export const useAuth = () => useContext(AuthContext);
export const usePrivileges = () => useContext(AuthorizedContext);

const AuthProvider = ({ children }) => {
    const authToken = store(namespace.token);
    const [token, setToken] = useState(authToken);
    const [isAuthenticated, setIsAuthenticated] = useState(authToken ? true : false);
    const [isVerified, setIsVerified] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const match = useMatch("/auth/*");
    const pathTrack = useMatch("/*");

    useEffect(() => {
        // pathTrack
        if (!match && token) {
            updateProfile();
        }
    }, [pathTrack]);

    useEffect(() => {
        if (token) {
            refresh();
        }
        else {
            unAuthenticated();
        }
    }, [token, setToken]);

    const unAuthenticated = () => {
        setUser(null);
        setIsAuthenticated(null);
        setToken(null);
        setIsLoading(false);
        store.clearAll();
        if (!match) {
            navigate(route_names.login);
        }
    };

    const updateProfile = async () => {
        await refreshUserData();
        return user;
    };

    const refreshUserData = async () => {
        var response = await fetchApi(server_route_names["account.verify"]);
        if (response && response.status === "success") {
            if (response.isAuthenticated) {
                // console.log("response?.token", response?.token);
                if (response?.token) {
                    store(namespace.token, response?.token);
                }
                setIsAuthenticated(true);
                setIsVerified(response.isVerified);
                setUser(response.user);
            }
            else {
                unAuthenticated();
            }
        }
        else{
            unAuthenticated();
        }
    };

    const refresh = async () => {
        setIsLoading(true);
        var response = await fetchApi(server_route_names["account.verify"]);
        if (response && response.status === "success") {
            if (response.isAuthenticated) {
                // console.log("response?.token", response?.token);
                if (response?.token) {
                    store(namespace.token, response?.token);
                }
                setIsAuthenticated(true);
                setIsVerified(response.isVerified);
                setUser(response.user);
            }
            else {
                unAuthenticated();
            }
        }
        else{
            unAuthenticated();
        }
        setIsLoading(false);
    };

    const changePassword = async (oldPassword, password, confirmPassword) => {
        // Make a call to the authentication
        var response = await fetchApi(server_route_names["account.password.update"], {
            type: "POST",
            body: {
                userId: user.userId,
                oldPassword,
                password,
                confirmPassword,
            },
        });
        return response;
    };

    const login = async (email, password) => {
        // Make a call to the authentication
        var response = await fetchApi(server_route_names["account.login"], {
            type: "POST",
            body: {
                email,
                password,
            },
        });
        if (response?.status === "success") {
            store(namespace.token, response?.token);
            setToken(response?.token);
            setUser(response?.user);
            setIsAuthenticated(true);
        }
        return response;
    };

    const activateAccount = async (otp) => {
        // Make a call to the authentication
        var response = await fetchApi(server_route_names["account.activate"], {
            type: "POST",
            body: {
                id: user.userId,
                otp: otp
            }
        });
        if (response?.status === "success") {
            await updateProfile();
        }
        return response;
    };

     const resendMyOTP = async () => {
        // Make a call to the authentication
        var response = await fetchApi(server_route_names["otp.resend"], {
            type: "POST",
            body: {
                email: user.email
            }
        });
        return response;
    };

    const logout = () => {
        // console.log("Good Bye");
        unAuthenticated();
    };

    const hasPermission = (permissions) => {
        if (user.role === "SUPER_ADMIN") {
            return true;
        }
        else if (user?.privileges) {
            if (typeof permissions === "string") {
                var index = user?.privileges.findIndex( x => x.scope === permissions && x.status === "GRANTED" );
                if (index > -1) {
                    return true;
                }
                else{
                    return false;
                }
            }
            else if(typeof permissions === "object"){
                let has = false;
                try {
                    for (let pIndex = 0; pIndex < permissions.length; pIndex++) {
                        const perm = permissions[pIndex];
                        var index = user?.privileges.findIndex( x => x.scope === perm && x.status === "GRANTED" );
                        if (index > -1) {
                            has = true;
                            break;
                        }
                    }
                } catch (error) {
                    
                }
                return has;
            }
            else if (typeof permissions === "boolean") {
                if (permissions === true) {
                    return true;
                }
                else{
                    return false;
                }
            }
        }
        return false;
    }

    if (loading) {
        return (
            <Backdrop open>
                <CircularProgress></CircularProgress>
            </Backdrop>
        );
    }

    if (!user) {
        return (
            <AuthContext.Provider
                value={{
                    user,
                    token,
                    isAuthenticated: false,
                    loading,
                    isVerified,
                    login,
                    refresh,
                    resendMyOTP
                }}
            >
                <AuthRoutes />
            </AuthContext.Provider>
        );
    }

    if (!isVerified) {
        return (
            <AuthContext.Provider
                value={{
                    user,
                    token,
                    isAuthenticated,
                    isVerified,
                    login,
                    refresh,
                    activateAccount,
                    updateProfile,
                    resendMyOTP
                }}
            >
                <ActivateAccount />
            </AuthContext.Provider>
        );
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                isAuthenticated,
                isVerified,
                loading,
                login,
                logout,
                refresh,
                changePassword,
                updateProfile
            }}
        >
            <AuthorizedContext.Provider value={{ user, hasPermission }}>
                <AppDataProvider>
                    <AppLayout>
                        <AppRouter />
                    </AppLayout>
                </AppDataProvider>
            </AuthorizedContext.Provider>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
