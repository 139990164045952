import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useFetch, useRequest, useUpdateRecord } from "../../providers/AppProvider";
import { Avatar, Button, ButtonGroup, Checkbox, Chip, FormControlLabel, IconButton, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import _enum from "../../namespace/enum";
import LegacyPage from "../../subcomponents/LegacyPage";
import TableLoadingData from "../../components/TableLoadingData";
import Layout from "../../components/Layout";
import LegacyCard from "../../subcomponents/LegacyCard";
import moment from "moment";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { EbayLink } from "../../functions/common";

export default function Suspects() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [pageNumber, setPageNumber] = useState(params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 1);
    const [pageSizeLimit, setPageSizeLimit] = useState(params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 50);
    const [pageLoading, setPageLoading] = useState(true);
    const [bulkSelection, setBulkSelection] = useState([]);
    

    if (pageSizeLimit) {
        params.set("limit", pageSizeLimit);
    }
    else {
        params.set("limit", 250);
    }
    if (!isNaN(pageNumber) && Number(pageNumber) > 0) {
        params.set("page", parseInt(pageNumber));
    }
    else {
        params.set("page", 1);
    }
    const combinedParams = new URLSearchParams(params.toString());
    let productsURL = `${server_route_names["repricer.suspects"]}`;
    if (combinedParams.toString()) {
        productsURL += `?${combinedParams.toString()}`;
    }
    const { data, error, fetching, reFetch } = useFetch(productsURL);
    const { data: recordUpdated, updateRecord, loading: recordUpdating } = useUpdateRecord(`${server_route_names["repricer.suspects"]}`);
    const { data: bulkUpdateRecordsData, loading: bulk_loading, sendRequest: bulkUpdateRecord} = useRequest();
    const [ suspectData, setSuspectData ] = useState({});

    useEffect(() => {
        document.title = "Suspects";
    }, []);

    useEffect(() => {
        console.log("Page refreshed");
        if (data) {
            setSuspectData(data);
        }
        setPageLoading(false);
    }, [data, data?.product, data?.suspects]);

    if (pageLoading) {
        return (
            <LegacyPage
                title="Suspects"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }

    if (error) {
        return (
            <LegacyPage
                title="Suspects"
            >
                {error}
            </LegacyPage>
        )
    }
    const product = suspectData?.product??{};
    const pagination = {
        count: suspectData?.suspects?.count ?? 0,
        limit: suspectData?.suspects?.limit ?? 0,
        next: suspectData?.suspects?.next ?? null,
        prev: suspectData?.suspects?.prev ?? null,
        page: suspectData?.suspects?.page ?? 0,
        pages: suspectData?.suspects?.pages ?? 0,
        total: suspectData?.suspects?.total ?? 0
    };
    const headerSlots = pagination?.total?<Stack>
        <ButtonGroup>
            <IconButton
                disabled={!pagination.prev || pageLoading}
                onClick={() => {
                    if (Number(pageNumber-1) > 0) {
                        setPageNumber(pageNumber-1);
                    }
                }}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                disabled={!pagination.next || pageLoading}
                onClick={() => {
                    if (Number(pageNumber-1) <= Number(pagination.pages)) {
                        setPageNumber(pageNumber+1);
                    }
                }}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
        </ButtonGroup>
    </Stack>:null;
    return (
        <LegacyPage
            title="Suspects"
            headerSlots={headerSlots}
        >
            <Layout>
                {
                    suspectData?.suspects?.items?.length > 0 &&
                    <Layout.Section>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <FormControlLabel
                                label="Select all suspects"
                                control={
                                    <Checkbox
                                        indeterminate={bulkSelection?.length > 0}
                                        onChange={(e, checked) => {
                                            if (checked) {
                                                let items = suspectData?.suspects?.items?.map(r => {
                                                    return r?._id
                                                });
                                                setBulkSelection(items);
                                            }
                                            else{
                                                setBulkSelection([]);
                                            }
                                        }}
                                        name="Select all suspects"
                                    />
                                }
                            />
                            {
                                bulkSelection?.length > 0 && <div>
                                    <Button
                                        size="small"
                                        disabled={bulk_loading}
                                        variant="contained"
                                        onClick={async () => {
                                            let a = await bulkUpdateRecord(
                                                `${server_route_names["repricer.suspects.bulk_update"]}`, 
                                                {
                                                    body: {
                                                        items: bulkSelection,
                                                        compete: true
                                                    },
                                                    type: "POST"
                                                }
                                            );
                                            setBulkSelection([]);
                                            reFetch();
                                        }}
                                    >
                                        Compete
                                    </Button>
                                </div>
                            }
                            {
                                bulkSelection?.length > 0 && <div>
                                    <Button
                                        size="small"
                                        disabled={bulk_loading}
                                        variant="contained"
                                        onClick={async () => {
                                            let a = await bulkUpdateRecord(
                                                `${server_route_names["repricer.suspects.bulk_update"]}`, 
                                                {
                                                    body: {
                                                        items: bulkSelection,
                                                        compete: false
                                                    },
                                                    type: "POST"
                                                }
                                            );
                                            setBulkSelection([]);
                                            reFetch();
                                        }}
                                    >
                                        Don't compete
                                    </Button>
                                </div>
                            }
                            {
                                bulkSelection?.length > 0 && <Typography>{bulkSelection?.length} suspects selected</Typography>
                            }
                        </Stack>
                    </Layout.Section>
                }
                <Layout.Section>
                {
                    suspectData?.suspects?.items?.map((suspect, index) => (
                        <LegacyCard key={`${suspect.title}_${index}`}>
                            <Table sx={{ minWidth: 650 }} aria-label={`Suspect table for ${suspect.title}`} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>My SKU</TableCell>
                                        <TableCell>Price + Ship</TableCell>
                                        <TableSeparator />
                                        <TableCell>Price + Ship</TableCell>
                                        <TableCell>Suspect</TableCell>
                                        <TableCell>Suspect Item ID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><Typography variant="caption">{product?.sku}</Typography></TableCell>
                                        <TableCell align="right">
                                            <Typography variant="subtitle2">
                                                {product?.price}
                                            </Typography>
                                        </TableCell>
                                        <TableSeparator />
                                        <TableCell align="left">
                                            <Typography variant="subtitle2">
                                                {suspect?.totalPrice}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1} alignItems="center" >
                                            <div>
                                                {suspect?.seller?.username}
                                            </div>
                                            <div>
                                                {`(${suspect?.seller?.feedbackScore})`}
                                            </div>
                                            <div>
                                                {`${suspect?.seller?.feedbackPercentage}%`}
                                            </div>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                variant="text"
                                                onClick={() => {
                                                    window.open(EbayLink(suspect?.legacyItemId),"_blank");
                                                }}
                                            >{suspect?.legacyItemId}</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{width: 320, minWidth: 320, maxWidth: 320}}>
                                            <Typography variant="caption">{product?.title}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Avatar sx={{ width: 96, height: 96, borderRadius: 0 }} className="image-contains" src={product?.images?.[0]} alt={`Image of ${product.title}`}></Avatar>
                                        </TableCell>
                                        <TableSeparator />
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" spacing={0}>
                                                <div>
                                                    <Checkbox
                                                        onChange={(e,checked) => {
                                                            let items = JSON.parse(JSON.stringify(bulkSelection));
                                                            if(checked){
                                                                items.push(suspect?._id);
                                                            }
                                                            else{
                                                                items = items?.filter(c => c !== suspect?._id);
                                                            }
                                                            setBulkSelection(items);
                                                        }}
                                                        checked={bulkSelection?.findIndex(x => x === suspect?._id) > -1}
                                                    ></Checkbox>
                                                </div>
                                                <Avatar sx={{ width: 96, height: 96, borderRadius: 0, }} className="image-contains" src={suspect?.image} alt={`Image of suspect ${suspect.title}`}></Avatar>
                                            </Stack>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Stack direction="column" spacing={1}>
                                                <Typography variant="body2">
                                                    <Link
                                                        underline="hover"
                                                        sx={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            window.open(EbayLink(suspect?.legacyItemId),"_blank");
                                                        }}
                                                    >{suspect?.title}</Link>                                                    
                                                </Typography>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Chip size="small" label={`Location: ${suspect?.itemLocation?.country}`}></Chip>
                                                    <Chip size="small" color="success" label={suspect.condition}></Chip>
                                                    <div>
                                                        MPN: {suspect.mpn}
                                                    </div>
                                                    {
                                                        suspect.ipn && <div>
                                                            Interchange Part Number: {suspect.ipn}
                                                        </div>
                                                    }
                                                    {
                                                        suspect.oem && <div>
                                                            OEM Number: {suspect.oem}
                                                        </div>
                                                    }
                                                </Stack>
                                                <Typography variant="caption">
                                                    Last scanned: {moment(suspect.updatedAt).fromNow()}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                        <TableSeparator />
                                        <TableCell colSpan={3}>
                                            <ButtonGroup size="small">
                                                <Button variant={suspect?.isCompetitor === true?"contained":"outlined"}
                                                    onClick={ async () => {
                                                        let items = JSON.parse(JSON.stringify(suspectData.suspects.items));
                                                        let d = JSON.parse(JSON.stringify(suspectData));
                                                        items[index]["isCompetitor"] = true;
                                                        d["suspects"]["items"] = items;
                                                        setSuspectData(d);
                                                        let urr = await updateRecord(suspect?._id, {
                                                            data: {
                                                                isCompetitor: true
                                                            }
                                                        });
                                                        if (urr?.status === "failed") {
                                                            let items = JSON.parse(JSON.stringify(suspectData.suspects.items));
                                                            let d = JSON.parse(JSON.stringify(suspectData));
                                                            items[index]["isCompetitor"] = true;
                                                            d["suspects"]["items"] = items;
                                                            setSuspectData(d);
                                                        }
                                                    }}
                                                >Compete</Button>
                                                <Button variant={suspect?.isCompetitor === false?"contained":"outlined"}
                                                    onClick={async() => {
                                                        let items = JSON.parse(JSON.stringify(suspectData.suspects.items));
                                                        let d = JSON.parse(JSON.stringify(suspectData));
                                                        items[index]["isCompetitor"] = false;
                                                        d["suspects"]["items"] = items;
                                                        setSuspectData(d);
                                                        let urr = await updateRecord(suspect?._id, {
                                                            data: {
                                                                isCompetitor: false
                                                            }
                                                        });
                                                        if (urr?.status === "failed") {
                                                            let items = JSON.parse(JSON.stringify(suspectData.suspects.items));
                                                            let d = JSON.parse(JSON.stringify(suspectData));
                                                            items[index]["isCompetitor"] = false;
                                                            d["suspects"]["items"] = items;
                                                            setSuspectData(d);
                                                        }
                                                    }}
                                                >Don't Compete</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </LegacyCard>
                    ))
                }
                </Layout.Section>
                {
                    !pageLoading &&
                    <Layout.Section>
                        <Stack direction="row">
                            {
                                pagination.total ?
                                <Stack width="100%">
                                    Total { pagination.total } suspects found
                                </Stack>:null
                            }
                            <div>
                                {headerSlots}
                            </div>
                        </Stack>
                    </Layout.Section>
                }
            </Layout>
        </LegacyPage>
    )
}

function TableSeparator({}){
    return <TableCell sx={{
        // width: 1,
        // minWidth: 1,
        // maxWidth: 1,
        // border: "1px solid",
        borderColor: theme => theme.palette.divider,
        borderTopColor: theme => theme.palette.background.default,
        borderBottomColor: theme => theme.palette.background.default,
        backgroundColor: theme => theme.palette.background.default,
    }}></TableCell>
}