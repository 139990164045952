import { useEffect, useState } from "react";
import server_route_names from "../routes/server_route_names";
import { useCreateRecord, useDeleteRecord, useFetch } from "../providers/AppProvider";
import { Button, Stack, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import _enum from "../namespace/enum";
import CsvUploader from "../components/CsvUploader";
import LegacyDialog from "../subcomponents/LegacyDialog";
import ExportCSV from "../subcomponents/ExportCSV";
import LegacyPage from "../subcomponents/LegacyPage";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FiltersJsonGetQuery from "../components/FiltersJsonGetQuery";
import { usePrivileges } from "../providers/AuthProvider";
import ProductTable from "../components/ProductTable";
import TableLoadingData from "../components/TableLoadingData";
import Layout from "../components/Layout";
import privileges from "../privileges";


export default function Products() {
    const [snackbar, setSnackbar] = useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pageSizeLimit = params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 10;
    const pageNumber = params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 0;
    const [paginationModel, setPaginationModel] = useState({
        page: pageNumber,
        pageSize: pageSizeLimit,
    });
    const [filtersQuery, setFiltersQuery] = useState(new URLSearchParams());
    const [pageLoading, setPageLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [importOpen, setImportOpen] = useState(false);
    const [exportCSVOpen, setExportCSVOpen] = useState(false);
    const [q, setQ] = useState(params.get('q') || "");
    if (String(q).trim() !== "") {
        params.set("q", String(q).trim());
        params.set("qmw", "sku,checkmateName,title");
    }
    else {
        params.delete("q", q);
    }
    if (paginationModel.pageSize) {
        params.set("limit", paginationModel.pageSize);
    }
    else {
        params.set("limit", 10);
    }
    if (paginationModel.page) {
        params.set("page", parseInt(paginationModel.page) + 1);
    }
    else {
        params.set("page", 1);
    }
    const combinedParams = new URLSearchParams(params.toString() + '&' + filtersQuery.toString());
    let productsURL = `${server_route_names["products.lookup"]}`;
    if (combinedParams.toString()) {
        productsURL += `?${combinedParams.toString()}`;
    }
    const { data: initialProducts, error: initialProductsError, fetching: initialProductLoading, reFetch } = useFetch(productsURL);
    const { data: recordDeleted, loading: recordDeleting, deleteRecord } = useDeleteRecord(server_route_names["products.save.search"]);
    const { data: shopifyProductData, createRecord: createShopifyProduct } = useCreateRecord(server_route_names["shopify.products.bulk_create"], 1);
    const { createRecord: allProductsImportToShopify } = useCreateRecord(server_route_names["shopify.products.all_import"], 1);

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    
    const navigate = useNavigate();

    const { hasPermission } = usePrivileges();

    useEffect(() => {
        document.title = "All inventory";
    }, []);

    useEffect(() => {
        let newFilters = JSON.parse(JSON.stringify(default_tabs[selectedTab].filters));
        let newQuery = FiltersJsonGetQuery(newFilters);
        setFiltersQuery(newQuery);
    }, []);

    useEffect(() => {
        if (recordDeleted) {
            setSelectedTab(0);
            applyFiltersByTab(0);
            // reFetchSaveSearches();
        }
    }, [recordDeleted]);

    useEffect(() => {
        if (initialProducts) {
            setPageLoading(false);
        }
        else if(initialProductsError){
            setPageLoading(false);
        }
    }, [initialProducts, initialProductsError]);

    const applyFiltersByTab = (value) => {
        let newFilters = JSON.parse(JSON.stringify(default_tabs[value].filters));
        let newQuery = FiltersJsonGetQuery(newFilters);
        setFiltersQuery(newQuery);
    };

    const pushProducts = async () => {
        let created = await createShopifyProduct(rowSelectionModel);
        setSnackbar({ children: 'Request received and will be executed shortly', severity: 'info' });
        setRowSelectionModel([]);
    }
    
    const handleCloseSnackbar = () => setSnackbar(null);

    if (pageLoading) {
        return (
            <LegacyPage
                title="All inventory"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }

    if (initialProductsError) {
        return (
            <LegacyPage
                title="All inventory"
            >
                {initialProductsError}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            title="All inventory"
            actions={hasPermission(privileges["products.manage"])?[
                {
                    title: "Import",
                    color: "primary",
                    onClick: () => {
                        setImportOpen(true);
                    }
                },
            ]:[]}
            headerSlots={
                <>
                    {
                        (hasPermission(privileges["products.manage"]) && rowSelectionModel.length > 0) && (
                            <Button
                                onClick={pushProducts}
                                color="secondary"
                                size="small"
                                variant="contained"
                            >
                                Push {rowSelectionModel.length} items to Shopify
                            </Button>
                        )
                    }
                    {
                        (hasPermission(privileges["products.manage"]) && rowSelectionModel.length > 0) && (
                            <Button
                                onClick={() => {
                                    allProductsImportToShopify();
                                    setRowSelectionModel([]);
                                }}
                                color="success"
                                size="small"
                                variant="contained"
                            >
                                Push all {initialProducts?.total}+ items to Shopify
                            </Button>
                        )
                    }
                </>
            }
            slots={
                <>
                    <Stack direction="row" spacing={2} justifyContent="start" alignItems="baseline">
                        <Stack spacing={1} direction="row" width="100%">
                            {
                                default_tabs.map((r, i) => (
                                    <Button
                                        size="small"
                                        key={r.label}
                                        onClick={() => {
                                            setSelectedTab(i);
                                            applyFiltersByTab(i);
                                            setRowSelectionModel([]);
                                        }}
                                        variant={selectedTab === i?"contained":"text"}
                                        // disabled={initialProductLoading}
                                    >{r.label}</Button>
                                ))
                            }
                        </Stack>
                        <Stack width="100%" justifyContent="end">
                            <TextField
                                margin="none"
                                label="Search by SKU, title or checkmate"
                                value={q}
                                onChange={(e) => {
                                    setQ(e.target.value);
                                    navigate(`?q=${e.target.value}`);
                                }}
                            />
                        </Stack>
                    </Stack>
                </>
            }
        >
            {
                !!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )
            }
            {
                exportCSVOpen && (
                    <ExportCSV
                        data={initialProducts?.items}
                        onClose={() => {
                            setExportCSVOpen(false);
                        }}
                    />
                )
            }
            <LegacyDialog
                open={importOpen}
                title="Upload CSV File"
                onClose={() => {
                    setImportOpen(false);
                }}
                secondaryAction={{
                    title: "Close",
                    onClick: () => setImportOpen(false)
                }}
            >
                <CsvUploader
                    fileType="products.create"
                    targetUrl={server_route_names["products.bulk_update_by_csv"]}
                    showButton
                    onUploadDone={() => {
                        setImportOpen(false);
                        reFetch();
                    }}
                />
            </LegacyDialog>
            <Layout>
                <Layout.Section>
                    <ProductTable
                        paginationModel={paginationModel}
                        initialProducts={initialProducts}
                        setPaginationModel={setPaginationModel}
                        loading={initialProductLoading || recordDeleting}
                        // onRowDoubleClick={(selection) => {
                        //     navigate(route_names["products.edit"].replace(":id", selection.row._id));
                        // }}
                        checkboxSelection={hasPermission(privileges["products.manage"])}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        paginationMode="server"
                        isEditAllowed={hasPermission(privileges["products.manage"])}
                    />
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

const default_tabs = [
    {
        label: "All",
        filters: [],
        custom: false
    },
    {
        label: "Out of stock",
        filters: [
            {
                "column": "status",
                "condition": "eq",
                "value": "PUBLISHED",
                "filter": {
                    "label": "Status",
                    "value": "status",
                    "valueType": "select",
                    "valueOptions": [
                        {
                            "label": "Published",
                            "value": "PUBLISHED"
                        },
                        {
                            "label": "Unpublished",
                            "value": "UNPUBLISHED"
                        },
                        {
                            "label": "Draft",
                            "value": "DRAFT"
                        }
                    ],
                    "conditions": [
                        {
                            "label": "Equal to",
                            "value": "eq"
                        }
                    ]
                }
            },
            {
                "column": "inventoryQuantity",
                "condition": "lt",
                "value": "1",
                "filter": {
                    "label": "Inventory quantity",
                    "value": "inventoryQuantity",
                    "valueType": "number",
                    "conditions": [
                        {
                            "label": "Equal to",
                            "value": "eq"
                        },
                        {
                            "label": "Greater than",
                            "value": "gt"
                        },
                        {
                            "label": "Less than",
                            "value": "lt"
                        },
                        {
                            "label": "Range",
                            "value": "range"
                        }
                    ]
                }
            }
        ],
        custom: true
    }
];