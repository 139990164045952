import { useEffect, useState } from "react";
import server_route_names from "../routes/server_route_names";
import { useCreateRecord } from "../providers/AppProvider";
import { Button, Stack, TextField, Select, MenuItem, Popover, FormControl, InputLabel, Box, Chip, FormLabel, RadioGroup, FormControlLabel, Radio, Alert, Paper, Container, TableContainer, ButtonGroup, Grid, IconButton, Divider, Checkbox, Tooltip, Card } from "@mui/material";
import { renderConditionView, visible_columns_initial } from "../functions/filters";
import FiltersJson from "../components/FiltersJson";
import FiltersJsonCompare from "../components/FiltersJsonCompare";
import FiltersJsonGetQuery from "../components/FiltersJsonGetQuery";
import LegacyDialog from "../subcomponents/LegacyDialog";
import Layout from "./Layout";
import FilterAlt from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import CompareIcon from '@mui/icons-material/Compare';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoIcon from '@mui/icons-material/Info';

export default function FilterProducts({
    onFilter = () => {},
    onClearFilters = () => {},
    preAppliedFilters = [],
    onFilterSave = () => {},
    visibleColumns = visible_columns_initial(),
    onToggleAdvancedFilters = () => {},
    onSearchQueryChange = () => {}
 }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filtersJsonData, setFiltersJsonData] = useState(FiltersJson);
    const [filterColumn, setFilterColumn] = useState(filtersJsonData?.[0].value);
    const [filterCondition, setFilterCondition] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState(preAppliedFilters||[]);
    const [minRangeValue, setMinRangeValue] = useState("");
    const [maxRangeValue, setMaxRangeValue] = useState("");
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const [compareList, setCompareList] = useState({
        compare: null,
        with: null,
        condition: null
    });
    const [savePrompt, setSavePrompt] = useState(false);
    const [reportTitle, setReportTitle] = useState("");
    const [reportDescription, setReportDescription] = useState("");
    const [reportShowOnDashboard, setReportShowOnDashboard] = useState(true);
    const [frequency, setFrequency] = useState("DAILY");
    const [advancedFilters, setAdvancedFilters] = useState(false);
    const { loading, createRecord } = useCreateRecord(server_route_names["products.save.search"]);
    const { error: errorSaveAsReport, loading: createAsReportLoading, createRecord: createAsReport } = useCreateRecord(server_route_names["reporting"]);

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const setFilterRangeValue = (index, value) => {
        if (index === 0) {
            setMinRangeValue(value);
        }
        else if (index === 1) {
            setMaxRangeValue(value);
        }
    };

    const handleApplyFilter = () => {
        // Validate and apply the filter
        let value = filterValue;
        let condition = filterCondition;
        let column = filterColumn;
        if (filtersJsonData[selectedFilter]?.valueType === "comparison") {
            condition = `$expr_$${compareList.condition}`;
            column = compareList.compare;
            value = compareList.with;
        }
        else if (filterCondition === "range") {
            value = {
                min: minRangeValue,
                max: maxRangeValue
            }
        }
        let filter = {
            column: column,
            condition: condition,
            value: value,
            filter: filtersJsonData[selectedFilter]
        };
        let newFilters = JSON.parse(JSON.stringify(appliedFilters));
        let indx = newFilters.findIndex(i => i.column === filter.column);
        if (indx > -1) {
            newFilters[indx] = filter;
        }
        else {
            newFilters.push(filter);
        }
        setAppliedFilters(newFilters);
        let newQuery = FiltersJsonGetQuery(newFilters);
        if (typeof onFilter === "function") {
            onFilter(newFilters, newQuery);
        }
        handleClosePopover();
        setSelectedFilter(0);
        setFilterColumn(filtersJsonData[0].value);
        setFilterCondition("");
        setFilterValue("");
    };

    const applyFilterValidation = () => {
        if (filtersJsonData[selectedFilter]?.valueType === "comparison") {
            if (!compareList.compare || !compareList.with) {
                return true;
            }
        }
        else if(!filterColumn || !filterCondition){
            return true;
        }
        else {
            if (filterCondition === "range") {
                if (!minRangeValue || !maxRangeValue) {
                    return true;
                }
            }
            else{
                if (!filterValue) {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        if(preAppliedFilters){
            setAppliedFilters(preAppliedFilters);
        }
    }, [preAppliedFilters]);

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={8} lg={8} xl={8}>
                    <Stack spacing={2} direction="row">
                        <Button
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setAdvancedFilters(true);
                                setFiltersJsonData(FiltersJson);
                                setFilterCondition("");
                            }}
                            startIcon={<FilterAltOutlinedIcon />}
                            size="small"
                            variant="text"
                        >
                            Filters
                        </Button>
                        <Button
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setSelectedFilter(0);
                                setAdvancedFilters(true);
                                setFiltersJsonData(FiltersJsonCompare);
                                setFilterColumn(FiltersJsonCompare[0].value);
                                setFilterCondition("expr");
                                setFilterValue("");
                                setCompareList({
                                    compare: null,
                                    with: null,
                                    condition: null
                                });
                            }}
                            size="small"
                            variant="text"
                            startIcon={<CompareArrowsIcon/>}
                        >
                            Repricer filters
                        </Button>
                        <Button
                            onClick={(event) => {
                                setShowAdvancedFilters(!showAdvancedFilters);
                                onToggleAdvancedFilters(!showAdvancedFilters);
                            }}
                            size="small"
                            variant="text"
                            startIcon={!showAdvancedFilters?<FilterAltOutlinedIcon />:<FilterAltOffOutlinedIcon />}
                        >
                            Advanced filters
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={4} xl={4}>
                    <TextField
                        component={Paper}
                        fullWidth
                        size="small"
                        margin="none"
                        label="Search by SKU, title or checkmate"
                        onChange={(e) => {
                            onSearchQueryChange(e.target.value);
                        }}
                    />
                </Grid>
                {
                    appliedFilters && appliedFilters.length > 0 && 
                    <Grid item xs={12} md={9} lg={9} xl={10}>
                        <Stack py={2} px={1} direction="row" spacing={1} overflow="hidden" sx={{overflowX: "auto"}}>
                            {
                                appliedFilters.map(
                                    (filter, index) => (
                                        <Chip
                                            color="primary"
                                            // variant="outlined"
                                            component={Paper}
                                            sx={{mb: 2}}
                                            key={filter.column}
                                            label={chipLabel(filter)}
                                            onDelete={() => {
                                                let a = JSON.parse(JSON.stringify(appliedFilters));
                                                a.splice(index, 1);
                                                setAppliedFilters(a);
                                                let newQuery = FiltersJsonGetQuery(a);
                                                if (typeof onFilter === "function") {
                                                    onFilter(a, newQuery);
                                                }
                                            }}
                                        />
                                    )
                                )
                            }
                        </Stack>
                    </Grid>
                }
                {
                    (appliedFilters && appliedFilters.length > 0) &&
                    <Grid item xs={12} md={3} lg={3} xl={2}>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            <Button
                                onClick={async () => {
                                    setSavePrompt(true);
                                }}
                                size="small"
                                variant="contained"
                                color="info"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setAppliedFilters([]);
                                    if (typeof onClearFilters === "function") {
                                        onClearFilters();
                                    }
                                }}
                                size="small"
                                variant="contained"
                                color="inherit"
                            >
                                Clear all filters
                            </Button>
                        </Stack>
                    </Grid>
                }
            </Grid>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: "left"
                }}
            >
                <TableContainer sx={{p:2, minWidth: 320}}>
                    <Stack spacing={2}>
                        {
                            filterCondition !== "expr" &&
                            <FormControl fullWidth size="small">
                                <InputLabel>Select</InputLabel>
                                <Select
                                    label="Select"
                                    value={filtersJsonData[selectedFilter].value}
                                    onChange={(e) => {
                                        let v = e.target.value;
                                        let findIndex = filtersJsonData.findIndex(i => i.value === v);
                                        if (findIndex > -1) {
                                            setSelectedFilter(findIndex);
                                            setFilterColumn(v);
                                            setFilterValue("");
                                            if (filtersJsonData[findIndex].valueType === "comparison") {
                                                setFilterCondition("expr");
                                            }
                                            else{
                                                setFilterCondition("");
                                            }
                                        }
                                    }}
                                >
                                    {
                                        filtersJsonData.map(
                                            (r, i) => (
                                                <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                            )
                                        )
                                    }
                                </Select>
                            </FormControl>
                        }
                        {
                            filtersJsonData[selectedFilter].valueType === "comparison"? null
                            :<FormControl fullWidth size="small">
                                <InputLabel>Condition</InputLabel>
                                <Select
                                    label="Condition"
                                    value={filterCondition}
                                    onChange={(e) => setFilterCondition(e.target.value)}
                                >
                                    {
                                        filtersJsonData[selectedFilter]?.conditions.map(
                                            (r, i) => (
                                                <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                            )
                                        )
                                    }
                                </Select>
                            </FormControl>
                        }
                        {
                            filterCondition === "expr" ?
                                <Stack spacing={1}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Compare</InputLabel>
                                        <Select
                                            label="Compare"
                                            value={compareList.compare}
                                            onChange={(e) => {
                                                let cx = JSON.parse(JSON.stringify(compareList));
                                                cx.compare = e.target.value;
                                                cx.with = null;
                                                setCompareList(cx);
                                            }}
                                        >
                                            {
                                                filtersJsonData[selectedFilter].value1Options.map(r => (
                                                    <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Condition</InputLabel>
                                        <Select
                                            label="Condition"
                                            value={compareList.condition}
                                            onChange={(e) => {
                                                let cx = JSON.parse(JSON.stringify(compareList));
                                                cx.condition = e.target.value;
                                                setCompareList(cx);
                                            }}
                                        >
                                            {
                                                filtersJsonData[selectedFilter].conditions.map(r => (
                                                    <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>With</InputLabel>
                                        <Select
                                            label="With"
                                            value={compareList.with}
                                            onChange={(e) => {
                                                let cx = JSON.parse(JSON.stringify(compareList));
                                                cx.with = e.target.value;
                                                setCompareList(cx);
                                            }}
                                        >
                                            {
                                                filtersJsonData[selectedFilter]?.value2Options?.filter(x => x.value !== compareList.compare).map(r => (
                                                    <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                            :filterCondition === "range" ? 
                                <Stack spacing={2}>
                                    <TextField
                                        margin="none"
                                        type="number"
                                        fullWidth
                                        label="Min value"
                                        value={minRangeValue}
                                        onChange={(e) => setFilterRangeValue(0, e.target.value)}
                                    />
                                    <TextField
                                        margin="none"
                                        type="number"
                                        fullWidth
                                        label="Max Value"
                                        value={maxRangeValue}
                                        onChange={(e) => setFilterRangeValue(1, e.target.value)}
                                    />
                                </Stack>
                            :filtersJsonData[selectedFilter].valueType === "select"?
                                <FormControl fullWidth size="small">
                                    <InputLabel>Filter value</InputLabel>
                                    <Select
                                        label="Filter value"
                                        value={filterValue}
                                        onChange={(e) => setFilterValue(e.target.value)}
                                    >
                                        {
                                            filtersJsonData[selectedFilter].valueOptions.map(r => (
                                                <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            :<TextField
                                margin="none"
                                type={filtersJsonData[selectedFilter].valueType || "text"}
                                fullWidth
                                label="Filter value"
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)}
                            />
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleApplyFilter}
                            disabled={applyFilterValidation()}
                        >
                            Apply Filter
                        </Button>
                    </Stack>
                </TableContainer>
            </Popover>
            <LegacyDialog
                open={savePrompt}
                onClose={() => setSavePrompt(!savePrompt)}
                onCancel={() => setSavePrompt(!savePrompt)}
                title="Save this search"
                primaryAction={{
                    title: "Save as report",
                    onClick: async () => {
                        let fields = [];
                        try {
                            for (const key in visibleColumns) {
                                if (String(key).toLowerCase() === "imageurl") continue;
                                if (Object.hasOwnProperty.call(visibleColumns, key)) {
                                    const value = visibleColumns[key];
                                    if (value) {
                                        fields.push(key);
                                    }

                                }
                            }
                        } catch (error) {

                        }
                        let saved = await createAsReport({
                            data: {
                                filters: appliedFilters,
                                title: reportTitle,
                                description: reportDescription,
                                showOnDashboard: reportShowOnDashboard,
                                frequency: frequency,
                                type: "FILTERS",
                                fields: fields.toString()
                            }
                        });
                        if (saved?.status === "success") {
                            if(typeof onFilterSave === "function"){
                                onFilterSave();
                            }
                            setSavePrompt(!savePrompt);
                            setReportDescription("");
                            setReportShowOnDashboard(false);
                            setReportTitle("");
                            setFrequency("DAILY");
                        }
                    },
                    // variant: "contained",
                    disabled: createAsReportLoading || !reportTitle || reportTitle.trim() === "" || !reportDescription || reportDescription.trim() === "" 
                }}
            >
                <Layout>
                    <Layout.Section>
                        <Stack direction="column" spacing={2} width="100%">
                            {
                                errorSaveAsReport && (
                                    <Alert severity="error">{errorSaveAsReport}</Alert>
                                )
                            }
                            <TextField
                                fullWidth
                                label="Title"
                                required
                                value={reportTitle}
                                onChange={(e) => {
                                    setReportTitle(e.target.value);
                                }}
                                sx={{
                                    minWidth: 230
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Description"
                                required
                                value={reportDescription}
                                multiline
                                rows={2}
                                onChange={(e) => {
                                    setReportDescription(e.target.value);
                                }}
                                sx={{
                                    minWidth: 230
                                }}
                            />
                            <FormControl>
                                <FormControlLabel
                                    label="Show this report's summary on dashboard"
                                    control={
                                        <Checkbox
                                            onChange={(e,checked) => {
                                                setReportShowOnDashboard(checked);
                                            }}
                                            checked={reportShowOnDashboard}
                                        />
                                    }
                                />
                            </FormControl>
                            <Divider />
                            <FormControl>
                                <FormLabel id="frequency"> Report frequency settings</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="frequency"
                                    name="Report Frequency"
                                    value={frequency}
                                    onChange={(e) => {
                                        setFrequency(e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="DAILY" control={<Radio />} label="Daily" />
                                    <FormControlLabel value="WEEKLY" control={<Radio />} label="Weekly" />
                                    <FormControlLabel value="MONTHLY" control={<Radio />} label="Monthly" />
                                    <FormControlLabel value="YEARLY" control={<Radio />} label="Yearly" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </Layout.Section>
                </Layout>
            </LegacyDialog>
        </>
    )
}

function chipLabel(filter){
    let value = `${filter.filter.label} ${renderConditionView(filter.condition)}`;
    if (String(filter?.condition).indexOf("expr") > -1){
        let c = String(filter?.condition).split("_")?.[1];
        if(c === "$gt") c = "is greater than";
        else if(c === "$lt") c = "is less than";
        else if(c === "$eq") c = "is equal to";
        value = ` ${filter.column} ${c} ${filter.value}`;
    }
    else if (filter?.condition === "range") {
        value += ` ${filter.value.min} to ${filter.value.max}`;
    }
    else{
        value += ` ${filter.value}`;
    }
    return value;
}