import store from "store2";
import namespace from "../namespace";
import { host } from "../config";

export const fetchApi = async (url, options, storeId = 1) => {
    const response = await fetch(host+url, {
        // mode: "no-cors",
        method: options?.type??"GET", // *GET, POST, PUT, DELETE, etc.
        cache: options?.cache??"no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: options?.headers??{
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer "+ store(namespace.token),
            "X-Store-id": storeId
        },
        redirect: options?.redirect??"follow", // manual, *follow, error
        body: options?.body?JSON.stringify(options?.body):null, // body data type must match "Content-Type" header
        signal: options?.signal
    }).then((response) => response.json())
    .catch((error) => error);
    if(response?.code === "992"){
        console.log("Login expired");
        store.remove(namespace.token);
        redirectToLogin();
    }
    if(response?.code === "1004"){
        store.remove(namespace.token);
        redirectToLogin();
    }

    return response;
}

function redirectToLogin(){
    if (String(window.location.pathname).indexOf("auth/login") === -1) {
        return window.location.href = '/auth/login';
    }
    return null;
}