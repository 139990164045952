import Layout from '../../components/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { Skeleton, Stack, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "../../providers/AppProvider";
import server_route_names from "../../routes/server_route_names";
import LegacyCard from "../../subcomponents/LegacyCard";
import CollapsibleTable from '../../components/CollapsibleTable';
import DateRangeSelection from '../../components/DateRangeSelection';
import TableLoadingData from '../../components/TableLoadingData';
import LegacyPage from '../../subcomponents/LegacyPage';
import EmptyPage from '../../components/EmptyPage';
import { calculateDate } from '../../functions/dates';



export default function SalesVelocity() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let filtersQuery =  new URLSearchParams();
    const skuQuery = params.get("sku")??null;
    const startDateQuery = params.get("start_date")??null;
    const endDateQuery = params.get("end_date")??null;
    const [sku, setSku] = useState(skuQuery??"");
    const [start_date, setStartDate] = useState(startDateQuery??calculateDate(-30,"days"));
    const [end_date, setEndDate] = useState(endDateQuery??null);

    if (sku) {
        filtersQuery.set("sku", String(sku).trim());
    }
    else{
        filtersQuery.delete("sku");
    }
    if(start_date) {
        filtersQuery.set("start_date", start_date);
    }
    else{
        filtersQuery.delete("start_date");
    }

    if(end_date) {
        filtersQuery.set("end_date", end_date);
    }
    else{
        filtersQuery.delete("end_date");
    }

    const {data: reports, fetching:loading, error, reFetch: refresh } = useFetch(`${server_route_names["sales.velocity"]}?${filtersQuery.toString()}`);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Sales velocity";
    }, []);

    if (error) {
        return (
            <LegacyPage
                title="Sales velocity"
            >
                {error}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            slots={
                <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="row" justifyContent="start" width="100%">
                        <TextField
                            sx={{maxWidth: 360 }}
                            fullWidth
                            label="Search by sku"
                            value={sku}
                            type="text"
                            autoComplete="off"
                            onChange={(e) => {
                                setSku(e.target.value);
                                filtersQuery.set("sku", e.target.value);
                                navigate(`?${filtersQuery.toString()}`);
                            }}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="end">
                        <DateRangeSelection
                            onSelection={(startDate, endDate) => {
                                setStartDate(startDate??null);
                                setEndDate(endDate??null);
                            }}
                            startDate={start_date}
                            endDate={end_date}
                        >
                        </DateRangeSelection>
                    </Stack>
                </Stack>
            }
        >
            <Layout>
                {
                    loading && <Layout.Section>
                        <TableLoadingData />
                    </Layout.Section>
                }
                {
                    !loading && 
                    <Layout.Section>
                        {
                            reports?.length > 0 && 
                            <LegacyCard>
                                <CollapsibleTable data={reports??[]}/>
                            </LegacyCard>
                        }
                        {
                            !loading && reports?.length === 0 && (
                                <EmptyPage title={`No product found for "${sku}"`}/>
                            )
                        }
                        {
                            !loading && reports?.length > 0 && (
                                <Stack direction="row" spacing={3}>
                                    <Typography>
                                        {reports?.length} records found
                                    </Typography>
                                </Stack>
                            )
                        }
                    </Layout.Section>
                }
            </Layout>
        </LegacyPage>
    );
}