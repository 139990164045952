import {
    Typography,
    Stack,
    ListItemButton,
    ListItemText,
    List,
    Skeleton,
} from '@mui/material';
import { useFetch } from '../providers/AppProvider';
import server_route_names from '../routes/server_route_names';
import LegacyCard from '../subcomponents/LegacyCard';
import Layout from '../components/Layout';
import { capitalizeText } from '../functions/common';

export default function OutOfStockItemsWidget({
    startDate = null, endDate = null, title = null
}) {

    let query = `${server_route_names["dashboard.widget"]}?widget_type=inventory_status`;
    // if (startDate) {
    //     query += `&start_date=${startDate}`;
    // }
    // if (endDate) {
    //     query += `&end_date=${endDate}`;
    // }

    const { data: widget_data, error, fetching: widget_is_loading } = useFetch(query);

    if (error) {
        return <div>{error}</div>;
    }

    if (widget_is_loading) {
        return (
            <>
                <Layout.Section oneThird>
                    <LegacyCard sectioned>
                        <List disablePadding dense>
                            <ListItemButton dense disableGutters>
                                <ListItemText>
                                    <Skeleton />
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton dense disableGutters>
                                <ListItemText>
                                    <Skeleton />
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton dense disableGutters>
                            <ListItemText>
                                    <Skeleton />
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section oneThird></Layout.Section>
                <Layout.Section oneThird></Layout.Section>
            </>
        );
    }

    const marketplace_inventory = () => {
        // const sortedKeys = Object.keys(widget_data?.marketplaceInventory??{}).sort();
        const marketplaceInventory = widget_data?.marketplaceInventory;
        // sortedKeys.forEach(key => {
        //     marketplaceInventory[key] = widget_data?.marketplaceInventory[key];
        // });
        let html = [];
        for (const key in marketplaceInventory??{}) {
            let _html = [];
            if (String(key).toLowerCase() === "total") { continue; }
            else{
                if (marketplaceInventory.hasOwnProperty(key)) {
                    let item = marketplaceInventory?.[key];
                    for (const innerKey in item??{}) {
                        _html.push(
                            <CustomList key={`${key}-${innerKey}`} title={titleCase(innerKey)} secondaryInfo={item?.[innerKey]?.count}/>
                        )
                    }
                }
            }
            html.push(
                <Layout.Section key={key} oneThird>
                    <LegacyCard title={key}>
                        <LegacyCard.Section>
                            <List disablePadding dense>
                                {_html}
                            </List>
                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
            );
        }
        return html;
    };

    return (
        <>
            <Layout.Section oneThird>
                <LegacyCard title="Total inventory" sectioned>
                    <List disablePadding dense>
                        <CustomList title="In Stock" secondaryInfo={widget_data?.inventory?.IN_STOCK??0}/>
                        <CustomList title="Out of stock" secondaryInfo={widget_data?.inventory?.OUT_OF_STOCK??0} />
                        <CustomList title="Total" secondaryInfo={widget_data?.inventory?.TOTAL??0} />
                    </List>
                </LegacyCard>
            </Layout.Section>
            <Layout.Section oneThird></Layout.Section>
            <Layout.Section oneThird></Layout.Section>
            {/* {marketplace_inventory()} */}
        </>
    );
}


function CustomList({
    title = null,
    secondaryInfo = null,
    onClick = () => {}
}){
    return (
        <ListItemButton dense disableGutters
            onClick={onClick}
        >
            <ListItemText primary={capitalizeText(title)}/>
            {   secondaryInfo &&
                <Typography>{secondaryInfo}</Typography>
            }
        </ListItemButton>
    )
}

function titleCase(string){
    string = String(string).replace(/[\-_]/g, ' ').toLowerCase();
    return string;
}