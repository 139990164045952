import { Avatar, Chip, IconButton, Link, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAvatar from "./CustomAvatar";
import moment from "moment";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { FaEbay, FaShopify } from "react-icons/fa6";
import AlertDialog from "./AlertDialog";
import { useDeleteRecord, useCustomRequest } from "../providers/AppProvider";
import server_route_names from "../routes/server_route_names";
import CustomCard from "./CustomCard";
import Layout from "./Layout";
import ListInfo from "./ListInfo";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Toast from "./Toast";
import { usePrivileges } from "../providers/AuthProvider";
import privileges from "../privileges";
import LegacyCard from "../subcomponents/LegacyCard";

const StoreDetails = ({selectedTab, shopify_stores, ebay_stores, onDisconnect, onRefresh }) => {
    let data = [];
    if (ebay_stores && typeof ebay_stores === "object" && ebay_stores.length > 0) {
        data = data.concat(ebay_stores);
    }
    if (shopify_stores && typeof shopify_stores === "object" && shopify_stores.length > 0) {
        data = data.concat(shopify_stores);
    }
    const [store, setStore] = useState(data[selectedTab]);
    const [wantDisconnect, setWantDisconnect] = useState(false);
    const { data: disconnectData, loading: disconnecting, deleteRecord } = useDeleteRecord(server_route_names["ebay.stores"]);
    const { send, sending } = useCustomRequest();
    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
    const openActions = Boolean(actionsAnchorEl);
    
    const { hasPermission } = usePrivileges();

    
    const [resyncData, setResyncData] = useState(null);

    useEffect(() => {
        setStore(data[selectedTab]);
    }, [selectedTab, data]);

    useEffect(() => {
        if (disconnectData) {
            if (typeof onDisconnect === "function") {
                onDisconnect(store);
            }
        }
    }, [disconnectData, store])

    const profile = user_profile(store?.profile)??{};

    let actionButtons = [
        {
            label: "Refresh",
            onClick: () => {
                if (typeof onRefresh === "function") {
                    onRefresh();
                }
            }
        }
    ];
    if (store.type === "EBAY") {
        if (store.status === "CONNECTED") {
            if (hasPermission(privileges["store.ebay.manage"])) {
                actionButtons.push({
                    label: "Re-sync store",
                    onClick: () => {
                        reSyncData();
                    }
                });
                actionButtons.push({
                    label: "Bulk migrate listings",
                    onClick: () => {
                        bulkMigrateListing();
                    }
                });
                actionButtons.push({
                    label: "Disconnect",
                    onClick: () => {
                        setWantDisconnect(true);
                    }
                });
            }
        }
    }

    if (store.type === "SHOPIFY") {
        if (store.status === "CONNECTED") {
            if (hasPermission(privileges["store.shopify.manage"])) {
                actionButtons.push({
                    label: "Re-sync store",
                    onClick: () => {
                        reSyncData();
                    }
                });
            }
        }
    }

    const reSyncData = async () => {
        setResyncData(null);
        var url = server_route_names["shopify.store.resync"];
        if (store.type === "EBAY") {
            url = server_route_names["ebay.store.resync"];
        }
        var response = await send(url, null, store.id);
        setResyncData(response);
    }

    const bulkMigrateListing = async () => {
        setResyncData(null);
        const url = server_route_names["ebay.store.bulk_migrate_listing"];
        var response = await send(url, null, store.id);
        setResyncData(response);
    }

    return (
        <div>
            {
                wantDisconnect && (
                    <AlertDialog
                        title={`Disconnect ${store.name}?`}
                        content="By disassociating the eBay store, all existing access to its contents will be revoked. Consequently, the synchronization of products, orders, and related elements will no longer be feasible. Are you certain you wish to proceed with this action?"
                        primaryAction={{
                            title: disconnecting?"Processing...":"Disconnect",
                            color: "error",
                            onClick: async () => {
                                var deleted = await deleteRecord(store._id);
                                setWantDisconnect(false);
                            },
                            disabled: disconnecting
                        }}
                        secondaryAction={{
                            title: "No",
                            onClick: () => {
                                setWantDisconnect(false);
                            },
                            disabled: disconnecting
                        }}
                        onClose={() => {
                            setWantDisconnect(false);
                        }}
                    />
                )
            }
            {
                resyncData && <Toast open={true} type={resyncData.status === "success"?"success":"error"} onClose={() => { setResyncData(null); }} message={resyncData.message}></Toast>
            }
            <Layout spacing={2} alignItems="start">
                <Layout.Item twoThird>
                    <LegacyCard>
                        <LegacyCard.Section>
                            <Stack direction="row" spacing={3} alignItems="center">
                                <Avatar>
                                    {
                                        store.type === "EBAY" && (
                                            <CustomAvatar name={store.name} icon={<FaEbay size="32" />}/>
                                        )
                                    }
                                    {
                                        store.type === "SHOPIFY" && (
                                            <CustomAvatar name={store.name} icon={<FaShopify />}/>
                                        )
                                    }
                                </Avatar>
                                <Stack alignItems="start" width="100%">
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        <Link
                                            sx={{cursor: "pointer"}}
                                            underline="none"
                                            color="text.primary"
                                        >
                                            {
                                                profile?.name ?? store?.name
                                            }
                                        </Link>
                                        
                                    </Typography>
                                    <Typography variant="caption">
                                        {
                                            store.type === "EBAY" && (
                                                <div>
                                                    {
                                                        profile.doingBusinessAs ?? store.name
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            store.type === "SHOPIFY" && (
                                                <Link
                                                    onClick={() => {
                                                        window.open(`https://${store.domain}.myshopify.com`, '_blank');
                                                    }}
                                                    sx={{cursor: "pointer"}}
                                                    color="inherit"
                                                    underline="hover"
                                                >
                                                    https://{store.domain}.myshopify.com
                                                </Link>
                                            )
                                        }
                                    </Typography>
                                </Stack>
                                <Stack alignItems="end" width="100%">
                                    <IconButton
                                        id="basic-button"
                                        aria-controls={openActions ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openActions ? 'true' : undefined}
                                        onClick={(event) => {
                                            setActionsAnchorEl(event.currentTarget);
                                        }}
                                    >
                                        <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={actionsAnchorEl}
                                        open={openActions}
                                        onClose={() => {
                                            setActionsAnchorEl(null);
                                        }}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {
                                            actionButtons.map((r,i) => (
                                                <MenuItem
                                                    key={`m${r.label}-${i}`}
                                                    onClick={r.onClick}
                                                    disabled={sending}
                                                >
                                                {r.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </Stack>
                            </Stack>
                        </LegacyCard.Section>
                        <Stack>
                        {
                            store.type === "EBAY" && (
                                <LegacyCard.Section title="USER PROFILE" sectioned>
                                    <ListInfo>
                                        {/* <ListInfo.Classic title="Email address" value={profile.email} /> */}
                                        <ListInfo.Classic title="Account type" value={store?.profile?.accountType} />
                                        <ListInfo.Classic title="Registration marketplace" value={store?.profile?.registrationMarketplaceId?.replace(/\_/g, ' ')} />
                                    </ListInfo>
                                </LegacyCard.Section>
                            )
                        }
                        {
                            store.type === "SHOPIFY" && (
                                <LegacyCard.Section title="USER PROFILE" sectioned>
                                    <ListInfo>
                                        <ListInfo.Classic title="Store name" value={`${profile.name}`} />
                                        <ListInfo.Classic title="Email address" value={profile.email} />
                                        <ListInfo.Classic title="Currency" value={store?.profile?.currency} />
                                        <ListInfo.Classic title="Plan name" value={store?.profile?.plan_display_name} />
                                        {
                                            store?.profile?.password_enabled && (
                                                <ListInfo.Classic title="Password protected" value="Yes" />
                                            )
                                        }
                                    </ListInfo>
                                </LegacyCard.Section>
                            )
                        }
                        </Stack>
                    </LegacyCard>
                </Layout.Item>
                <Layout.Item oneThird>
                    <CustomCard title="Status">
                        <ListInfo>
                            <ListInfo.Item title="Status" value={<Chip color={store.status === "CONNECTED"?"success":"error"} label={store.status} size="small"></Chip>} />
                            <ListInfo.Item title="Created" value={moment(store.createdAt).fromNow()} />
                            {
                                store?.updatedAt && (
                                    <ListInfo.Item title="Last activity" value={moment(store?.updatedAt).fromNow()} />
                                )
                            }
                        </ListInfo>
                    </CustomCard>
                </Layout.Item>
            </Layout>

            <Layout sx={{mt: 2}}>
                <Layout.Item>
                     <LegacyCard title="Timeline">
                        <Timeline position="alternate-reverse">
                            {
                                JSON.parse(JSON.stringify(store.timelines)).reverse().map((t,i) => (
                                    <TimelineItem key={`timeline-item-${i}`}>
                                        <TimelineOppositeContent color="text.secondary">
                                            {moment(t.createdAt).calendar()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                        <TimelineDot />
                                        {
                                            store.timelines.length !== (i+1) && (<TimelineConnector />)
                                        }
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="body1" component="span">
                                                {t.content}
                                            </Typography>
                                            {
                                                (t.content && false) && (
                                                    <Typography>{t.content}</Typography>
                                                )
                                            }
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                        </Timeline>
                    </LegacyCard>
                </Layout.Item>
            </Layout>
        </div>
    )
}

export default StoreDetails;

function user_profile(profile) {
    if (profile?.accountType === "INDIVIDUAL") {
        return profile?.individualAccount;
    }
    else if(profile?.accountType === "BUSINESS"){
        return profile?.businessAccount;
    }
    if (!profile) {
        return {};
    }
    return profile
}


function CustomListItem({title, icon}) {
    return (
        <ListItem>
            {
                icon && (
                    <ListItemAvatar>
                        <Avatar>
                        {icon}
                        </Avatar>
                    </ListItemAvatar>
                )
            }
            <ListItemText primary={title}></ListItemText>
        </ListItem>
    )
}