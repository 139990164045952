import { Typography } from "@mui/material";
import Layout from "./Layout";

const ListInfo = ({children, spacing=1}) => {
    return (
        <Layout spacing={spacing}>
            {children}
        </Layout>
    )
}

ListInfo.Item = ({title, value, color}) => {
    return (
        <>
            <Layout.Item oneHalf>
                <Typography color={color??"text.secondary"}>
                    {title}
                </Typography>
            </Layout.Item>
            <Layout.Item oneHalf>{value}</Layout.Item>
        </>
    )
}

ListInfo.Classic = ({title, value, color}) => {
    return (
        <>
            <Layout.Item oneThird>
                <Typography color={color??"text.secondary"}>
                    {title}
                </Typography>
            </Layout.Item>
            <Layout.Item twoThird>{value}</Layout.Item>
        </>
    )
}

export default ListInfo;