import React, { useCallback, useState } from "react";
import server_route_names from "../routes/server_route_names";
import { useUpdateRecord } from "../providers/AppProvider";
import { Paper, Stack, TableContainer, TextField, Typography, Avatar, Button, Card } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import EmptyPage from "./EmptyPage";
import { findChangedFields } from "../functions/objects";
import LegacyDialog from "../subcomponents/LegacyDialog";
import { calculateSumOfQuantities, convertCamelCaseToWords } from "../functions/common";
import { visible_columns_initial } from "../functions/filters";
import { ebay_store_names } from "../config";
import moment from "moment";
import { StringToNumber } from "../functions/common";
import { getPrevious3Months, getPreviousMonths } from "../functions/dates";
import LoadProductHistory from "./LoadProductHistory";
import { common } from "@mui/material/colors";

const Previous3Months = getPrevious3Months();
const previousMonths = getPreviousMonths();

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .even`]: {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.background.default
	},
	'& .MuiDataGrid-cell': {
		borderRight: "1px solid",
		borderColor: theme.palette.divider,
	},
	'& .MuiDataGrid': {
		borderColor: theme.palette.divider,
	},
	'& .MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey.A200 : null
	},
	'& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
		backgroundColor: theme.palette.mode === "light" ? theme.palette.grey.A200 : null
	},
	'& .error': {
		backgroundColor: theme.palette.error[theme.palette.mode],
	},
	'& .success': {
		backgroundColor: theme.palette.success[theme.palette.mode],
	},
	'& .low-inventory': {
		backgroundColor: theme.palette.warning[theme.palette.mode],
	}
}));

const useDataMutation = () => {
	return useCallback((newRow, oldRow, updateRecord, comment) =>
		new Promise((resolve, reject) => {
			let changed = findChangedFields(newRow, oldRow);
			// if row was updated
			if (changed?.length > 0) {
				let data = {};
				for (let index = 0; index < changed.length; index++) {
					const changedData = changed[index];
					data[changedData.name] = changedData.newValue
				}
				updateRecord(newRow._id, {
					product: data,
					chx: changed,
					comment: comment
				})
					.then(response => {
						resolve(response);
					}).catch(error => {
						reject(error);
					})
			}
			else {
				resolve(null);
			}

		})
		, []);
}

function computeMutation(newRow, oldRow) {
	let changed = findChangedFields(newRow, oldRow);
	if (changed && changed.length > 0) {
		return `Edit product's ${changed.length} fields`;
	}
	return null;
}

export default function ProductTable({
	pageSizeOptions = [10, 25, 50, 100],
	initialProducts = {
		items: [],
		total: 0,
		count: 0,
		next: null,
		limit: 25
	},
	paginationModel = {
		page: 0,
		pageSize: 10,
	},
	setPaginationModel = () => { },
	loading = true,
	onRowDoubleClick = () => { },
	onRowSelectionModelChange = () => { },
	rowSelectionModel = [],
	checkboxSelection = false,
	columns = null,
	hideFooterPagination = false,
	hideFooterSelectedRowCount = false,
	hideFooter = false,
	showQuickFilter = false,
	paginationMode = "client", // client, server
	onSetVisibleColumns = () => { },
	isEditAllowed = false,
	emptyPageMessage = null
}) {
	const { data: updatedRecord, loading: loadingRecord, error: errorUpdateRecord, updateRecord } = useUpdateRecord(server_route_names.products);
	const [snackbar, setSnackbar] = useState(null);
	const [visibleColumns, setVisibleColumns] = useState(visible_columns_initial());
	const [promiseArguments, setPromiseArguments] = useState(null);
	const [comment, setComment] = useState(null);
	const mutateRow = useDataMutation();
	const [historyModel, setHistoryModel] = useState(null);
	const onHistoryClick = (item) => {
		setHistoryModel(item);
	}

	if (!columns) {
		columns = columns_json(onHistoryClick)
	}

	const processRowUpdate = useCallback(
		(newRow, oldRow) => {
			return new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					// Save the arguments to resolve or reject the promise later
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				}
				else {
					resolve(oldRow); // Nothing was changed
				}
			})
		},
		[],
	);

	const handleProcessRowUpdateError = useCallback((error) => {
		setSnackbar({ children: error.message, severity: 'error' });
	}, []);

	const handleCloseSnackbar = () => setSnackbar(null);

	const handleNo = () => {
		const { oldRow, resolve, reject, newRow } = promiseArguments;
		setPromiseArguments(null);
		resolve(oldRow); // Resolve with the old row to not update the internal state
	};

	const handleYes = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;
		try {
			// Make the HTTP request to save in the backend
			const response = await mutateRow(newRow, oldRow, updateRecord, comment);
			setSnackbar({ children: response?.message, severity: response?.status === 'success' ? 'success' : 'error' });
			if (response?.status === "success") {
				resolve(newRow);
				setComment(null);
			}
			else {
				resolve(oldRow);
			}
			setPromiseArguments(null);
		} catch (error) {
			reject(oldRow);
			setPromiseArguments(null);
		}
	};

	const handleEntered = () => {
		// The `autoFocus` is not used because, if used, the same Enter that saves
		// the cell triggers "No". Instead, we manually focus the "No" button once
		// the dialog is fully open.
		// noButtonRef.current?.focus();
	};

	const renderConfirmDialog = () => {
		if (!promiseArguments) {
			return null;
		}

		const { newRow, oldRow } = promiseArguments;
		const mutation = computeMutation(newRow, oldRow);
		const editedFields = findChangedFields(newRow, oldRow);

		return (
			<LegacyDialog
				size="md"
				open={!!promiseArguments}
				TransitionProps={{ onEntered: handleEntered }}
				title={mutation}
				primaryAction={{
					title: "Update",
					onClick: handleYes,
					disabled: (!comment || String(comment).trim() === "") || loadingRecord
				}}
				secondaryAction={{
					title: "Cancel",
					onClick: handleNo,
					disabled: loadingRecord
				}}
				hideCloseIcon
			>
				<Stack spacing={3}>
					<Typography mb={2} variant="body2">Review your edits before proceeding with the update.</Typography>
					<StripedDataGrid
						density="compact"
						columns={[
							{
								field: "name",
								headerName: "Field",
								sortable: false,
								editable: false,
								width: 240,
								renderCell: params => <Typography variant="button">{convertCamelCaseToWords(params.value)}</Typography>
							},
							{
								field: "oldValue",
								headerName: "Old value",
								sortable: false,
								editable: false,
								width: 180
							},
							{
								field: "newValue",
								headerName: "New Value",
								sortable: false,
								editable: false,
								width: 180
							}
						]}
						rows={editedFields}
						disableColumnFilter
						disableColumnMenu
						disableColumnSelector
						disableDensitySelector
						disableEval
						disableRowSelectionOnClick
						disableVirtualization
						hideFooter
						hideFooterPagination
						hideFooterSelectedRowCount
					/>
					<TextField
						required
						label="Comment (Required)"
						helperText
						multiline
						rows={2}
						value={comment}
						onChange={(e) => {
							setComment(e.target.value);
						}}
					/>
					<Typography variant="caption" color="text.secondary">Note: The admin will be notified about this change.</Typography>
				</Stack>
			</LegacyDialog>
		);
	};

	return (
		<>
			<LegacyDialog
				title={`History for "${historyModel?.sku}"`}
				open={historyModel?.sku}
				onCancel={() => {
					setHistoryModel(null);
				}}
				onClose={() => {
					setHistoryModel(null);
				}}
				size="lg"
			>
				<LoadProductHistory
					productId={historyModel?._id}
				></LoadProductHistory>
			</LegacyDialog>
			{renderConfirmDialog()}
			{
				!!snackbar && (
					<Snackbar
						open
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						onClose={handleCloseSnackbar}
						autoHideDuration={6000}
					>
						<Alert {...snackbar} onClose={handleCloseSnackbar} />
					</Snackbar>
				)
			}
			<TableContainer component={Paper}>
				<StripedDataGrid
					disableDensitySelector
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <EmptyPage title={emptyPageMessage} />,
						toolbar: GridToolbar
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: showQuickFilter,
							printOptions: {
								disableToolbarButton: true
							},
						}
					}}
					sx={{
						p: 0, pt: 0, height: initialProducts?.total > 0 ? "auto" : 400,
						'& .MuiDataGrid-cell:hover': {
							color: 'primary.main',
						},
						maxHeight: 700,
						overflow: "hidden"
						// maxHeight: 'calc(100% - 400px)'
					}}
					columns={columns}
					rows={DataGridRow(initialProducts)}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					pagination
					initialState={{
						pagination: {
							paginationModel: paginationModel
						}
					}}
					pageSizeOptions={pageSizeOptions}
					rowCount={initialProducts?.total ?? 0}
					paginationMode={paginationMode}
					editMode="row"
					isCellEditable={(params) => isEditAllowed === true}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					loading={loading}
					onColumnVisibilityModelChange={(columns, details) => {
						setVisibleColumns(columns);
						onSetVisibleColumns(columns, details);
					}}
					columnVisibilityModel={visibleColumns}
					disableRowSelectionOnClick
					onRowDoubleClick={onRowDoubleClick}
					onRowSelectionModelChange={onRowSelectionModelChange}
					rowSelectionModel={rowSelectionModel}
					checkboxSelection={checkboxSelection}
					getCellClassName={(params) => {
						if (params?.field === "image") {
							return 'bg-white';
						}
						else if (params?.field === "inventoryQuantity") {
							if (Number(params?.value) <= 0) {
								return 'error';
							}
							if (Number(params?.value) < 5) {
								return 'low-inventory';
							}
						}
						else if (params?.field === "netEbayProfitPercentage") {
							if (Number(params?.value) < 5) {
								return "error";
							}
							else if (Number(params.value) > 50) {
								return "success";
							}
						}
						else if (params?.field === "netEbayProfit") {
							if (!params?.value || Number(params.value) < 0) {
								return "error";
							}
						}
						return ``;
					}}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					hideFooterPagination={hideFooterPagination}
					hideFooter={hideFooter}
					hideFooterSelectedRowCount={hideFooterSelectedRowCount}
					disableColumnMenu
					showQuickFilter={showQuickFilter}
					disableColumnFilter
				>
				</StripedDataGrid>
			</TableContainer>
		</>
	)
}

const DataGridRow = (data) => {
	if (data?.items?.length > 0) {
		return data.items;
	}
	else {
		return [];
	}
}

function columns_json(onHistoryClick) {
	let _columns = [
		{
			"field": "image",
			"headerName": "Image",
			"description": "Image",
			"width": 110,
			"sortable": false,
			"filterable": false,
			"hideable": false,
			"printable": false,
			"renderCell": (params) => {
				let img = params?.row?.image ?? params?.row?.images?.[0] ?? null;
				if (img) {
					return <Avatar
						alt={`Image-${params?.row?.sku}`}
						src={img}
						sx={{ width: 84, height: 84, borderRadius: 0 }}
					/>;
				}
				return null;
			},
			"align": 'center',
			"headerAlign": 'center',
		},
		{
			"field": "sku",
			"headerName": "Product ID/SKU",
			"description": "SKU",
			"width": 180,
			"sortable": true,
			"editable": false,
		},
		{
			"field": "checkmateName",
			"headerName": "Checkmate Name",
			"description": "Checkmate name",
			"width": 140,
			"sortable": true,
			"editable": false,
		},
		{
			"field": "pairName",
			"headerName": "Pair Name",
			"description": "Pair name/Pair code",
			"width": 140,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "title",
			"headerName": "Title",
			"description": "Item title",
			"width": 300,
			"sortable": false,
			"editable": false,
			"renderCell": (params) => {
				return params?.value;
				// return params?.row?.marketplaceListings?.[0]?.title??null;
			},
		},
		{
			"field": "price",
			"headerName": "Our Price",
			"description": "Item price / Our price / Ebay price",
			"width": 130,
			"sortable": false,
			"editable": false,
			"type": "number",
			"valueFormatter": params => {
				if (!params?.value) {
					return `$ ${StringToNumber(params?.row?.marketplaceListings?.[0]?.price ?? 0)}`;
				}
				return `$ ${StringToNumber(params.value)}`;
			}
		},
		{
			"field": "inventoryQuantity",
			"headerName": "Inventory Quantity",
			"description": "In stock quantity",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return StringToNumber(params.value)
			}
		},
		{
			"field": "cost",
			"headerName": "Cost/Item",
			"description": "Cost per item",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "fbmCost",
			"headerName": "FBM Cost",
			"description": "FBM cost",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "ebayLowestPrice",
			"headerName": "Ebay Lowest Price",
			"description": "Ebay Lowest price",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "competitorsLowestPrice",
			"headerName": "Competitors Lowest Price",
			"description": "Competitors lowest price",
			"width": 130,
			"sortable": false,
			"editable": false,
			"type": "number",
			"valueFormatter": params => {
				if (!params?.value) {
					return `$ ${StringToNumber(params?.row?.competitorsLowestPrice, 2)}`;
				}
				return `$ ${StringToNumber(params.value)}`;
			}
		},
		{
			"field": "floorPrice",
			"headerName": "Floor Price",
			"description": "Floor price",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "ceilingPrice",
			"headerName": "Ceiling Price",
			"description": "Ceiling Price",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "soldQuantity",
			"headerName": "Sold Quantity",
			"description": "Sold quantity",
			"width": 130,
			"sortable": true,
			"type": "number",
			"valueFormatter": params => {
				return StringToNumber(params.value)
			}
		},
		{
			"field": "upcomingQuantity",
			"headerName": "Upcoming Quantity",
			"description": "Upcoming quantity",
			"width": 130,
			"sortable": true,
			"editable": false,
			"type": "number",
			"valueFormatter": params => {
				return StringToNumber(params.value)
			}
		},
		{
			"field": "ebayFeeAmount",
			"headerName": "Ebay FEE Price",
			"description": "Ebay FEE Price; Formula = price x 0.11",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				return StringToNumber(params.value)
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "adRate",
			"headerName": "Ebay Ad Rate %",
			"description": "Ebay Ad Rate %",
			"width": 130,
			"sortable": false,
			// "valueGetter": (params) => `${params?.row?.adRate}%`,
			"editable": true,
			"valueFormatter": params => {
				return `${StringToNumber(params.value)} %`
			}
		},
		{
			"field": "adRateAmount",
			"headerName": "Ebay Ad Rate",
			"description": "Ebay Ad Rate",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				return StringToNumber(params.value)
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "profit",
			"headerName": "Ebay Profit",
			"description": "Ebay Profit",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				return StringToNumber(params.value);
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			},
		},
		{
			"field": "profitPercentage",
			"headerName": "Ebay Profit %",
			"description": "Ebay Profit %",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueFormatter": params => {
				return `${StringToNumber(params.value)} %`
			}
		},
		{
			"field": "ebayFeeWhileOnFloorPrice",
			"headerName": "Ebay Fee While On Floor Price",
			"description": "Ebay Fee While On Floor Price",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				return StringToNumber(params?.row?.floorPrice * params?.row?.ebayFee / 100)
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "ebayAdRateCostAtFloorPrice",
			"headerName": "Ebay Ad Rate Cost At Floor Price",
			"description": "Ebay Ad Rate Cost At Floor Price",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				return StringToNumber(params?.row?.floorPrice * params?.row?.adRate / 100)
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "netProfitOnFloorPrice",
			"headerName": "Profit On Floor Price",
			"description": "Profit On Floor Price",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				let price = Number(params?.row?.floorPrice ?? 0);
				let cost = Number(params?.row?.cost ?? 0);
				let fbmCost = Number(params?.row?.fbmCost ?? 0);
				let ebayFee = Number(params?.row?.ebayFloorFeeAmount ?? 0);
				let adrate = Number(params?.row?.ebayFloorAdRateAmount ?? 0);
				let profit = price - cost - fbmCost - ebayFee - adrate;
				return `${StringToNumber(profit)}`;
			},
			"valueFormatter": params => {
				return `$ ${StringToNumber(params.value)}`
			}
		},
		{
			"field": "netProfitOnFloorPricePercentage",
			"headerName": "Profit On Floor Price %",
			"description": "Profit On Floor Price %",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				let price = Number(params?.row?.floorPrice ?? 0);
				let cost = Number(params?.row?.cost ?? 0);
				let fbmCost = Number(params?.row?.fbmCost ?? 0);
				let ebayFee = Number(params?.row?.ebayFloorFeeAmount ?? 0);
				let adrate = Number(params?.row?.ebayFloorAdRateAmount ?? 0);
				let profit = price - cost - fbmCost - ebayFee - adrate;
				return `${StringToNumber(profit / cost * 100)}`;
			},
			"valueFormatter": params => {
				return `${StringToNumber(params.value)} %`
			}
		},
		{
			"field": "ebayRank",
			"headerName": "Ebay Ranking",
			"description": "Ebay ranking",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "number"
		},
		{
			"field": "customCategory",
			"headerName": "Product category",
			"description": "Product category",
			"width": 200,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "profitLossCategory",
			"headerName": "Profit/Loss Category",
			"description": "Profit/Loss Category",
			"width": 200,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "shippingService",
			"headerName": "Shipping Service",
			"description": "Shipping Service",
			"width": 200,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "carrierFee",
			"headerName": "Carrier Fee",
			"description": "Carrier Fee",
			"width": 100,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return params.value?`$ ${StringToNumber(params.value)}`:null;
			}
		},
		{
			"field": "carrierName",
			"headerName": "Carrier Name",
			"description": "Carrier Name",
			"width": 200,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "carrierPercentage",
			"headerName": "Carrier %",
			"description": "Carrier %",
			"width": 120,
			"sortable": true,
			"editable": true,
			"type": "number",
			"valueFormatter": params => {
				return params.value?`${StringToNumber(params.value)} %`:null;
			}
		},
		{
			"field": "isPair",
			"headerName": "Pair/Single",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "boolean",
			"renderCell": params => {
				return params.value?"Pair":"Single";
			}
		},
		{
			"field": "discontinued",
			"headerName": "Discontinued",
			"description": "Discontinued",
			"width": 130,
			"sortable": true,
			"editable": true,
			"type": "boolean",
			"renderCell": (params) => {
				return params.value?"Discontinued":null;
			}
		},
		{
			"field": "discontinuationDate",
			"headerName": "Discontinuation Date",
			"description": "Discontinuation Date",
			"width": 200,
			"sortable": true,
			"editable": true,
			"type": "date",
			"valueGetter": params => {
				return params.value?new Date(params.value):null;
			},
			"renderCell": params => {
				return params.value?moment(params.value).fromNow():null;
			}
		},
		{
			"field": "ebayResearchLinkByPart1",
			"headerName": "Ebay Research Link By Part 1",
			"description": "Ebay Research Link By Part 1",
			"width": 200,
			"sortable": true,
			"editable": true,
			"align": 'center',
			"headerAlign": 'left',
			"renderCell": (params) => {
				if (params?.value) {
					return <Button
						size="small"
						color="inherit"
						variant="contained"
						onClick={() => {
							window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
						}}
					>
						View
					</Button>
				}
				return params?.value;
			}
		},
		{
			"field": "ebayResearchLinkByPart2",
			"headerName": "Ebay Research Link By Part 2",
			"description": "Ebay Research Link By Part 2",
			"width": 200,
			"sortable": true,
			"editable": true,
			"align": 'center',
			"headerAlign": 'left',
			"renderCell": (params) => {
				if (params?.value) {
					return <Button
						size="small"
						color="inherit"
						variant="contained"
						onClick={() => {
							window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
						}}
					>
						View
					</Button>
				}
				return params?.value;
			}
		},
		{
			"field": "ebayResearchLinkByKeyword1",
			"headerName": "Ebay Research Link By Keyword 1",
			"description": "Ebay Research Link By Keyword 1",
			"width": 200,
			"sortable": true,
			"editable": true, "align": 'center',
			"headerAlign": 'left',
			"renderCell": (params) => {
				if (params?.value) {
					return <Button
						size="small"
						color="inherit"
						variant="contained"
						onClick={() => {
							window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
						}}
					>
						View
					</Button>
				}
				return params?.value;
			}
		},
		{
			"field": "ebayResearchLinkByKeyword2",
			"headerName": "Ebay Research Link By Keyword 2",
			"description": "Ebay Research Link By Keyword 2",
			"width": 200,
			"sortable": false,
			"editable": true,
			"align": 'center',
			"headerAlign": 'left',
			"renderCell": (params) => {
				if (params?.value) {
					return <Button
						size="small"
						color="inherit"
						variant="contained"
						onClick={() => {
							window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
						}}
					>
						View
					</Button>
				}
				return params?.value;
			}
		},
		{
			"field": "keyword1",
			"headerName": "Keyword 1",
			"description": "Keyword 1",
			"width": 200,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "competitorKeywords",
			"headerName": "Competitor Keywords",
			"description": "Competitor Keywords",
			"width": 260,
			"sortable": true,
			"editable": true,
		}, {
			"field": "competitorIds",
			"headerName": "Competitor Ids",
			"description": "Competitor Ids",
			"width": 260,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "soldoutDate",
			"headerName": "When Item Got Out Of Stock",
			"description": "When Item Got Out Of Stock",
			"width": 180,
			"sortable": false,
			"valueFormatter": params => {
				if (!params.value) return '';
				if (params.value) return moment(params.value).format('MM/DD/YYYY hh:mm a')
				return ''
			}
		},
		{
			"field": "weightOz",
			"headerName": "Weight OZ",
			"description": "Weight OZ",
			"width": 120,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "height",
			"headerName": "Height",
			"description": "Height",
			"width": 120,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "length",
			"headerName": "Length",
			"description": "Length",
			"width": 120,
			"sortable": true,
			"editable": true,
		},
		{
			"field": "vendor",
			"headerName": "Vendor",
			"description": "Vendor",
			"width": 120,
			"sortable": true,
			"editable": true,
		},
		{
			"field": Previous3Months[0].monthName,
			"headerName": `${Previous3Months[0].monthName} ${Previous3Months[0].year}`,
			"description": Previous3Months[0].monthName,
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				try {
					let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[0].monthNumber && x?.year === Previous3Months[0].year);
					if (ind > -1) {
						return StringToNumber(params?.row?.orders[ind]["quantity"]);
					}
				} catch (error) {

				}
				return 0;
			}
		},
		{
			"field": Previous3Months[1].monthName,
			"headerName": `${Previous3Months[1].monthName} ${Previous3Months[1].year}`,
			"description": Previous3Months[1].monthName,
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				try {
					let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[1].monthNumber && x?.year === Previous3Months[1].year);
					if (ind > -1) {
						return StringToNumber(params?.row?.orders[ind]["quantity"]);
					}
				} catch (error) {

				}
				return 0;
			}
		},
		{
			"field": Previous3Months[2].monthName,
			"headerName": `${Previous3Months[2].monthName} ${Previous3Months[2].year}`,
			"description": Previous3Months[2].monthName,
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				try {
					let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[2].monthNumber && x?.year === Previous3Months[2].year);
					if (ind > -1) {
						return StringToNumber(params?.row?.orders[ind]["quantity"]);
					}
				} catch (error) {

				}
				return 0;
			}
		},
		{
			"field": "Last60Days",
			"headerName": "Last 60 Days",
			"description": "Last 60 Days",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				let count = 0;
				try {
					let a1 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[0].monthNumber && x?.year === Previous3Months[0].year);
					let a2 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[1].monthNumber && x?.year === Previous3Months[1].year);
					let a3 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[2].monthNumber && x?.year === Previous3Months[2].year);
					if (a2 > -1) {
						count += Number(params?.row?.orders[a2]["quantity"]);
					}
					if (a3 > -1) {
						count += Number(params?.row?.orders[a3]["quantity"]);
					}
				} catch (error) {

				}
				return count;
			}
		},
		{
			"field": "Last90Days",
			"headerName": "Last 90 Days",
			"description": "Last 90 Days",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				return calculateSumOfQuantities(params?.row?.orders, 3);
			}
		},
		{
			"field": "Last180Days",
			"headerName": "Last 180 Days",
			"description": "Last 180 Days",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				let count = calculateSumOfQuantities(params?.row?.orders, 6);
				return count;
			}
		},
		{
			"field": "Thisyear",
			"headerName": "This Year",
			"description": "This Year",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				let count = 0;
				try {
					params?.row?.orders?.forEach(item => {
						if (item?.year === new Date().getFullYear()) {
							count += Number(item?.quantity);
						}
					});
				} catch (error) {

				}
				return count;
			}
		},
		{
			"field": "lastyear",
			"headerName": `Last Year ${new Date().getFullYear() - 1}`,
			"description": `Last Year ${new Date().getFullYear() - 1}`,
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				let count = 0;
				try {
					params?.row?.orders?.forEach(item => {
						if (item?.year === new Date().getFullYear() - 1) {
							count += Number(item?.quantity);
						}
					});
				} catch (error) {

				}
				return count;
			}
		},
		{
			"field": "totalSales",
			"headerName": "Total Sales",
			"description": "Total Sales",
			"width": 130,
			"sortable": false,
			"filterable": false,
			"valueGetter": (params) => {
				let count = 0;
				try {
					params?.row?.orders?.forEach(item => {
						count += Number(item?.quantity);
					});
				} catch (error) {

				}
				return count;
			}
		},
		{
			"field": "comment",
			"headerName": "Comment",
			"description": "Total Sales",
			"width": 300,
			"sortable": true,
			"editable": true,
		},
		{
			"field": `Listing_${ebay_store_names[1].username}`,
			"headerName": `Listing on ${ebay_store_names[1].name}`,
			"description": `Listing on ${ebay_store_names[1].name}`,
			"width": 380,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[1].username)?.[0];
				if (marketplace && marketplace?.listingId) {
					return `https://www.ebay.com/itm/${marketplace?.listingId}`;
				}
				return null
			},
			"renderCell": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[1].username)?.[0];
				if (marketplace) {
					return <Stack direction="row" alignItems="center" spacing={2}>
						{/* <CustomAvatar name={marketplace?.ebayStore?.name} icon={<FaEbay size="36" />}></CustomAvatar> */}
						{
							marketplace?.listingId &&
							<Button
								color="inherit"
								variant="contained"
								onClick={() => {
									window.open(`https://www.ebay.com/itm/${marketplace?.listingId}`, "_blank");
								}}
								size="small"
							>
								View
							</Button>
						}
						<Typography sx={{ minWidth: 60 }}>
							{`$ ${StringToNumber(marketplace?.price)}`}
						</Typography>
						<Typography sx={{ color: marketplace.listingStatus === "OUT_OF_STOCK" ? "red" : null }}>
							{/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
							{
								marketplace.listingStatus === "OUT_OF_STOCK" ? `Out of stock` : `(Available Qty: ${marketplace?.availableQuantity ?? 0})`
							}
						</Typography>
					</Stack>
				}
				return null;
			},
		},
		{
			"field": `Listing_${ebay_store_names[2].username}`,
			"headerName": `Listing on ${ebay_store_names[2].name}`,
			"description": `Listing on ${ebay_store_names[2].name}`,
			"width": 380,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[2].username)?.[0];
				if (marketplace && marketplace?.listingId) {
					return `https://www.ebay.com/itm/${marketplace?.listingId}`;
				}
				return null
			},
			"renderCell": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[2].username)?.[0];
				if (marketplace) {
					return <Stack direction="row" alignItems="center" spacing={2}>
						{/* <CustomAvatar name={marketplace?.ebayStore?.name} icon={<FaEbay size="36" />}></CustomAvatar> */}
						{
							marketplace?.listingId &&
							<Button
								color="inherit"
								variant="contained"
								onClick={() => {
									window.open(`https://www.ebay.com/itm/${marketplace?.listingId}`, "_blank");
								}}
								size="small"
							>
								View
							</Button>
						}
						<Typography sx={{ minWidth: 60 }}>
							{`$ ${StringToNumber(marketplace?.price)}`}
						</Typography>
						<Typography sx={{ color: marketplace.listingStatus === "OUT_OF_STOCK" ? "red" : null }}>
							{/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
							{
								marketplace.listingStatus === "OUT_OF_STOCK" ? `Out of stock` : `(Available Qty: ${marketplace?.availableQuantity ?? 0})`
							}
						</Typography>

					</Stack>
				}
				return null;
			},
		},
		{
			"field": "Listing_ShopifyAccount",
			"headerName": "Listing on Shopify",
			"description": "Listing on Shopify",
			"width": 380,
			"sortable": false,
			"filterable": false,
			"valueGetter": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "SHOPIFY")?.[0];
				if (marketplace?.shopifyStore?.domain && marketplace?.metadata?.handle) {
					return `https://${marketplace?.shopifyStore?.domain}.myshopify.com/products/${marketplace?.metadata?.handle}`;
				}
				return null
			},
			"renderCell": params => {
				let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "SHOPIFY")?.[0];
				if (marketplace) {
					return <Stack direction="row" alignItems="center" spacing={2}>
						{/* <CustomAvatar name={marketplace?.sku} icon={<FaShopify />}></CustomAvatar> */}
						{
							(marketplace?.shopifyStore?.domain && marketplace?.metadata?.handle) &&
							<Button
								color="inherit"
								variant="contained"
								onClick={() => {
									window.open(`https://${marketplace?.shopifyStore?.domain}.myshopify.com/products/${marketplace?.metadata?.handle}`, "_blank");
								}}
								size="small"
							>
								View
							</Button>
						}
						<Typography sx={{ minWidth: 60 }}>
							{`$ ${StringToNumber(marketplace?.price)}`}
						</Typography>
						<Typography sx={{ color: marketplace.listingStatus === "OUT_OF_STOCK" ? "red" : null }}>
							{
								marketplace.listingStatus === "OUT_OF_STOCK" ? `Out of stock` : `(Available Qty: ${marketplace?.availableQuantity ?? 0})`
							}
							{/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
						</Typography>

					</Stack>
				}
				return null;
			},
		},
		{
			"field": "status",
			"headerName": "Status",
			"description": "Status",
			"width": 120,
			"sortable": false,
			"editable": false,
		},
		{
			"field": "updatedAt",
			"headerName": "Last Updated",
			"description": "Last Updated",
			"width": 160,
			"sortable": false,
			"filterable": false,
			"valueFormatter": params => {
				if (!params.value) return '';
				return moment(params?.value).fromNow()
			}
		},
		{
			"field": "createdAt",
			"headerName": "Created",
			"description": "Created",
			"width": 120,
			"sortable": false,
			"filterable": false,
			"valueFormatter": params => {
				if (!params.value) return '';
				return moment(params?.value).format('MM/DD/YYYY')
			}
		},
	];
	if (typeof onHistoryClick === "function") {
		_columns.push({
			"field": "History",
			"headerName": "History",
			"description": "History",
			"width": 200,
			"sortable": false,
			"editable": false,
			"align": 'center',
			"headerAlign": 'left',
			"renderCell": (params) => {
				if (params?.row?._id) {
					return <Button
						size="small"
						color="inherit"
						variant="contained"
						onClick={() => {
							onHistoryClick(params?.row);
						}}
					>
						View history
					</Button>
				}
				return params?.row?._id;
			}
		});
	}
	return _columns
}