import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Stores from "../pages/Stores";
import ConnectShopifyStore from "../pages/ConnectShopifyStore";
import route_names from "./route_names";
import MyAccount from "../pages/users/MyAccount";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Orders from '../pages/orders/Orders';
import Products from '../pages/Products';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Customers from '../pages/Customers';
import EditProduct from '../pages/EditProduct';
import MyStaff from '../pages/users/MyStaff';
import DescriptionIcon from '@mui/icons-material/Description';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Source from '@mui/icons-material/Source';
import Home from '../pages/dashboard/Home';
import SalesVelocity from '../pages/reporting/SalesVelocity';
import Research from '../pages/research/Research';
import SearchIcon from '@mui/icons-material/Search';
import Reporting from '../pages/reporting/Reporting';
import Restock from '../pages/restock/Restock';
import VendorSheet from '../pages/VendorSheet';
import SpeedIcon from '@mui/icons-material/Speed';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined';
import PointOfSale from '../pages/point_of_sale/PointOfSale';
import privileges from '../privileges';
import AddMyStaff from '../pages/users/AddMyStaff';
import EditMyStaff from '../pages/users/EditMyStaff';
import UserReportings from '../pages/reporting/UserReportings';
import MyActivity from '../pages/users/MyActivity';
import StaffActivity from '../pages/users/StaffActivity';
import RepricerTool from '../pages/repricer/RepricerTool';
import Suspects from '../pages/repricer/Suspects';
import Competitors from '../pages/repricer/Competitors';


export const navigation = [
    {
        path: route_names.index,
        element: <Home />,
        isMenu: false,
    },
    {
        title: 'Home',
        path: route_names.home,
        element: <Home />,
        icon: <HomeIcon />,
        isMenu: true,
        position: 0,
        permissions: true,
    },
    {
        title: 'Stores',
        path: route_names.stores,
        element: <Stores />,
        icon: <StoreIcon />,
        isMenu: true,
        position: 1,
        permissions: [privileges['store.ebay.view'], privileges['store.ebay.manage'], privileges['store.shopify.view'], privileges['store.shopify.manage']]
    },
    {
        path: route_names["shopify.store.connect"],
        element: <ConnectShopifyStore />,
        isMenu: false,
        permissions: privileges['store.shopify.manage']
    },
    {
        path: route_names["shopify.store.edit"],
        element: <ConnectShopifyStore isEdit={true}/>,
        isMenu: false,
        permissions: privileges['store.shopify.manage']
    },
    {
        title: 'All inventory',
        path: route_names.products,
        element: <Products />,
        icon: <LocalOfferIcon />,
        isMenu: true,
        submenu: [],
        position: 2,
        permissions: [privileges['products.view'], privileges['products.manage']]
    },
    {
        path: route_names["products.edit"],
        element: <EditProduct isEdit={true}/>,
        isMenu: false,
        permissions: privileges['products.manage']
    },
    // {
    //     title: 'Upload Checkmate',
    //     path: route_names.excelsheet,
    //     element: <ExcelSheetUpload />,
    //     icon: <UploadFileIcon />,
    //     isMenu: true,
    //     position: 10,
    //     permissions: privileges['checkmate.manage']
    // },
    {
        title: 'Orders',
        path: route_names.orders,
        element: <Orders />,
        icon: <AssignmentIcon />,
        isMenu: true,
        position: 7,
        permissions: [privileges['orders.view']]
    },
    {
        title: 'Customers',
        path: route_names.customers,
        element: <Customers />,
        icon: <PeopleAltIcon />,
        isMenu: true, 
        position: 8,
        permissions: [privileges['customers.view']]
    },
    {
        title: 'Personalized reports',
        path: route_names.reports,
        element: <UserReportings />,
        icon: <DescriptionIcon />,
        isMenu: true,
        position: 4,
        permissions: privileges['reporting.view']
    },
    {
        title: 'Sales velocity',
        path: route_names['reports.sales.velocity'],
        element: <SalesVelocity />,
        icon: <SpeedIcon />,
        isMenu: true,
        position: 5,
        permissions: privileges['reporting.view']
    },
    {
        title: 'Research',
        path: route_names.research,
        element: <Research />,
        icon: <LibraryBooksIcon />,
        isMenu: true,
        position: 3,
        permissions: [privileges['products.view'], privileges['products.manage']]
    },
    {
        title: 'Report',
        path: route_names['report.view'],
        element: <Reporting />,
        icon: <DescriptionIcon />,
        permissions: privileges['reporting.view']
    },
    {
        title: 'Ordering sheet',
        path: route_names['ordering.sheet'],
        element: <Restock />,
        icon: <Inventory2Icon />,
        isMenu: true,
        position: 6,
        permissions: privileges['ordering_sheet.view']
    },
    {
        title: 'Vendor sheet',
        path: route_names['vendor.sheet'],
        element: <VendorSheet />,
        icon: <Source />,
        isMenu: true,
        position: 10,
        permissions: privileges['vendor_sheet.view']
    },
    {
        title: 'Repricer tool',
        path: route_names["repricer"],
        element: <RepricerTool />,
        icon: <PriceChangeOutlined />,
        isMenu: true,
        position: 11,
        permissions: [privileges['repricer.view'], privileges['repricer.manage']]
    },
    {
        title: 'Suspects',
        path: route_names["repricer.suspects"],
        element: <Suspects />,
        isMenu: false,
        permissions: [privileges['repricer.view'], privileges['repricer.manage']]
    },
    {
        title: 'Suspects',
        path: route_names["repricer.suspects.dontcompete"],
        element: <Suspects />,
        isMenu: false,
        permissions: [privileges['repricer.view'], privileges['repricer.manage']]
    },
    {
        title: 'Competitors',
        path: route_names["repricer.competitors"],
        element: <Competitors />,
        isMenu: false,
        permissions: [privileges['repricer.view'], privileges['repricer.manage']]
    },
    {
        title: 'Point of sale',
        path: route_names["pos"],
        element: <PointOfSale />,
        icon: <PointOfSaleIcon />,
        isMenu: true,
        position: 30,
        permissions: privileges['point_of_sale']
    },
    {
        type: "divider",
        position: 100
    },
    {
        title: 'MANAGEMENT',
        type: "heading",
        position: 101
    },
    {
        title: 'My account',
        path: route_names["myaccount"],
        element: <MyAccount />,
        // icon: <ManageAccountsIcon />,
        isMenu: true,
        position: 103,
        permissions: true
    },
    {
        title: 'My staff',
        path: route_names['management.staff'],
        element: <MyStaff />,
        // icon: <PeopleIcon />,
        isMenu: true,
        position: 102,
        permissions: privileges['management.hr']
    },
    {
        title: 'Add Staff',
        path: route_names['management.staff.add'],
        element: <AddMyStaff />,
        isMenu: false,
        permissions: privileges['management.hr']
    },
    {
        title: 'Edit Staff',
        path: route_names['management.staff.edit'],
        element: <EditMyStaff />,
        isMenu: false,
        permissions: privileges['management.hr']
    },
    {
        title: 'Activity Feed',
        path: route_names["myaccount.history"],
        element: <MyActivity />,
        // icon: <ManageAccountsIcon />,
        isMenu: false,
        position: 104,
        permissions: true
    },
    {
        title: 'Staff activity',
        path: route_names["management.staff.activity"],
        element: <StaffActivity />,
        // icon: <ManageAccountsIcon />,
        isMenu: false,
        position: 105,
        permissions: true
    },
];
