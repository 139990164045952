const fieldsList = [
    {
        "label": "Our price",
        "value": "price"
    },
    {
        "label": "Cost/Item",
        "value": "cost"
    },
    {
        "label": "Floor price",
        "value": "floorPrice"
    },
    {
        "label": "Ceiling price",
        "value": "ceilingPrice"
    },
    {
        "label": "Ebay lowest price",
        "value": "ebayLowestPrice"
    },
    {
        "label": "Competitors lowest price",
        "value": "competitorsLowestPrice"
    }
];

const fieldsListCondition = [
    {
        "label": "Equals",
        "value": "eq"
    },
    {
        "label": "Less than",
        "value": "lt"
    },
    {
        "label": "Greater than",
        "value": "gt"
    }
];

const FiltersJsonCompare = [
    {
        "label": "Compare column",
        "value": "compareColumn",
        "valueType": "comparison",
        "value1Options": fieldsList,
        "value2Options": fieldsList,
        "conditions": fieldsListCondition
    }
];

export default FiltersJsonCompare;