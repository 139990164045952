import { useEffect } from "react";
import server_route_names from "../../routes/server_route_names";
import { useFetch } from "../../providers/AppProvider";
import { Chip, Paper, TableContainer } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import route_names from "../../routes/route_names";
import { useAuth } from "../../providers/AuthProvider";
import TableLoadingData from "../../components/TableLoadingData";
import LegacyPage from "../../subcomponents/LegacyPage";


export default function MyStaff(){
    const { data: staffData, error: staffError, fetching: staffLoading } = useFetch(`${server_route_names.users}?limit=250`);

    const navigate = useNavigate();

    const { user } = useAuth();

    useEffect(() => {
        document.title = "My staff";
    }, []);

    if (staffLoading) {
        return (
            <LegacyPage
                title="My staff"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }
    

    if (staffError || !staffData) {
        return (
            <LegacyPage
                title="My staff"
            >
                {staffError}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            title="My staff"
            actions={[
                {
                    title: "Add staff",
                    onClick: () => {
                        navigate(route_names["management.staff.add"]);
                    }
                },
                {
                    title: "Staff activity",
                    onClick: () => {
                        navigate(route_names["management.staff.activity"]);
                    }
                }
            ]}
        >
            <TableContainer component={Paper}>
                <DataGrid
                    columns={columns}
                    rows={DataGridRow(staffData, user)}
                    pageSizeOptions={[25, 50, 100]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25
                            }
                        }
                    }}
                    onRowDoubleClick={(event) => {
                        return navigate(String(route_names["management.staff.edit"]).replace(":id", event.row._id));
                        if (user && user._id === event.row._id) {
                            // if (window.confirm("Redirect to my account?")) {
                            //     navigate(route_names.myaccount);
                            // }
                        }
                        else{
                            navigate(String(route_names["management.staff.edit"]).replace(":id", event.row._id));
                        }
                    }}
                    disableRowSelectionOnClick
                >
                </DataGrid>
            </TableContainer>
        </LegacyPage>
    )
}

const columns = [
    {   field: 'id', 
        headerName: '#ID',
        width: 60,
        filterable: false,
        sortable: false,
        hideable: false
    },
    {   field: 'fullName', 
        headerName: 'Full name',
        width: 160,
        hideable: false
    },
    {   field: 'email', 
        headerName: 'Email',
        width: 240,
        hideable: false
    },
    {
        field: 'status', 
        headerName: 'Status',
        width: 120,
        filterable: false,
        sortable: false,
        renderCell: params => {
            return <Chip variant="outlined" color={params.value === "ACTIVE"?"success":params.value === "BLOCKED"?"error":"default"} label={String(params.value).toLowerCase()}>{params.value}</Chip>
        }
    },
    {   field: 'lastLogin', 
        headerName: 'Last login',
        filterable: false,
        sortable: false,
        sortable: false,
        width: 120,
        filterable: false,
        sortable: false,
        valueGetter: (params) =>  params.row.lastLogin?moment(params.row.lastLogin).fromNow():null,
    },
    {   field: 'createdAt', 
        headerName: 'Created',
        filterable: false,
        sortable: false,
        width: 180,
        valueGetter: (params) =>  `${ moment(params.row.createdAt).calendar() }`
    },
];

const DataGridRow = (data, user) => {
    let finalData = [];

    if (data?.items?.length > 0) {
        finalData = data?.items.filter(x => x._id === user?._id).map(x => {
            x.fullName = "Me";
            return x;
        });
        finalData = finalData.concat(data?.items.filter(x => x._id != user?._id));
        return finalData;
    }
    else{
        return [];
    }
}