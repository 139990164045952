
const route_names = {
    "index": "/",
    "home": "/home",
    "stores": "/stores",
    "stores.create": "/stores/connect",
    "stores.edit": "/stores/edit/:id",
    "shopify.store.connect": "/stores/shopify/connect",
    "shopify.store.edit": "/stores/shopify/edit/:id",
    "products": "/products",
    "products.create": "/products/create",
    "products.edit": "/products/edit/:id",
    "inventory": "/inventory",
    "excelsheet": "/excel-sheet",
    "draft.listing": "/products/listing/draft",
    "orders": "/orders",
    "orders.ebay": "/orders/ebay",
    "customers": "/customers",
    "pos": "/pos",
    "settings": "/settings",
    "login": "/auth/login",
    "forgot.password": "/auth/forgot-password",
    "reset.password": "/auth/reset-password",
    "auth.invitation": "/auth/invitation",
    "logout": "/logout",
    "myaccount": "/myaccount",
    "management.staff": "/management/staff",
    "reports": "/reports",
    "reports.sales.velocity": "/sales-velocity",
    "research": "/research",
    "report.view": "/report/view/:id",
    "ordering.sheet": "/restock",
    "vendor.sheet": "/vendor",
    "management.staff.add": "/management/staff/add",
    "management.staff.edit": "/management/staff/edit/:id",
    "myaccount.history": "/myaccount/history",
    "management.staff.activity": "/management/staff/activity",
    "repricer": "/repricer",
    "repricer.suspects": "/repricer/suspects",
    "repricer.suspects.dontcompete": "/repricer/suspects/dont_compete",
    "repricer.competitors": "/repricer/competitors",
};

export default route_names;