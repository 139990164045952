import { useEffect, useState } from "react";
import server_route_names from "../../routes/server_route_names";
import { useFetch } from "../../providers/AppProvider";
import { Paper, Stack, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import _enum from "../../namespace/enum";
import LegacyPage from "../../subcomponents/LegacyPage";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { usePrivileges } from "../../providers/AuthProvider";
import TableLoadingData from "../../components/TableLoadingData";
import Layout from "../../components/Layout";
import privileges from "../../privileges";
import RepricerToolProductTable from "../../components/RepricerToolProductTable";

export default function RepricerTool() {
    const [snackbar, setSnackbar] = useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pageSizeLimit = params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 10;
    const pageNumber = params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 0;
    const [paginationModel, setPaginationModel] = useState({
        page: pageNumber,
        pageSize: pageSizeLimit,
    });
    const [pageLoading, setPageLoading] = useState(true);
    const [q, setQ] = useState(params.get('q') || "");
    if (String(q).trim() !== "") {
        params.set("q", String(q).trim());
        params.set("qmw", "sku,checkmateName,title,competitorKeywords,competitorIds");
    }
    else {
        params.delete("q", q);
    }
    if (paginationModel.pageSize) {
        params.set("limit", paginationModel.pageSize);
    }
    else {
        params.set("limit", 10);
    }
    if (paginationModel.page) {
        params.set("page", parseInt(paginationModel.page) + 1);
    }
    else {
        params.set("page", 1);
    }
    const combinedParams = new URLSearchParams(params.toString());
    let productsURL = `${server_route_names["products.repricer"]}?fields=id,sku,image,title,competitorKeywords,competitorIds,suspectsCounts,competitorsCounts,competitorsLowestPrice,price,floorPrice,ceilingPrice,competitorIds,suspectUpdatedAt,competitorUpdatedAt,listingId,nonCompetitorsCounts,marketplaceListings,competitorsLowestPriceUpdateAt`;
    if (combinedParams.toString()) {
        productsURL += `&${combinedParams.toString()}`;
    }
    const { data: initialProducts, error: initialProductsError, fetching: initialProductLoading, reFetch } = useFetch(productsURL);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);    
    const navigate = useNavigate();
    const { hasPermission } = usePrivileges();

    useEffect(() => {
        document.title = "Repricer tool";
    }, []);

    useEffect(() => {
        if (initialProducts) {
            setPageLoading(false);
        }
        else if(initialProductsError){
            setPageLoading(false);
        }
    }, [initialProducts, initialProductsError]);
    const handleCloseSnackbar = () => setSnackbar(null);
    if (pageLoading) {
        return (
            <LegacyPage
                title="Repricer tool"
            >
                <TableLoadingData />
            </LegacyPage>
        )
    }

    if (initialProductsError) {
        return (
            <LegacyPage
                title="Repricer tool"
            >
                {initialProductsError}
            </LegacyPage>
        )
    }
    return (
        <LegacyPage
            title="Repricer tool"
            slots={
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="baseline">
                    <Stack width="100%">
                        <TextField
                            component={Paper}
                            margin="none"
                            label="Search product"
                            value={q}
                            onChange={(e) => {
                                setQ(e.target.value);
                                navigate(`?q=${e.target.value}`);
                            }}
                        />
                    </Stack>
                </Stack>
            }
        >

            {
                !!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )
            }
            <Layout>
                
                <Layout.Section>
                    <RepricerToolProductTable
                        paginationModel={paginationModel}
                        initialProducts={initialProducts}
                        setPaginationModel={setPaginationModel}
                        loading={initialProductLoading}
                        checkboxSelection={hasPermission(privileges["products.manage"])}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        paginationMode="server"
                        isEditAllowed={hasPermission(privileges["products.manage"])}
                    />
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}