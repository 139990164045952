import { useEffect, useState } from "react";
import LegacyModal from "./LegacyModal";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { exportToCSV, exportToExcel } from "../functions/files";

export default function ExportCSV({ data, onClose }) {

    const [open, setOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(extractLeafKeys(data));

    let columns = extractLeafKeys(data);

    useEffect(() => {
        setSelectedColumns(columns);
    }, []);

    const isChecked = (value) => {
        let index = selectedColumns.findIndex(x => x.value === value);
        if (index > -1) {
            return true;
        }
        return false;
    };

    return (
        <div>
            <LegacyModal
                title="Export products"
                onClose={onClose}
                onCancel={onClose}
                primaryAction={{
                    title: "Export to CSV",
                    onClick: () => {
                        let dataset = filterAndOrderDataset(selectedColumns, data);
                        let compile = convertToCSVString(selectedColumns, dataset);
                        exportToCSV(compile, 'export_products');
                        onClose();
                    }
                }}
                secondaryAction={{
                    title: "Export to Excel",
                    onClick: () => {
                        let dataset = filterAndOrderDatasetForExcel(selectedColumns, data);
                        exportToExcel(dataset, 'export_products');
                        onClose();
                    }
                }}
            >
                <FormGroup>
                    {
                        columns.map((r, i) => (
                            <FormControlLabel
                                key={r.name}
                                control={
                                    <Checkbox
                                        value={r.value}
                                        checked={isChecked(r.value)}
                                        onChange={(e) => {
                                            const { checked, value } = e.target;
                                            let sc = JSON.parse(JSON.stringify(selectedColumns));
                                            if (checked) {
                                                let index = sc.findIndex(x => x.value === r.value);
                                                if (index === -1) {
                                                    sc.push({
                                                        name: r.name,
                                                        value: r.value
                                                    });
                                                }
                                                else {
                                                    sc[index] = {
                                                        name: r.name,
                                                        value: r.value
                                                    }
                                                }
                                            }
                                            else {
                                                sc = sc.filter(x => x.value !== r.value);
                                            }
                                            setSelectedColumns(sc);
                                        }}
                                    />
                                }
                                label={r.name}
                            />
                        ))
                    }
                </FormGroup>
            </LegacyModal>
        </div>
    )
}

function extractLeafKeys(data) {
    const leafKeys = [];

    if (data && data.length > 0) {
        for (const key in data[0]) {
            if (typeof data[0][key] !== 'object' || Array.isArray(data[0][key])) {
                // Add top-level keys that are not objects or arrays
                leafKeys.push({ name: key, value: key });
            }
        }
    }

    let finalKeys = leafKeys.map((keyObject) => ({
        name: keyObject.name
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between two consecutive uppercase letters followed by a lowercase letter
            // .replace(/_/g, ' ') // Replace underscores with spaces
            .trim() // Remove leading and trailing spaces
            .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1)), // Capitalize the first letter of each word
        value: keyObject.value,
    }));

    finalKeys = finalKeys.filter(x => x.value !== "id" && x.value !== "_id" && x.value !== "compatibility" && x.value !== "imageUrls" && x.value !== "inventoryItemGroupKeys" && x.value !== "isbn" && x.value !== "videoIds" && x.value !== "videoUrls" && x.value !== "eBayRank" && x.value !== "ean" && x.value !== "groupIds" && x.value !== "content" && x.value !== "salesHistory" && x.value !== "availableForSale");

    return finalKeys;
}

function filterAndOrderDataset(selectedColumns, dataset) {
    // Create a new array to store the filtered dataset
    const filteredDataset = [];

    // Iterate through the dataset
    for (const data of dataset) {
        const filteredData = {};

        // Iterate through selectedColumns to maintain the desired order
        for (const column of selectedColumns) {
            // Check if the column exists in the data object
            if (data.hasOwnProperty(column.value)) {
                // Add the column and its value to the filteredData object
                filteredData[column.value] = data[column.value];
            } else {
                // If the column doesn't exist in the data, add it with a null value
                filteredData[column.value] = null;
            }
        }

        // Add the filtered data to the filteredDataset array
        filteredDataset.push(filteredData);
    }

    return filteredDataset;
}

function convertToCSVString(selectedColumns, filteredDataset) {
    const csvRows = [];

    // Create the header row with column names
    const headerRow = selectedColumns.map(r => r.name).join(",");
    csvRows.push(headerRow);

    // Iterate through the filtered dataset
    for (const data of filteredDataset) {
        const rowValues = selectedColumns.map((column) => {
            return data[column.value] !== null ? data[column.value] : ""; // Handle null values
        });
        const csvRow = rowValues.join(",");
        csvRows.push(csvRow);
    }

    // Join all rows with line breaks to create the CSV string
    return csvRows.join("\n");
}

function filterAndOrderDatasetForExcel(selectedColumns, dataset) {
    // Create a new array to store the filtered dataset
    const filteredDataset = [];

    // Iterate through the dataset
    for (const data of dataset) {
        const filteredData = {};

        // Iterate through selectedColumns to maintain the desired order
        for (const column of selectedColumns) {
            // Check if the column exists in the data object
            if (data.hasOwnProperty(column.value)) {
                // Add the column and its value to the filteredData object
                filteredData[column.name] = data[column.value];
            } else {
                // If the column doesn't exist in the data, add it with a null value
                filteredData[column.name] = null;
            }
        }

        // Add the filtered data to the filteredDataset array
        filteredDataset.push(filteredData);
    }

    return filteredDataset;
}