import React, { useCallback, useEffect, useState } from 'react';
import { useCustomRequest, useUploadFile } from '../providers/AppProvider';
import server_route_names from '../routes/server_route_names';
import { Box, Typography, Button, Alert } from '@mui/material';
import { formatFileSize } from '../functions/common';

function CsvUploader({
    onUploadDone = (uploaded_file) => {},
    fileType = "",
    targetUrl = "",
    showButton = false
}) {
    const { upload, data: fileUploaded, uploading } = useUploadFile();
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const onFileChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setErrorMessage(null);
        }
    }, []);

    const onUploadClick = useCallback(() => {
        if (selectedFile) {
            handleFileUpload();
        } else {
            setErrorMessage('Please select a CSV file.');
        }
    }, [selectedFile]);

    const handleFileUpload = async (file) => {
        let fileName = selectedFile.name.replace(/\s+/gi,'').toLowerCase();
        const formData = new FormData();
        formData.append('csvFile', selectedFile, fileName);
        var uploaded_file = await upload(`${targetUrl}?type=${fileType??'products.create'}`, formData);
        typeof onUploadDone === "function" && onUploadDone(uploaded_file);
    };

    useEffect(() => {
        if (fileUploaded) {
            setSelectedFile(null);
        }
    }, [fileUploaded, uploading]);

    if (fileUploaded) {
        return (
            <div>
                {
                    (fileUploaded && fileUploaded.status === "success") && (
                        <Alert severity="success">{fileUploaded.message}</Alert>
                    )
                }
                {
                    (fileUploaded && fileUploaded.status !== "success") && (
                        <Alert severity="error">{fileUploaded.message}</Alert>
                    )
                }
            </div>
        )
    }

    return (
        <div>
            <input
                type="file"
                accept=".csv"
                id="csv-file-input"
                style={{ display: 'none' }}
                onChange={onFileChange}
            />
            <label htmlFor="csv-file-input">
                <Box
                    sx={{
                        border: '2px dashed #ccc',
                        borderRadius: '4px',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {selectedFile ? selectedFile.name : 'Select a CSV file'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {selectedFile
                            ? `Size: ${formatFileSize(selectedFile.size)}`
                            : ''}
                    </Typography>
                </Box>
            </label>
            {(selectedFile || showButton) && (
                <Button
                    variant="contained"
                    onClick={onUploadClick}
                    sx={{mt: 2}}
                    disabled={uploading || !selectedFile}
                >
                    Upload CSV
                </Button>
            )}
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </div>
    );
}

export default CsvUploader;
