import moment from "moment";
import { StringToNumber } from "../functions/common";
import { getPrevious3Months } from "../functions/dates";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import CustomAvatar from "./CustomAvatar";
import { FaEbay, FaShopify } from "react-icons/fa6";
import { ebay_store_names } from "../config";

const Previous3Months = getPrevious3Months();

function ColumnsJson(data){
    
    let columns_json = [
        {
            "field": "image",
            "headerName": "Image",
            "description": "Image",
            "width": 110,
            "sortable": false,
            "filterable": false,
            "hideable": false,
            "printable": false,
            "renderCell": (params) => {
                let img = params?.row?.image??params?.row?.images?.[0]??null;
                if(img){
                    return <Avatar
                        alt={`Image-${params?.row?.sku}`}
                        src={img}
                        sx={{ width: 84, height: 84, borderRadius: 0 }}
                    />;
                }
                return null;
            },
            "align": 'center',
            "headerAlign": 'center',
        },
        {
            "field": "sku",
            "headerName": "Product ID/SKU",
            "description": "SKU",
            "width": 180,
            "sortable": true,
            "editable": false,
        },
        {
            "field": "checkmateName",
            "headerName": "Checkmate Name",
            "description": "Checkmate name",
            "width": 140,
            "sortable": true,
            "editable": true,
        },
        {
            "field": "pairName",
            "headerName": "Pair Name",
            "description": "Pair name/Pair code",
            "width": 140,
            "sortable": true,
            "editable": false,
        },
        {
            "field": "title",
            "headerName": "Title",
            "description": "Item title",
            "width": 300,
            "sortable": false,
            "editable": false,
            "renderCell": (params) => {
                return params?.value;
                // return params?.row?.marketplaceListings?.[0]?.title??null;
            },
        },
        {
            "field": "price",
            "headerName": "Price",
            "description": "Item price / Our price / Ebay price",
            "width": 130,
            "sortable": false,
            "editable": false,
            "type": "number",
            "valueFormatter": params => {
                if (!params?.value) {
                    return `$ ${StringToNumber(params?.row?.marketplaceListings?.[0]?.price??0)}`;
                }
                return `$ ${StringToNumber(params.value)}`;
            }
        },
        {
            "field": "inventoryQuantity",
            "headerName": "Inventory Quantity",
            "description": "In stock quantity",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return StringToNumber(params.value)
            }
        },
        {
            "field": "cost",
            "headerName": "Cost/Item",
            "description": "Cost per item",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "fbmCost",
            "headerName": "FBM Cost",
            "description": "FBM cost",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "ebayLowestPrice",
            "headerName": "Ebay Lowest Price",
            "description": "Ebay Lowest price",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "floorPrice",
            "headerName": "Floor Price",
            "description": "Floor price",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "ceilingPrice",
            "headerName": "Ceiling Price",
            "description": "Ceiling Price",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number",
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "soldQuantity",
            "headerName": "Sold Quantity",
            "description": "Sold quantity",
            "width": 130,
            "sortable": true,
            "type": "number",
            "valueFormatter": params => {
                return StringToNumber(params.value)
            }
        },
        {
            "field": "upcomingQuantity",
            "headerName": "Upcoming Quantity",
            "description": "Upcoming quantity",
            "width": 130,
            "sortable": true,
            "editable": false,
            "type": "number",
            "valueFormatter": params => {
                return StringToNumber(params.value)
            }
        },
        {
            "field": "ebayFeeAmount",
            "headerName": "Ebay FEE Price",
            "description": "Ebay FEE Price; Formula = price x 0.11",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                return StringToNumber(params.value)
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "adRate",
            "headerName": "Ebay Ad Rate %",
            "description": "Ebay Ad Rate %",
            "width": 130,
            "sortable": false,
            // "valueGetter": (params) => `${params?.row?.adRate}%`,
            "editable": true,
            "valueFormatter": params => {
                return `${StringToNumber(params.value)} %`
            }
        },
        {
            "field": "adRateAmount",
            "headerName": "Ebay Ad Rate",
            "description": "Ebay Ad Rate",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                return StringToNumber(params.value)
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "profit",
            "headerName": "Ebay Profit",
            "description": "Ebay Profit",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                return StringToNumber(params.value);
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            },
        },
        {
            "field": "profitPercentage",
            "headerName": "Ebay Profit %",
            "description": "Ebay Profit %",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueFormatter": params => {
                return `${StringToNumber(params.value)} %`
            }
        },
        {
            "field": "ebayFeeWhileOnFloorPrice",
            "headerName": "Ebay Fee While On Floor Price",
            "description": "Ebay Fee While On Floor Price",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                return StringToNumber(params?.row?.floorPrice * params?.row?.ebayFee / 100)
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "ebayAdRateCostAtFloorPrice",
            "headerName": "Ebay Ad Rate Cost At Floor Price",
            "description": "Ebay Ad Rate Cost At Floor Price",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                return StringToNumber(params?.row?.floorPrice * params?.row?.adRate / 100)
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "netProfitOnFloorPrice",
            "headerName": "Profit On Floor Price",
            "description": "Profit On Floor Price",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                let price = Number(params?.row?.floorPrice??0);
                let cost = Number(params?.row?.cost??0);
                let fbmCost = Number(params?.row?.fbmCost??0);
                let ebayFee = Number(params?.row?.ebayFeeAmount??0);
                let adrate = Number(params?.row?.adRateAmount??0);
                let profit = price - cost - fbmCost - ebayFee - adrate;
                return `${StringToNumber(profit)}`;
            },
            "valueFormatter": params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {
            "field": "netProfitOnFloorPricePercentage",
            "headerName": "Profit On Floor Price %",
            "description": "Profit On Floor Price %",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                let price = Number(params?.row?.floorPrice??0);
                let profit = Number(params?.row?.profit??0);
                return `${StringToNumber(profit/price*100)}`;
            },
            "valueFormatter": params => {
                return `${StringToNumber(params.value)} %`
            }
        },
        {
            "field": "ebayRank",
            "headerName": "Ebay Ranking",
            "description": "Ebay ranking",
            "width": 130,
            "sortable": true,
            "editable": true,
            "type": "number"
        },
        {
            "field": "customCategory",
            "headerName": "Part Name",
            "description": "Part name",
            "width": 200,
            "sortable": true,
            "editable": false,
        },
        {
            "field": "ebayResearchLinkByPart1",
            "headerName": "Ebay Research Link By Part 1",
            "description": "Ebay Research Link By Part 1",
            "width": 200,
            "sortable": true,
            "editable": true,
            "align": 'center',
            "headerAlign": 'left',
            "renderCell": (params) => {
                if (params?.value) {
                    return <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
                        }}
                    >
                        View
                    </Button>
                }
                return params?.value;
            }
        },
        {
            "field": "ebayResearchLinkByPart2",
            "headerName": "Ebay Research Link By Part 2",
            "description": "Ebay Research Link By Part 2",
            "width": 200,
            "sortable": true,
            "editable": true,
            "align": 'center',
            "headerAlign": 'left',
            "renderCell": (params) => {
                if (params?.value) {
                    return <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
                        }}
                    >
                        View
                    </Button>
                }
                return params?.value;
            }
        },
        {
            "field": "ebayResearchLinkByKeyword1",
            "headerName": "Ebay Research Link By Keyword 1",
            "description": "Ebay Research Link By Keyword 1",
            "width": 200,
            "sortable": true,
            "editable": true,"align": 'center',
            "headerAlign": 'left',
            "renderCell": (params) => {
                if (params?.value) {
                    return <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
                        }}
                    >
                        View
                    </Button>
                }
                return params?.value;
            }
        },
        {
            "field": "ebayResearchLinkByKeyword2",
            "headerName": "Ebay Research Link By Keyword 2",
            "description": "Ebay Research Link By Keyword 2",
            "width": 200,
            "sortable": false,
            "editable": true,
            "align": 'center',
            "headerAlign": 'left',
            "renderCell": (params) => {
                if (params?.value) {
                    return <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            window.open(`https://${String(params.value).replace('https://', '')}`, "_blank");
                        }}
                    >
                        View
                    </Button>
                }
                return params?.value;
            }
        },
        {
            "field": "keyword1",
            "headerName": "Keyword 1",
            "description": "Keyword 1",
            "width": 200,
            "sortable": true,
            "editable": true,
        },
        {
            "field": "soldoutDate",
            "headerName": "When Item Got Out Of Stock",
            "description": "When Item Got Out Of Stock",
            "width": 180,
            "sortable": false,
            "valueFormatter": params => {
                if (!params.value) return '';
                if(params.value) return moment(params.value).format('MM/DD/YYYY hh:mm a')
                return ''
            }
        },
        {
            "field": "weightOz",
            "headerName": "Weight OZ",
            "description": "Weight OZ",
            "width": 120,
            "sortable": true,
            "editable": true,
        },
        {
            "field": "height",
            "headerName": "Height",
            "description": "Height",
            "width": 120,
            "sortable": true,
            "editable": true,
        },
        {
            "field": "length",
            "headerName": "Length",
            "description": "Length",
            "width": 120,
            "sortable": true,
            "editable": true,
        },
        {
            "field": "vendor",
            "headerName": "Vendor",
            "description": "Vendor",
            "width": 120,
            "sortable": true,
            "editable": true,
        },
        {
            "field": Previous3Months[0].monthName,
            "headerName": `${Previous3Months[0].monthName} ${Previous3Months[0].year}`,
            "description": Previous3Months[0].monthName,
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                try {
                    let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[0].monthNumber && x?.year === Previous3Months[0].year);
                    if (ind > -1) {
                        return StringToNumber(params?.row?.orders[ind]["quantity"]);
                    }
                } catch (error) {
                    
                }
                return 0;
            }
        },
        {
            "field": Previous3Months[1].monthName,
            "headerName": `${Previous3Months[1].monthName} ${Previous3Months[1].year}`,
            "description": Previous3Months[1].monthName,
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                try {
                    let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[1].monthNumber && x?.year === Previous3Months[1].year);
                    if (ind > -1) {
                        return StringToNumber(params?.row?.orders[ind]["quantity"]);
                    }
                } catch (error) {
                    
                }
                return 0;
            }
        },
        {
            "field": Previous3Months[2].monthName,
            "headerName": `${Previous3Months[2].monthName} ${Previous3Months[2].year}`,
            "description": Previous3Months[2].monthName,
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                try {
                    let ind = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[2].monthNumber && x?.year === Previous3Months[2].year);
                    if (ind > -1) {
                        return StringToNumber(params?.row?.orders[ind]["quantity"]);
                    }
                } catch (error) {
                    
                }
                return 0;
            }
        },
        {
            "field": "Last60Days",
            "headerName": "Last 60 Days",
            "description": "Last 60 Days",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                let count = 0;
                try {
                    let a1 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[0].monthNumber && x?.year === Previous3Months[0].year);
                    let a2 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[1].monthNumber && x?.year === Previous3Months[1].year);
                    let a3 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[2].monthNumber && x?.year === Previous3Months[2].year);
                    if (a2 > -1) {
                        count += Number(params?.row?.orders[a2]["quantity"]); 
                    }
                    if (a3 > -1) {
                        count += Number(params?.row?.orders[a3]["quantity"]); 
                    }
                } catch (error) {
                    
                }
                return count;
            }
        },
        {
            "field": "Last90Days",
            "headerName": "Last 90 Days",
            "description": "Last 90 Days",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                let count = 0;
                try {
                    let a1 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[0].monthNumber && x?.year === Previous3Months[0].year);
                    let a2 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[1].monthNumber && x?.year === Previous3Months[1].year);
                    let a3 = params?.row?.orders?.findIndex(x => x?.month === Previous3Months[2].monthNumber && x?.year === Previous3Months[2].year);
                    if (a1 > -1) {
                        count += Number(params?.row?.orders[a1]["quantity"]); 
                    }
                    if (a2 > -1) {
                        count += Number(params?.row?.orders[a2]["quantity"]); 
                    }
                    if (a3 > -1) {
                        count += Number(params?.row?.orders[a3]["quantity"]); 
                    }
                } catch (error) {
                    
                }
                return count;
            }
        },
        {
            "field": "Thisyear",
            "headerName": "This Year",
            "description": "This Year",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                let count = 0;
                try {
                    params?.row?.orders?.forEach(item => {
                        if (item?.year === new Date().getFullYear()) {
                            count += Number(item?.quantity);
                        }
                    });
                } catch (error) {
                    
                }
                return count;
            }
        },
        {
            "field": "lastyear",
            "headerName": `Last Year ${new Date().getFullYear() - 1}`,
            "description": `Last Year ${new Date().getFullYear() - 1}`,
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                let count = 0;
                try {
                    params?.row?.orders?.forEach(item => {
                        if (item?.year === new Date().getFullYear() - 1) {
                            count += Number(item?.quantity);
                        }
                    });
                } catch (error) {
                    
                }
                return count;
            }
        },
        {
            "field": "totalSales",
            "headerName": "Total Sales",
            "description": "Total Sales",
            "width": 130,
            "sortable": false,
            "filterable": false,
            "valueGetter": (params) => {
                let count = 0;
                try {
                    params?.row?.orders?.forEach(item => {
                    count += Number(item?.quantity);
                });
                } catch (error) {
                    
                }
                return count;
            }
        },
        {
            "field": "comment",
            "headerName": "Comment",
            "description": "Total Sales",
            "width": 300,
            "sortable": true,
            "editable": true,
        },
        {
            "field": `Listing_${ebay_store_names[1].username}`,
            "headerName": `Listing on ${ebay_store_names[1].name}`,
            "description": `Listing on ${ebay_store_names[1].name}`,
            "width": 380,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[1].username)?.[0];
                if (marketplace && marketplace?.listingId) {
                    return `https://www.ebay.com/itm/${marketplace?.listingId}`;
                }
                return null
            },
            "renderCell": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[1].username)?.[0];
                if (marketplace) {
                    return <Stack direction="row" alignItems="center" spacing={2}>
                        {/* <CustomAvatar name={marketplace?.ebayStore?.name} icon={<FaEbay size="36" />}></CustomAvatar> */}
                        {
                            marketplace?.listingId &&
                            <Button
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                    window.open(`https://www.ebay.com/itm/${marketplace?.listingId}`, "_blank");
                                }}
                                size="small"
                            >
                                View
                            </Button>
                        }
                        <Typography sx={{minWidth: 60}}>
                            {`$ ${StringToNumber(marketplace?.price)}`}
                        </Typography>
                        <Typography sx={{color: marketplace.listingStatus === "OUT_OF_STOCK"?"red": null }}>
                            {/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
                            {
                                marketplace.listingStatus === "OUT_OF_STOCK"?`Out of stock`:`(Available Qty: ${marketplace?.availableQuantity??0})`
                            }
                        </Typography>
                    </Stack>
                }
                return null;
            },
        },
        {
            "field": `Listing_${ebay_store_names[2].username}`,
            "headerName": `Listing on ${ebay_store_names[2].name}`,
            "description": `Listing on ${ebay_store_names[2].name}`,
            "width": 380,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[2].username)?.[0];
                if (marketplace && marketplace?.listingId) {
                    return `https://www.ebay.com/itm/${marketplace?.listingId}`;
                }
                return null
            },
            "renderCell": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "EBAY" && x?.ebayStore?.username === ebay_store_names[2].username)?.[0];
                if (marketplace) {
                    return <Stack direction="row" alignItems="center" spacing={2}>
                        {/* <CustomAvatar name={marketplace?.ebayStore?.name} icon={<FaEbay size="36" />}></CustomAvatar> */}
                        {
                            marketplace?.listingId &&
                            <Button
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                    window.open(`https://www.ebay.com/itm/${marketplace?.listingId}`, "_blank");
                                }}
                                size="small"
                            >
                                View
                            </Button>
                        }
                        <Typography sx={{minWidth: 60}}>
                            {`$ ${StringToNumber(marketplace?.price)}`}
                        </Typography>
                        <Typography sx={{color: marketplace.listingStatus === "OUT_OF_STOCK"?"red": null }}>
                            {/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
                            {
                                marketplace.listingStatus === "OUT_OF_STOCK"?`Out of stock`:`(Available Qty: ${marketplace?.availableQuantity??0})`
                            }
                        </Typography>
                        
                    </Stack>
                }
                return null;
            },
        },
        {
            "field": "Listing_ShopifyAccount",
            "headerName": "Listing on Shopify",
            "description": "Listing on Shopify",
            "width": 380,
            "sortable": false,
            "filterable": false,
            "valueGetter": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "SHOPIFY")?.[0];
                if (marketplace?.shopifyStore?.domain && marketplace?.metadata?.handle) {
                    return `https://${marketplace?.shopifyStore?.domain}.myshopify.com/products/${marketplace?.metadata?.handle}`;
                }
                return null
            },
            "renderCell": params => {
                let marketplace = params?.row?.marketplaceListings?.filter(x => x?.storeType === "SHOPIFY")?.[0];
                if (marketplace) {
                    return <Stack direction="row" alignItems="center" spacing={2}>
                        {/* <CustomAvatar name={marketplace?.sku} icon={<FaShopify />}></CustomAvatar> */}
                        {
                            (marketplace?.shopifyStore?.domain && marketplace?.metadata?.handle) &&
                            <Button
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                    window.open(`https://${marketplace?.shopifyStore?.domain}.myshopify.com/products/${marketplace?.metadata?.handle}`, "_blank");
                                }}
                                size="small"
                            >
                                View
                            </Button>
                        }
                        <Typography sx={{minWidth: 60}}>
                            {`$ ${StringToNumber(marketplace?.price)}`}
                        </Typography>
                        <Typography sx={{color: marketplace.listingStatus === "OUT_OF_STOCK"?"red": null }}>
                            {
                                marketplace.listingStatus === "OUT_OF_STOCK"?`Out of stock`:`(Available Qty: ${marketplace?.availableQuantity??0})`
                            }
                            {/* ({`Available Qty: ${marketplace?.availableQuantity??0}`}) */}
                        </Typography>
                        
                    </Stack>
                }
                return null;
            },
        },
        {
            "field": "status",
            "headerName": "Status",
            "description": "Status",
            "width": 120,
            "sortable": false,
            "editable": false,
        },
        {
            "field": "updatedAt",
            "headerName": "Last Updated",
            "description": "Last Updated",
            "width": 160,
            "sortable": false,
            "filterable": false,
            "valueFormatter": params => {
                if (!params.value) return '';
                return moment(params?.value).fromNow()
            }
        },
        {
            "field": "createdAt",
            "headerName": "Created",
            "description": "Created",
            "width": 120,
            "sortable": false,
            "filterable": false,
            "valueFormatter": params => {
                if (!params.value) return '';
                return moment(params?.value).format('MM/DD/YYYY')
            }
        },
        {
            "field": "History",
            "headerName": "History",
            "description": "History",
            "width": 200,
            "sortable": false,
            "editable": false,
            "align": 'center',
            "headerAlign": 'left',
            "renderCell": (params) => {
                if (params?.row?._id) {
                    return <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() => {
                            alert("History");
                        }}
                    >
                        View history
                    </Button>
                }
                return params?.row?._id;
            }
        },
    ];

    return columns_json;
}

export default ColumnsJson