
const privileges = {
    "store.ebay.view": "STORE.EBAY.VIEW",
    "store.ebay.manage": "STORE.EBAY.MANAGE",
    "store.shopify.view": "STORE.SHOPIFY.VIEW",
    "store.shopify.manage": "STORE.SHOPIFY.MANAGE",
    "products.view": "PRODUCTS.VIEW",
    "products.manage": "PRODUCTS.MANAGE",
    "products.delete": "PRODUCTS.DELETE",
    "checkmate.manage": "CHECKMATE.MANAGE",
    "vendor_sheet.view": "VENDOR_SHEET.VIEW",
    "vendor_sheet.manage": "VENDOR_SHEET.MANAGE",
    "vendor_sheet.delete": "VENDOR_SHEET.DELETE",
    "orders.view": "ORDERS.VIEW",
    "customers.view": "CUSTOMERS.VIEW",
    "reporting.view": "REPORTING.VIEW",
    "reporting.manage": "REPORTING.MANAGE",
    "financial_reporting.view": "FINANCIAL_REPORTING.VIEW",
    "financial_reporting.manage": "FINANCIAL_REPORTING.MANAGE",
    "point_of_sale": "POS",
    "ordering_sheet.view": "ORDERING_SHEET.VIEW",
    "management.hr": "MANAGEMENT.HR",
    "management.revenue": "MANAGEMENT.REVENUE",
    "dashboard.widget.view": "DASHBOARD.WIDGETS.VIEW",
    "dashboard.widget.manage": "DASHBOARD.WIDGETS.MANAGE",
    "repricer.view": "REPRICER.VIEW",
    "repricer.manage": "REPRICER.MANAGE",
}

export default privileges;