import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

export default function LegacyModal({ title, children, primaryAction, secondaryAction, onClose, onCancel }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="legacydialog-title"
        aria-describedby="legacydialog-description"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="sm"
    >
    <DialogTitle id="legacydialog-title">
        <Stack sx={{width: "100%"}} justifyItems="center" direction="row">
            <Stack sx={{width: "100%"}} >
                {title}
            </Stack>
            <Stack alignItems="end">
                <IconButton
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Stack>
    </DialogTitle>
    <DialogContent dividers>
        {children}
    </DialogContent>
    <DialogActions>
        {
            !secondaryAction && onCancel && (
                <Button
                    onClick={onCancel}
                    variant="text"
                >
                    Cancel
                </Button>
            )
        }
        {
            secondaryAction && (
                <Button
                    onClick={secondaryAction.onClick}
                    variant={secondaryAction.variant??"text"}
                    disabled={secondaryAction.disabled}
                    color={secondaryAction.color}
                >
                    {secondaryAction.title}
                </Button>
            )
        }
        {
            primaryAction && (
                <Button
                    onClick={primaryAction.onClick}
                    variant={primaryAction.variant??"text"}
                    disabled={primaryAction.disabled}
                    color={primaryAction.color}
                >
                    {primaryAction.title}
                </Button>
            )
        }
    </DialogActions>
    </Dialog>
  );
}