import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function Row(props) {
  const { row, is_open } = props;
  const [open, setOpen] = React.useState(is_open??false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.sku}
        </TableCell>
        <TableCell component="th" scope="row">
          {sum(row.sales, 'quantity')}
        </TableCell>
        <TableCell component="th" scope="row">
          ${sum(row.sales, 'total')}
        </TableCell>
        <TableCell>
        ${
          Number(
            Number(sum(row.sales, 'total') / sum(row.sales, 'quantity')).toFixed(2)
          )
        }
        </TableCell>
        {/* <TableCell component="th" scope="row">
            <ButtonGroup size="small">
              <Button
                size="small"
                onClick={() => {
                  // navigate(route_names['products.edit'].replace(":id", row.productId))
                }}>
                Export
              </Button>
              <Button
                size="small"
                onClick={() => {
                  navigate(route_names['products.edit'].replace(":id", row.productId))
                }}>
                  Edit
              </Button>
            </ButtonGroup>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Orders history
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Subtoal($)</TableCell>
                    <TableCell>Total($)</TableCell>
                    <TableCell>Fulfillment Status</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Marketplace</TableCell>
                    <TableCell>Store Name</TableCell>
                  </TableRow>
                </TableHead>
                {
                    row.sales && typeof row.sales.map === "function" && (
                        <TableBody>
                            {row.sales.map((saleRow) => (
                                <TableRow key={saleRow.creationDate}>
                                    <TableCell component="th" scope="row">
                                        {moment(saleRow.creationDate).calendar()}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {saleRow.orderNumber}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {saleRow.customer?.fullName}
                                    </TableCell>
                                    <TableCell>
                                        {saleRow.quantity}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      ${saleRow.subtotal}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      ${saleRow.total}
                                    </TableCell>
                                    <TableCell>
                                        {String(saleRow.orderFulfillmentStatus).replace(/\_/gi, ' ')}
                                    </TableCell>
                                    <TableCell>
                                        {String(saleRow.orderPaymentStatus).replace(/\_/gi, ' ')}
                                    </TableCell>
                                    <TableCell>
                                        {saleRow.sellingMarketplace}
                                    </TableCell>
                                    <TableCell>
                                        {saleRow.store}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )
                }
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({data, isOpen}) {
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>SKU</TableCell>
            <TableCell>Sold Quantity</TableCell>
            <TableCell>Total Revenue</TableCell>
            <TableCell>Average Selling Price</TableCell>
            {/* <TableCell>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <Row key={`${row.sku}-${index}`} row={row} is_open={isOpen} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function sum(sales, key){
    let sm = 0;
    try {
        sales.forEach(sale => {
          if (!isNaN(sale[key])) {
            sm += sale[key];
          }
        });
    } catch (error) { }
    return Number(Number(sm).toFixed(2));
}