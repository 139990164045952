import { createContext, useContext, useEffect, useState } from "react";
import { useFetch } from "./AppProvider";
import server_route_names from "../routes/server_route_names";
import store from "store2";
import namespace from "../namespace";

const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

const AppDataProvider  = ({children}) => {
    const { data: ebayStoreData } = useFetch(server_route_names["ebay.stores"]);
    const { data: shopifyStore } = useFetch(server_route_names["shopify.stores"]);
    const { data: users } = useFetch(server_route_names["unauthorized_access.users"]);
    const [ebayStore, setEbayStore ] = useState(null);
    const [selectedEbayStore, setSelectedEbayStore] = useState(null);
    
    useEffect(() => {
        if (ebayStoreData) {
            setEbayStore(ebayStoreData);
            if (ebayStoreData?.length > 0) {
                setSelectedEbayStore(ebayStoreData[0]);
                store(namespace.ebayStoreId, ebayStoreData[0]["id"]);
            }
        }
    }, [ebayStoreData]);

    return (
        <AppDataContext.Provider value={{
            ebayStore,
            selectedEbayStore,
            setSelectedEbayStore, 
            shopifyStore,
            users
        }}>
            {children}
        </AppDataContext.Provider>
    )
}

export default AppDataProvider;