import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import LegacyCard from "../../subcomponents/LegacyCard";
import LegacyPage from "../../subcomponents/LegacyPage";
import { Button, Card, FormControl, InputLabel, MenuItem, Paper, Select, Stack } from "@mui/material";
import server_route_names from "../../routes/server_route_names";
import { useRequest } from "../../providers/AppProvider";
import ColumnsJson from "../../components/ColumnsJson";
import { useLocation, useNavigate } from "react-router-dom";
import ProductTable from "../../components/ProductTable";
import { StringToNumber } from "../../functions/common";

const showColumns = ["image", "sku", "price", "title", "inventoryQuantity", "lowestPrice"];
const products_fields = `${showColumns.filter(x => x !== "image").toString()},id,image`;
const selectedColumns = ColumnsJson().filter(x => showColumns.findIndex(y => y === x.field) > -1).concat([
    {
        "field": "lowestPrice",
        "headerName": "Lowest Price",
        "description": "Item lowest price",
        "width": 140,
        "sortable": false,
        "editable": false,
        "type": "number",
        "valueFormatter": params => {
            return `$ ${StringToNumber(params.value)}`;
        }
    },
]);

export default function PointOfSale() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let ymm_query = `${server_route_names["pos.ymm"]}?timestamps=${new Date().getTime()}`;
    const pageSizeLimit = params.get('limit') && !isNaN(params.get('limit')) ? parseInt(params.get('limit')) : 100;
    const pageNumber = params.get('page') && !isNaN(params.get('page')) ? parseInt(params.get('page')) : 0;
    const [paginationModel, setPaginationModel] = useState({
        page: pageNumber,
        pageSize: pageSizeLimit,
    });
    const [year, setYear] = useState("");
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [customCategory, setCustomCategory] = useState("");
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [customCategories, setCustomCategories] = useState([]);
    const [products, setProducts] = useState({});
    const { data: ymm_filters, loading: ymm_loading, error: ymm_error, sendRequest: ymmRequest } = useRequest();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Point of sale";
    }, []);

    // fetch years
    useEffect(() => {
        ymmRequest(`${ymm_query}&viewset=year&fields=${products_fields}`);
    }, []);

    // when year changed
    useEffect(() => {
        if (year) {
            ymmRequest(`${ymm_query}&viewset=make&year=${year}&fields=${products_fields}`);
        }
    }, [year]);

    useEffect(() => {
        if (make && year) {
            ymmRequest(`${ymm_query}&viewset=model&year=${year}&make=${make}&fields=${products_fields}`);
        }
    }, [make]);

    useEffect(() => {
        if (year && make && model) {
            let page = parseInt(paginationModel.page)+1;
            ymmRequest(`${ymm_query}&viewset=custom_category&year=${year}&make=${make}&model=${model}&fields=${products_fields}&page=${page??1}&limit=${paginationModel.pageSize}`);
        }
    }, [model]);

    useEffect(() => {
        if (year && make && model && customCategory) {
            let page = parseInt(paginationModel.page)+1;
            ymmRequest(`${ymm_query}&viewset=products&year=${year}&make=${make}&model=${model}&custom_category=${customCategory}&fields=${products_fields}&page=${page}&limit=${paginationModel.pageSize??10}`);
        }
    }, [customCategory]);

    useEffect(() => {
        if (ymm_filters?.data && ymm_filters?.data.ymm) {
            if (ymm_filters?.data?.ymm?.["year"]) {
                setYears(ymm_filters?.data?.ymm?.["year"]);
            }
            if (ymm_filters?.data?.ymm?.["make"]) {
                setMakes(ymm_filters?.data?.ymm?.["make"]);
            }
            if (ymm_filters?.data?.ymm?.["model"]) {
                setModels(ymm_filters?.data?.ymm?.["model"]);
            }
            if (ymm_filters?.data?.["products"]) {
                setProducts(ymm_filters?.data?.["products"]);
            }
            if (ymm_filters?.data?.ymm?.["custom_category"]) {
                setCustomCategories(ymm_filters?.data?.ymm?.["custom_category"]);
            }
        }
    }, [ymm_filters]);

    const findProducts = async () => {
        if (year && make && model && customCategory) {
            ymmRequest(`${ymm_query}&viewset=products&year=${year}&make=${make}&model=${model}&custom_category=${customCategory}&fields=${products_fields}`);
        }
    }

    return (
        <LegacyPage
            slots={
                <Stack direction="row" width="100%" spacing={2} component={Paper} padding={2.5}>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Select Year</InputLabel>
                        <Select
                            label="Select Year"
                            value={year}
                            onChange={(e) => {
                                setMakes([]);setModels([]);setModel("");setMake("");setCustomCategories([]);setCustomCategory("")
                                setYear(e.target.value);
                            }}
                        >
                            {
                                years.map(
                                    (r, i) => (
                                        <MenuItem key={r} value={r}>{r}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Select Make</InputLabel>
                        <Select
                            label="Select Make"
                            value={make}
                            onChange={(e) => {
                                setModels([]);setModel("");setCustomCategories([]);setCustomCategory("")
                                setMake(e.target.value);
                            }}
                            disabled={makes.length === 0}
                        >
                            {
                                makes.map(
                                    (r, i) => (
                                        <MenuItem key={r} value={r}>{r}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Select Model</InputLabel>
                        <Select
                            label="Select Model"
                            value={model}
                            onChange={(e) => {
                                setCustomCategories([]);setCustomCategory("")
                                setModel(e.target.value);
                            }}
                            disabled={models.length === 0}
                        >
                            {
                                models.map(
                                    (r, i) => (
                                        <MenuItem key={r} value={r}>{r}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Select Part Name</InputLabel>
                        <Select
                            label="Select Part Name"
                            value={customCategory}
                            onChange={(e) => {
                                setCustomCategory(e.target.value);
                            }}
                            disabled={customCategories.length === 0}
                        >
                            {
                                customCategories.map(
                                    (r, i) => (
                                        <MenuItem key={r} value={r}>{r}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={findProducts}
                        disabled={(year && make && model && customCategory)?false:true}
                    >
                        Find
                    </Button>
                </Stack>
            }
        >
            <Layout>
                <Layout.Section>
                    <ProductTable
                        initialProducts={products}
                        columns={selectedColumns}
                        loading={ymm_loading}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        showQuickFilter
                        emptyPageMessage={
                            !year?"Select Year":!make?"Select Make":!model?"Select Model":!customCategory?"Select Part Name":"Select Year Make & Model to show products"
                        }
                        paginationMode="server"
                    />
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}