import { useDeleteRecord, useFetch, useUpdateRecord } from "../providers/AppProvider";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import server_route_names from "../routes/server_route_names";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LegacyPage from "../subcomponents/LegacyPage";
import CsvUploader from "../components/CsvUploader";
import { usePrivileges } from "../providers/AuthProvider";
import privileges from "../privileges";
import EmptyPage from "../components/EmptyPage";
import { Alert, Button, LinearProgress, Paper, Stack, TableContainer, TextField, Typography } from "@mui/material";
import LegacyDialog from "../subcomponents/LegacyDialog";
import ImageUploader from "../components/ImageUploader";
import { StringToNumber } from "../functions/common";

export default function VendorSheet() {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    let url = `${server_route_names["vendor_sheet"]}?page=${parseInt(paginationModel.page)+1}&limit=${paginationModel.pageSize}`;
    const { data, fetching: loading, error, reFetch: refreshData } = useFetch(url);
    const { data: deleteRecordData, loading: deleting, error: deleteError, deleteRecord } = useDeleteRecord(`${server_route_names["vendor_sheet"]}`);
    const { data: pushRecordData, loading: pushing, error: pushError, updateRecord } = useUpdateRecord(`${server_route_names["vendor_sheet"]}`);
    const { hasPermission } = usePrivileges();
    const [importSheet, setImportSheet] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [pushAlert, setPushAlert] = useState(false);
    const [selected, setSelected] = useState(null);
    
    useEffect(() => {
        document.title = "Vendor Sheet";
    }, []);

    const onDeleteButtonClick = async (params) => {
        console.log("On Delete", params?.row?.sku);
        setSelected(params?.row);
        setDeleteAlert(true);
    }

    const onPushButtonClick = async (params) => {
        console.log("On Edit", params?.row?.sku);
        setSelected(params?.row);
        setPushAlert(true);
    }

    if (error) {
        return (
            <LegacyPage
                title="Vendor Sheet"
            >
                {error}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            title="Vendor Sheet"
            actions={hasPermission(privileges["vendor_sheet.manage"])?[{
                title: "Upload Vendor Sheet",
                onClick: () => setImportSheet(!importSheet)
            }]:null}
        >
            {

                (hasPermission(privileges["vendor_sheet.manage"]) && selected && pushAlert) && 
                <LegacyDialog
                    size="xs"
                    open={pushAlert}
                    title={`Publish ${selected?.sku}?`}
                    onClose={() => setPushAlert(!pushAlert)}
                    onCancel={() => setPushAlert(!pushAlert)}
                    primaryAction={{
                        title: "Push",
                        onClick: async () => {
                            let response = await updateRecord(selected?._id, {
                                data: {
                                    quantity: selected?.quantity
                                }
                            });
                            if (response?.status === "success") {
                                refreshData(); setSelected(null); setDeleteAlert(false);
                            }
                        }
                    }}
                >
                    <TextField
                        value={selected?.quantity}
                        label="Quantity to push"
                        type="number"
                        onChange={(e) => {
                            let s = JSON.parse(JSON.stringify(selected));
                            s.quantity = e.target.value;
                            setSelected(s);
                        }}
                    />
                    {
                        pushError && <Alert severity="error">{pushError}</Alert>
                    }
                </LegacyDialog>
            }
            {
                (hasPermission(privileges["vendor_sheet.delete"]) && selected) && (
                    <LegacyDialog
                        size="xs"
                        open={deleteAlert}
                        title={`Delete ${selected?.sku}?`}
                        onClose={() => setDeleteAlert(!deleteAlert)}
                        secondaryAction={{
                            title: "cancel",
                            disabled: deleting,
                            onClick: () => {
                                setDeleteAlert(!deleteAlert);setSelected(null);
                            }
                        }}
                        primaryAction={{
                            title: "Delete",
                            disabled: deleting,
                            onClick: async () => {
                                let response = await deleteRecord(selected?._id);
                                if (response?.status === "success") {
                                    refreshData(); setSelected(null); setDeleteAlert(false);
                                }
                            }
                        }}
                    >
                        {
                            !deleteError && <Typography>
                                Do you want to delete {selected?.sku}?
                            </Typography>
                        }
                        {
                            deleteError && <Alert severity="error">{deleteError}</Alert>
                        }
                    </LegacyDialog>
                )
            }
            <LegacyDialog
                open={importSheet}
                title="Import vendor sheet"
                onClose={() => setImportSheet(!importSheet)}
                hideFooter
            >
                <Layout.Section>
                    <CsvUploader
                        fileType="vendor_sheet.upload"
                        targetUrl={server_route_names["vendor_sheet.bulk_update_by_csv"]}
                        showButton
                        onUploadDone={(file) => {
                            setImportSheet(!importSheet);
                            refreshData();
                        }}
                    />
                </Layout.Section>
            </LegacyDialog>
            <Layout>
                <Layout.Section>
                    <TableContainer component={Paper}>
                        <DataGrid
                            slots={{
                                loadingOverlay: LinearProgress,
                                noRowsOverlay: EmptyPage,
                                toolbar: GridToolbar
                            }}
                            slotProps={{
                                toolbar: {
                                    // showQuickFilter: true,
                                    printOptions: {
                                        disableToolbarButton: true
                                    }
                                }
                            }}
                            sx={{
                                p: 0, pt: 0, height: data?.total > 0?null:400
                            }}
                            columns={getColumns({
                                onUploadDone: refreshData,
                                onDeleteButtonClick,
                                onPushButtonClick,
                                deleteButton: hasPermission(privileges["vendor_sheet.delete"]),
                                pushButton: hasPermission(privileges["vendor_sheet.manage"])
                            })}
                            loading={loading}
                            rows={data?.items??[]}
                            pageSizeOptions={[10, 25, 50, 100]}
                            initialState={{
                                pagination: {
                                    paginationModel: paginationModel
                                }
                            }}
                            rowSelection={false}
                            // checkboxSelection
                            // onRowDoubleClick={(selection) => {
                            //     console.log("selection", selection.row);
                            //     navigate(route_names["products.edit"].replace(":id", selection.row._id));
                            // }}
                            // onRowClick={(params) => {
                            //     console.log("params", params?.row?.sku);
                            // }}
                            // disableRowSelectionOnClick
                            // disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                            disableColumnMenu
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pagination
                            rowCount={data?.total}
                        >
                        </DataGrid>
                    </TableContainer>
                </Layout.Section>
            </Layout>
        </LegacyPage>
    )
}

function getColumns({
    onUploadDone = () => {},
    onPushButtonClick = (params) => {},
    onDeleteButtonClick = (params) => {},
    deleteButton = false,
    pushButton = false,
}){
    let actions_width = 0;
    if (pushButton) actions_width += 80;
    if (deleteButton) actions_width += 80;
    let columns = [
        {   field: 'sku', 
            headerName: 'SKU',
            width: 160,
            hideable: false,
            sortable: false,
        },
        {   field: 'image', 
            headerName: 'Image',
            width: 120,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return <ImageUploader
                    title={'Upload image'}
                    key={params?.row?._id}
                    fileType="vendor_sheet.image.upload"
                    targetUrl={server_route_names["vendor_sheet.image_uplaod"]}
                    imageId={params?.row?._id}
                    onUploadDone={onUploadDone}
                    imageUrl={params.value}
                />
            }
        },
        {   field: 'quantity', 
            headerName: 'Quantity/PC',
            width: 100,
            sortable: false,
            type: "number",
        },
        {   field: 'price', 
            headerName: 'Unit Price/PC',
            width: 110,
            sortable: false,
            type: "number",
            valueFormatter: params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {   field: 'amount', 
            headerName: 'Amount',
            width: 100,
            sortable: false,
            type: "number",
            valueFormatter: params => {
                return `$ ${StringToNumber(params.value)}`
            }
        },
        {   field: 'vol',
            headerName: 'VOL',
            width: 60,
            type: "number",
            sortable: false
        },
        {   field: 'ctn',
            headerName: 'CTN',
            width: 60,
            sortable: false
        },
        {   field: 'notes',
            headerName: 'Notes',
            width: 180,
            sortable: false
        },
        {   field: 'description', 
            headerName: 'Description',
            width: 400,
            sortable: false
        }
    ];
    if (deleteButton || pushButton) {
        columns = [
            {
                field: 'actions', 
                headerName: 'Actions',
                width: actions_width,
                hideable: false,
                sortable: false,
                editable: false,
                filterable: false,
                renderCell: params => {
                    return <Stack direction="row">
                        {
                            pushButton && 
                            <Button
                                onClick={() => onPushButtonClick(params)}
                            >
                                Push
                            </Button>
                        }
                        {
                            deleteButton &&
                            <Button
                                onClick={() => onDeleteButtonClick(params)}
                            >
                                Delete
                            </Button>
                        }
                    </Stack>
                }
            }
        ].concat(columns);
    }
    return columns;
}