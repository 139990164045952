import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import privileges from '../privileges';
import { useAuth } from '../providers/AuthProvider';

export default function PermissionSelections({ 
  onSelection = () => {},
  selected = [],
  readOnly = false
}) {
  
  const { user } = useAuth();
  const [selectedPermissions, setSelectedPermissions] = React.useState(selected);
  const handleToggle = (value) => () => {
    if (readOnly) return null;
    const currentIndex = selectedPermissions.indexOf(value);
    const newChecked = [...selectedPermissions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedPermissions(newChecked);
    onSelection(newChecked);
  };

  React.useEffect(() => {
    setSelectedPermissions(selected);
  }, [selected]);

  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        maxHeight: 400,
        overflow: "auto"
      }}
      dense
      component="div"
      role="list"
    >
      {Object.values(privileges).map((value) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return (
          <ListItem
            disablePadding
            key={value}
            role="listitem"
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                  disabled={readOnly}
                  checked={selectedPermissions.indexOf(value) > -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={generic_names[value]??value} />
          </ListItem>
        );
      })}
    </List>
  );
}

const generic_names = {
  "STORE.EBAY.VIEW": "View Ebay Store",
  "STORE.EBAY.MANAGE": "Manage Ebay Store",
  "STORE.SHOPIFY.VIEW": "View Shopify Store",
  "STORE.SHOPIFY.MANAGE": "Manage Shopify Store",
  "PRODUCTS.VIEW": "View Products",
  "PRODUCTS.MANAGE": "Manage Products",
  "PRODUCTS.DELETE": "Delete Product",
  "CHECKMATE.MANAGE": "Upload Checkmate Sheet",
  "VENDOR_SHEET.VIEW": "View Vendor Sheet",
  "VENDOR_SHEET.MANAGE": "Manage Vendor Sheet",
  "VENDOR_SHEET.DELETE": "Delete Vendor Sheet",
  "ORDERS.VIEW": "View Orders",
  "CUSTOMERS.VIEW": "View Customers",
  "REPORTING.VIEW": "View Reporting",
  "REPORTING.MANAGE": "Manage Reporting",
  "FINANCIAL_REPORTING.VIEW": "View Financial Reporting",
  "FINANCIAL_REPORTING.MANAGE": "Manage Financial Reporting",
  "POS": "Point Of Sale",
  "ORDERING_SHEET.VIEW": "View Ordering Sheet",
  "MANAGEMENT.HR": "Manage Staff",
  "MANAGEMENT.REVENUE": "Manage Revenue",
  "DASHBOARD.WIDGETS.VIEW": "View Dashboard Widget",
  "DASHBOARD.WIDGETS.MANAGE": "Manage Dashboard Widget",
  "REPRICER.VIEW": "View Repricer Tool",
  "REPRICER.MANAGE": "Manage Repricer Tool",
}