import { useEffect, useState } from "react";
import Page from "../components/Page";
import server_route_names from "../routes/server_route_names";
import { useFetch } from "../providers/AppProvider";
import { Card, LinearProgress, Paper, Skeleton, TableContainer } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EmptyPage from "../components/EmptyPage";
import LegacyPage from "../subcomponents/LegacyPage";
import { moneyFormat } from "../functions/common";


export default function Customers(){
    const [paginationModel, setPaginationMode ] = useState({
        page: 0,
        pageSize: 10
    });
    const { data: customers, error, fetching } = useFetch(`${server_route_names["customers"]}?page=${parseInt(paginationModel.page)+1}&limit=${paginationModel.pageSize}`);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Customers";
    }, []);

    if (error) {
        return (
            <LegacyPage
                title="Customers"
            >
                {error}
            </LegacyPage>
        )
    }

    return (
        <LegacyPage
            title="Customers"
        >
            <TableContainer component={Paper}>
                <DataGrid
                    slots={{
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: EmptyPage,
                        toolbar: GridToolbar
                    }}
                    sx={{
                        p: 0, pt: 0, height: customers?.total > 0?null:400
                    }}
                    loading={fetching}
                    columns={columns}
                    rows={customers?.items??[]}
                    rowCount={customers?.total??0}
                    pageSizeOptions={[10, 25, 50, 100]}
                    initialState={{
                        pagination: {
                            paginationModel: paginationModel
                        }
                    }}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationMode}
                    disableRowSelectionOnClick
                    // checkboxSelection
                    disableColumnMenu
                >
                </DataGrid>
            </TableContainer>
        </LegacyPage>
    )
}

const columns = [
    {   field: 'id', 
        headerName: '#ID',
        width: 60,
        hideable: false,
    },
    {   field: 'username', 
        headerName: 'Username',
        width: 200,
        hideable: false,
    },
    {   field: 'fullName',
        headerName: 'Full name',
        width: 200,
    },
    {   field: 'email',
        headerName: 'Email',
        width: 240,
    },
    {   field: 'totalOrders',
        headerName: 'Total Orders',
        width: 100,
    },
    {   field: 'totalSpend',
        headerName: 'Lifetime spent',
        width: 120,
        valueFormatter: params => `${moneyFormat(params.value)}`
    },
    {   field: 'storeName',
        headerName: 'Store Name',
        width: 260,
    },
    {   field: 'storeType',
        headerName: 'Marketplace',
        width: 100,
    },
    {   field: 'updatedAt', 
        headerName: 'Last synchronized',
        filterable: false,
        sortable: false,
        width: 160,
        valueFormatter: (params) =>  `${ moment(params.value).fromNow() }`
    },
    {   field: 'createdAt', 
        headerName: 'Created',
        filterable: false,
        sortable: false,
        width: 180,
        valueFormatter: (params) =>  `${ moment(params.value).format('DD-MM-YYYY') }`
    },
];