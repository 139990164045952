import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { grey } from '@mui/material/colors';

export default function AlertDialog({ title, content, primaryAction, secondaryAction, onClose }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">
        {title}
    </DialogTitle>
    <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
            {content}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        {
            secondaryAction && (
                <Button
                    onClick={secondaryAction.onClick}
                    variant={secondaryAction.variant??"text"}
                    disabled={secondaryAction.disabled}
                    color={secondaryAction.color}
                >
                    {secondaryAction.title}
                </Button>
            )
        }
        {
            primaryAction && (
                <Button
                    onClick={primaryAction.onClick}
                    variant={primaryAction.variant??"text"}
                    disabled={primaryAction.disabled}
                    color={primaryAction.color}
                >
                    {primaryAction.title}
                </Button>
            )
        }
    </DialogActions>
    </Dialog>
  );
}