import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";

const ActionButtons = ({actions, onClick}) => {
    
    return (
        <SpeedDial
            ariaLabel="Action buttons"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            // onClick={onClick}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>
    )
}

export default ActionButtons;