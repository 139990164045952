const env = process.env.NODE_ENV === 'production' ? 1 : 0;

console.log("env", env, process.env.NODE_ENV);

export const host = env === 1?"https://s1.aescollisionparts.com":"http://localhost:3661";

export const ebay_store_names = {
    1: {
        name: "Infiniti OEM Parts",
        shortName: "Infiniti OEM",
        username: "infiniti-nissan-autoparts",
    },
    2: {
        name: "INFINITI NISSAN COLLISION PARTS",
        shortName: "INFINITI NISSAN",
        username: "infinitinissancollisionparts1",
    }
}